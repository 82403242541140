import React, { Fragment } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
const AutoOrgContextMenu = ({item, onRenameFile, onDeleteFile, getDataMoveList}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return(
    <Fragment>
      <span aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClick(event)}>
        <MoreHorizIcon />
      </span>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={(event) => {handleClose(event); getDataMoveList()}}>Move</MenuItem>
        <MenuItem onClick={(event) => {handleClose(event);onRenameFile()}}>Rename</MenuItem>
        <MenuItem onClick={(event) => {handleClose(event); onDeleteFile()}}>Delete</MenuItem>
      </Menu>
    </Fragment>
  )
}
export default AutoOrgContextMenu;

import moment from 'moment';
import { globalGetService, globalPostService, globalPutService, globalExportService, globalStaticGetService, globalStaticExportService } from '../../utils/globalApiServices';
import { checkApiStatus, getLocalStorageInfo, downloadFileType } from '../../utils';
import { trackActivity } from '../../utils/mixpanel';
export function getActionDocksListApi(props, queryParams = {}, loaderType = 'pageLoader') {
  this.setState({ [loaderType]: true });
  let query = Object.assign({}, queryParams);
  if (query.asset) {
    let filterAsset = query.asset.split('_');
    query = {
      ...query,
      asset_type: filterAsset[1],
      asset_id: filterAsset[0]
    }
    delete query.asset
  }
  globalStaticGetService('records/v2/action-dock/get-files/', query)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        delete queryParams.sort;
        delete queryParams.sort_by;
        delete queryParams.page;
        delete queryParams.per_page;
        delete queryParams.tab;
        this.setState({
          ocrAnalyticFlag: false,
          actionDocks: response.data.data,
          filter: queryParams,
          selectAllSearch: false,
          bulkOperation: { ids: [], modal: false, title: '' },
        })
      }
    });
}
export function getActionDockCountApi(props) {
  globalStaticGetService('records/v2/action-dock/get-count/')
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ actionDockCount: response.data.data });
      }
    })
}
export function actionDockAnalyticApi(props, queryParams) {
  this.setState({pageLoader:true})
  globalStaticGetService(`records/v2/dashboard_line_plots/`, queryParams)
  .then(response => {
      this.setState({pageLoader:false})
      if (checkApiStatus(response)) {
        this.setState({ actionDockAnalytic: response.data.data })
      }
    })
}
export function exportActionDocksListApi(props, queryParams = {}) {
  let query = Object.assign({}, queryParams);
  if (query.asset) {
    let filterAsset = query.asset.split('_');
    query = {
      ...query,
      asset_type: filterAsset[1],
      asset_id: filterAsset[0]
    }
    delete query.asset
  }
  this.setState({ pageLoader: true });
  globalStaticExportService('records/v2/action-dock/get-files/', query)
    .then(response => {
      this.setState({ pageLoader: false });
      downloadFileType(response.data, (`Review_Dock_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), query.download);
      this.props.enqueueSnackbar("Review Dock List Exported successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      trackActivity('Item Exported', {
        page_title: 'Review Dock',
        event_type: 'Export',
        event_desc: `Exported ${query.tab} data from Review Dock`,
        tab_name: query.tab,
        extension: query.download
      })
    });
}

export function publishActionDockApi(props, data) {
  globalPostService(`records/v2/action-dock/status-change/publish/`, { ...data, status: 3 })
    .then(response => {
      if (checkApiStatus(response)) {
        const { actionDocks, filter } = this.state;
        this.setState({ bulkOperation: { modal: false, ids: [], title: '' }, selectAllSearch: false });
        this.getActionDockCountApi(props);
        this.getActionDocksListApi(this.props, { tab: props.match.params.type, page: actionDocks.pagination.page, per_page: actionDocks.pagination.per_page, ...filter }, 'pageLoader');
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        trackActivity('File(s) Published', {
          page_title: 'Review Dock',
          event_type: 'Publish',
          event_desc: `Published File(s) from Review Dock`,
          tab_name: data.tab,
          item_type: data.type
        })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function rejectActionDockApi(props, data) {
  globalPostService(`records/v2/action-dock/status-change/reject/`, { ...data, status: 4 })
    .then(response => {
      if (checkApiStatus(response)) {
        const { actionDocks, filter } = this.state;
        this.setState({ bulkOperation: { modal: false, ids: [], title: '' }, selectAllSearch: false });
        this.getActionDockCountApi(props);
        this.getActionDocksListApi(this.props, { tab: props.match.params.type, page: actionDocks.pagination.page, per_page: actionDocks.pagination.per_page, ...filter }, 'pageLoader');
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        trackActivity('File(s) Rejected', {
          page_title: 'Review Dock',
          event_type: 'Reject',
          event_desc: `Rejected File(s) from Review Dock`,
          tab_name: data.tab,
          item_type: data.type
        })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function restoreActionDockApi(props, data) {
  globalPostService(`records/v2/action-dock/status-change/restore/`, { ...data, status: 1 })
    .then(response => {
      if (checkApiStatus(response)) {
        const { actionDocks, filter } = this.state;
        this.setState({ bulkOperation: { modal: false, ids: [], title: '' }, selectAllSearch: false });
        this.getActionDockCountApi(props);
        this.getActionDocksListApi(this.props, { tab: props.match.params.type, page: actionDocks.pagination.page, per_page: actionDocks.pagination.per_page, ...filter }, 'pageLoader');
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        trackActivity('File(s) Restored', {
          page_title: 'Review Dock',
          event_type: 'Restore',
          event_desc: `Restored File(s) from Review Dock`,
          tab_name: data.tab,
          item_type: data.type
        })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function trashActionDockApi(props, data) {
  globalPostService(`records/v2/action-dock/status-change/delete/`, { ...data, delete_status: 1 })
    .then(response => {
      if (checkApiStatus(response)) {
        const { actionDocks, filter } = this.state;
        this.setState({ bulkOperation: { modal: false, ids: [], title: '' }, selectAllSearch: false });
        this.getActionDockCountApi(props);
        this.getActionDocksListApi(this.props, { tab: props.match.params.type, page: actionDocks.pagination.page, per_page: actionDocks.pagination.per_page, ...filter }, 'pageLoader');
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function fileRenamingApi(props, fileRenameInfo = {}, publish) {

  let payload = {};
  payload = {
    ...payload,
    type: 'file',
    uuid: fileRenameInfo.data.uuid,
    new_name: fileRenameInfo.data.name,
    publish: publish
  }
  globalPutService(`records/v2/action-dock/rename/`, payload)
    .then(response => {
      if (checkApiStatus(response)) {
        const { actionDocks, filter } = this.state;
        this.setState({ fileRenameInfo: { modal: false, data: null } })
        this.getActionDockCountApi(props);
        this.getActionDocksListApi(this.props, { tab: props.match.params.type, page: actionDocks.pagination.page, per_page: actionDocks.pagination.per_page, ...filter }, 'pageLoader');
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        trackActivity('File Renamed', {
          page_title: 'Review Dock',
          event_type: 'Rename',
          event_desc: `Renamed File from Review Dock`,
          tab_name: props.match.params.type,
          file_name: fileRenameInfo.data.name,
          file_path: fileRenameInfo.data.location,
          asset_type: fileRenameInfo.data.asset.asset_type,
          asset_name: fileRenameInfo.data.asset.serial_no
        })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function getUsersFilterApi (query){
  globalGetService(`records/v2/action-dock/get-files/?get_only_users=true`,query)
  .then(response=>{
    if(checkApiStatus(response)){
      this.setState({usersFilter:response.data.data})
    }
  })
}
import React from 'react';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { checkPermission } from '../../../utils';
const MiniPackBulkOps = ({match, dataKits, bulkOperation, downloadResourceKit, onChangeMiniPackPic, onChangeBulkOps, toggleShareContent}) => {
  return(
    <div className="dock-bulk-ops">
      <ul className="list-inline dock-bulk-ops-list">
        <li className="list-inline-item">
          {checkPermission('records', 'mini-pack', 'R') && dataKits?.minipack_details?.cover_photo?.cover_photo ?
            <Button onClick={onChangeMiniPackPic} variant="outlined" color="primary"> Change or View Cover Photo </Button>
            : checkPermission('records', 'mini-pack', 'C') ?
              <Button onClick={onChangeMiniPackPic} variant="outlined" color="primary"> Upload Cover Photo </Button>
              : null
          }
        </li>
        { bulkOperation.ids.length && checkPermission('records','mini-pack','D') ?
          <li className="list-inline-item">
            <Button onClick={() => onChangeBulkOps('Remove')} variant="outlined" color="secondary">Remove ({bulkOperation.ids.length})</Button>
          </li>:null
        }
        { checkPermission('records','mini-pack','D') ?
          <li className="list-inline-item">
            <Button onClick={() => onChangeBulkOps('Remove All')} variant="contained" color="secondary">Remove All</Button>
          </li>:null
        }
        { checkPermission('records','mini-pack','DN') ?
          <li className="list-inline-item">
            <Button onClick={() => downloadResourceKit({download:'minipack', folder_uuid:match.params.uuid})} variant="contained" color="primary">Download All</Button>
          </li>:null
        }
        { checkPermission('records','mini-pack','DN') ?
          <li className="list-inline-item">
            <Button onClick={() => downloadResourceKit({download:'generate_minipack',folder_uuid:match.params.uuid})} variant="contained" color="primary">Generate MiniPack</Button>
          </li>:null
        }
        { checkPermission('records','mini-pack','SHR') ?
          <li className="list-inline-item">
            <Button onClick={() => toggleShareContent()} variant="contained" color="primary">Share</Button>
          </li>:null
        }
      </ul>
    </div>
  )
}
export default withRouter(MiniPackBulkOps);

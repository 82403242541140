import React from 'react'
import { Dialog, Paper, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, } from '@material-ui/core';
import { EmptyCollection, Pagination } from '../../../shared_elements';
import moment from 'moment';

export default function RevisionList({ revisionList, toggleModalFn,getResponseBack }) {
    return (
        <div>
            <Dialog
                open={revisionList.flag}
                onClose={() => toggleModalFn()}
                className='records-dataroom-modal'
                maxWidth='sm'
            >
                <DialogTitle id="scroll-dialog-title">Custom Alerts : Email Sent History </DialogTitle>
                <DialogContent dividers={true} style={{ width: "450px", padding:'10px 20px 0px 20px'}}>
                    <div style={{ height: '400px', overflow:'scroll' }}>
                        <Table className='mui-table-format'>
                            <TableHead>
                                <TableCell style={{ position: 'sticky', top: "0" }}>Sl. No.</TableCell>
                                <TableCell style={{ position: 'sticky', top: "0" }}>Email Sent Date & Time</TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    revisionList?.data?.list?.map((item, index) =>
                                        <TableRow>
                                            <TableCell >
                                            { (revisionList?.data?.pagination?.per_page * (revisionList?.data?.pagination?.page -1) ) +index+1  }
                                            </TableCell>

                                            <TableCell >
                                                {item?.email_sent_time ? moment(item?.email_sent_time).format('DD MMM-YYYY HH:mm') : '--'}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                        {
                            revisionList?.data?.list < 1 ?
                                <EmptyCollection title={'No records found'} /> : null
                        }
                    </div>
                    <Pagination
                        pagination={revisionList?.data?.pagination}
                        onChangePage={(event, newPage) => getResponseBack(revisionList?.item,{ page: newPage + 1, per_page: revisionList?.data?.pagination?.per_page }, 'pageLoader')}
                        onChangeRowsPerPage={(event) => getResponseBack(revisionList?.item,{ page: 1, per_page: event.target.value }, 'pageLoader')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModalFn} color="primary" variant="text">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

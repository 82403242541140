import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import {Accordion,AccordionSummary,AccordionDetails, Grid, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CkEditorText } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import { checkApiStatus ,checkPermission} from '../../../utils';
import { globalPutService } from '../../../utils/globalApiServices';
const ContentExpansion = ({match, title, valueKey, content, prsDetail}) => {
    const [htmlContent, setHtmlContent] = useState(content)
    const [editMode, setEditMode] = useState(false);
    const [isExpand, setExpand] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onUpdatePrsInfo = (key, value, required) => {
        globalPutService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/issues/${match.params.slug}/`, {[valueKey]:htmlContent})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setEditMode(false);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    return(
        <div>
            <Accordion square expanded={isExpand} onChange={(e,expanded)=> setExpand(expanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                    <h4>{title}</h4>
                    {checkPermission('records','records_issue','U') && !editMode ?
                        <EditIcon onClick={(e)=> {e.preventDefault();e.stopPropagation();setEditMode(true);setExpand(true)}} style={{marginLeft:'auto'}} color='primary' fontSize='small' />:null
                    }
                </AccordionSummary>
                <AccordionDetails style={{background:'#f9f7f7'}}>
                    <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                            { editMode ? 
                                <CkEditorText
                                    disabled={false}
                                    htmlData={htmlContent}
                                    onChangeData={(data) => setHtmlContent(data)}
                                />:
                                htmlContent ? <div className='comment-content' dangerouslySetInnerHTML={{__html: htmlContent}}></div>:'--'
                            }
                        </Grid>
                        { editMode ?
                            <Grid item md={12} xs={12} >
                                <ul className='list-inline'>
                                    <li className='list-inline-item'>
                                        <Button onClick={() => {setEditMode(false); setHtmlContent(content)}} color='primary' variant='outlined' size='small'>Cancel</Button>
                                    </li>
                                    <li className='list-inline-item' style={{marginLeft:'5px'}}>
                                        <Button onClick={onUpdatePrsInfo} color='primary' variant='contained' size='small'>Submit</Button>
                                    </li>
                                </ul>
                            </Grid>:null
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
export default withRouter(ContentExpansion);
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { checkPermission } from '../../utils'
import restrictIcon from '../../assets/images/restrict_icon.svg';
import config from '../../config';
const NoAccess = ({}) => {
    return(
        <div className="page-not-found">
            <Grid container spacing={0}>
                <Grid xs={12}>
                    <img src={restrictIcon} alt="Icon"/>
                    <h2>You’re not permitted to see this.</h2>
                    <p>The page you’re trying to access has restricted access,<br/>
                        please contact <a style={{color:'#3F51B5', textDecoration:'auto'}} href="mailto:support@sparta.aero">support@sparta.aero</a> to access this application.
                    </p>
                    { checkPermission('records','data_room','R') ?
                        <Link className="start-over btn btn-default return-home" to='/assets'>Return Home</Link>:
                        <Link className="start-over btn btn-default return-home" onClick={() => window.location.assign(`${config.domain.subDomian}`)}>Return Home</Link> 
                    }
                </Grid>
            </Grid>
        </div>
    )
}
export default NoAccess;
import Dashboard from './pages/Dashboard';
import ActiveAssets from './pages/ActiveAssets';
import ArchivedAssets from './pages/ArchivedAssets';
import PageLayout from '../../hoc/PageLayout';
import UserRoleAccess from '../../hoc/UserRoleAccess';
export const fleetRoutes = [
  // {
  //   path: '/dashboard',
  //   component: PageLayout(UserRoleAccess(Dashboard, ['records', 'dashboard', 'R']), {apps: 'Home', layoutPhase: 1}),
  //   key: 'Dashboard',
  //   apps:'records'
  // },
  {
    path: '/assets',
    component: PageLayout(ActiveAssets, {apps: 'Asset', layoutPhase: 1}),
    key: 'AssetListing',
    apps:'records'
  },
  {
    path: '/archived-assets',
    component: PageLayout(UserRoleAccess(ActiveAssets, ['technical_specs', 'archived_assets', 'R']), {apps: 'Reports', layoutPhase: 1}),
    key: 'ArchivedAssets',
    apps:'records'
  },
]

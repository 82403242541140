import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

const MaintenanceModal = ({ maintenanceModal, onCloseMaintenanceModal }) => {
    return (
        <Dialog
            open={maintenanceModal}
            maxWidth="sm"
            aria-label='simple-dialog-title'
            onClose={onCloseMaintenanceModal}
        >
            <DialogTitle id='simple-dialog-title'>
                Information
            </DialogTitle>
            <DialogContent dividers={true}>
                <div className="asset-listing-wrap utilization-history" style={{ marginLeft: '0px' }} >
                    Dear User,
                    <br /><br />
                    SPARTA will be under routine maintenance and feature upgrade release from Saturday 17 Sep 2022 00:30 Hrs UTC to Sunday 18 Sep 2022 23:30 Hrs UTC. During this time, SPARTA will be accessible but may pose intermittent connection problems. We regret the inconvenience caused.
                    <br /><br />
                    If you have any concerns or questions, please contact us on <a href="mailto:support@sparta.aero">support@sparta.aero</a>.
                    <br /><br />
                    Regards,
                    <br />
                    The SPARTA Team
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={onCloseMaintenanceModal} >Close</Button>
            </DialogActions>
        </Dialog>
    )
}
export default MaintenanceModal;
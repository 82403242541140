import React, {Fragment} from 'react';
import { TextField } from "@material-ui/core"
const TextFilter = ({filter, filterOption, filterMenu, keyParam, onFieldChange}) => {
    return(
        <Fragment>
            <TextField
                id={keyParam}
                label={filterMenu[keyParam].title}
                fullWidth
                margin="normal"
                value={ filterOption[keyParam] ? filterOption[keyParam] : ""}
                onChange={(e) => onFieldChange(keyParam, e.target.value)}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                className='filter-label'
            />
        </Fragment>
    )
}
export default TextFilter;
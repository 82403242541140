import React from 'react';
import { Tooltip } from '@material-ui/core';
import { getFileSize, getFileIcon } from '../../../utils';
import { imgPath } from '../../../constants';
import folderIcon from '../../../assets/images/folder_icon.svg';
import { trackPublicActivity } from '../../../utils/mixpanel';
const ShareFolderCard = ({ item, fileIcons, onChangeNav, onPreviewActivity, sharedData, trackActivity }) => {
  return (
    <div className="folder-card">
      <div className="folder-icon" style={{ background: "#F0F0F0" }}>
        <img
          style={{ width: item.type === 'folder' ? '50px' : '36px' }}
          className="folder-img"
          src={item.type === 'folder' ? folderIcon : getFileIcon(fileIcons, item.extension)} alt="Folder"
          onClick={(e) => {
            e.preventDefault(); e.stopPropagation();
            if (item.type === 'folder') {
              trackActivity()
              onChangeNav()
            } else {
              onPreviewActivity();
              trackPublicActivity('File Previewed', { page_title: 'Shared', event_type: 'Preview(Card View)', extension: item.extension ? item.extension : '', size: item.size ? item.size : '', asset_type: sharedData.asset.asset_type, asset_name: sharedData.asset.msn, lessor_name: sharedData.asset.lessor, file_path: item.location, file_name: item.name, uuid: item.uuid })
            }
          }}
        />
      </div>
      <div className="folder-info">
        <h5 onClick={(e) => {
          e.preventDefault(); e.stopPropagation();
          if (item.type === 'folder') {
            onChangeNav()
            trackActivity()
          } else {
            onPreviewActivity();
            trackPublicActivity('File Previewed', { page_title: 'Shared', event_type: 'Preview(Card Layout)', extension: item.extension ? item.extension : '', size: item.size ? item.size : '', asset_type: sharedData.asset.asset_type, asset_name: sharedData.asset.msn, lessor_name: sharedData.asset.lessor, file_path: item.location, file_name: item.name, uuid: item.uuid })
          }
        }}>
          {item.name.length < (item.type === 'folder' ? 18 : 13) ? item.name : <Tooltip title={item.name} arrow><span>{item.name.slice(0, item.type === 'folder' ? 18 : 13) + '...'}</span></Tooltip>}
        </h5>
        {item.type === 'folder' ? <p>Files: {item.file_count ? item.file_count : 0}</p> : <p>Size: {getFileSize(item.size)}</p>}
      </div>
    </div>
  )
}
export default ShareFolderCard;

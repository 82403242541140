export const fieldDateFormat = 'DD-MM-YYYY';
export const backendDateFormat = 'YYYY-MM-DD';
export const displayDateFormat = 'MMMM DD, YYYY';
export const displayDateFormatShort = 'MMM DD, YYYY';
export const imgPath = 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/'
export const assetTypeValues = {
  1: {label:'MSN', urlType:'aircraft'},
  2: {label:'ESN', urlType:'engine'},
  3: {label:'APU', urlType:'apu'},
  4: {label:'LG', urlType:'lg'},
  5: {label:'Propeller', urlType:'propeller'},
  6: {label:'FAN',urlType:'fan'},
  7: {label:'HPC', urlType:'hpc'},
  8: {label:'HPT', urlType:'hpt'},
  9: {label:'LPT', urlType:'lpt'}
};
export const revAssetTypeValues = {
  aircraft:{label:'MSN', assetType:1},
  engine:{label:'ESN', assetType:2},
  apu:{label:'APU', assetType:3},
  lg:{label:'LG', assetType:4},
  propeller:{label:'Propeller', assetType:5},
  fan:{label:'FAN', assetType:6},
  hpc:{label:'HPC', assetType:7},
  hpt:{label:'HPT', assetType:8},
  lpt:{label:'LPT', assetType:9}
};
export const imageMedia = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];
export const videoTypes = ['m4v', 'mov', 'mp4', 'webm', 'ogv']
export const audioTypes = ['wav', 'mp3', 'ogg']

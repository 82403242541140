import React from 'react';
import { Button } from '@material-ui/core';
import componentPermissionHoc from '../../../hoc/componentPermissionHoc'
import { checkPermission } from '../../../utils';
const EmptyFolder = ({toggleUploadModalFn, showUpload, applyTemplate}) => {
  return(
    <div className="empty-folder">
      <div className="upload-now-content">
        {
          !showUpload ?
          <p>No Result found</p>
          :<p>This folder is empty</p>
        }
        { checkPermission('records','data_room','UP') && showUpload ?
        <>
        <div style={{marginBottom:'4px'}}>

            <Button onClick={toggleUploadModalFn} size="small" variant="contained" color="primary">Upload Now</Button>
        </div>
        <div style={{marginBottom:'4px'}}>
        <Button onClick={() => {applyTemplate()}} size="small" variant="contained"  color="primary">Apply Template</Button>
        </div>
        </>
          :null
        }
      </div>
    </div>
  )
}
export default EmptyFolder;

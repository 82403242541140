import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { imgPath } from '../../../constants';
import { getFileSize } from '../../../utils';
import folderIcon from '../../../assets/images/folder_icon.svg';
const MiniPackList = ({match, item , history, fileIcons}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell className="file-info" width="380">
        <div className="flex-centered">
          <img className="folder-icon" width={item.type === 'folder' ? "30" : "20"} src={item.type === 'folder' ? folderIcon : fileIcons.map(item => item.label).includes(item.extension.toLowerCase()) ? imgPath+'file-icons/'+item.extension.toLowerCase()+'_icon.svg':''} alt="Folder" />
          <div>
            <h5 style={{cursor:'pointer'}} onClick={(e) =>
               item.type === 'folder' ?
                history.location.search === "?archived-assets" ? history.push({
                pathname: `/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/mini-pack/detail/${item.uuid}`,
                search: `${history.location.search}`}): history.push(`/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/mini-pack/detail/${item.uuid}`) 
                :
                window.open(`/records/preview/${item.uuid}`, '_blank')}>
              {item.name}
            </h5>
          </div>
        </div>
      </TableCell>
      <TableCell>{item.type === 'folder'? item.file_count:getFileSize(item.size)}</TableCell>
    </TableRow>
  )
}
export default withRouter(MiniPackList);

export const permissionKeys = {
  in_queue:'inqueue',
  rename_queue:'to_be_renamed',
  ocr_queue:'ocr_inqueue',
  ocr_rejected_queue:'ocr_rejected',
  rejected_queue:'rejected',
  corrupted_queue:'rejected'
}
export const actionDockFilterOps = {
  name: {
    inputType: 'text',
    placeholder: 'Enter File Name',
    title: 'File Name'
  },
  asset: {
    inputType: 'dropdown',
    placeholder: 'Please Enter Asset Serial No.',
    title: 'Asset',
    options: [],
    labelKey: 'unique_name',
    valueKey: 'id'
  },
  user_id: {
    inputType: 'dropdown',
    placeholder: 'Please Enter User Name',
    title: 'User Name',
    options: [],
    labelKey: 'name',
    valueKey: 'id'
  },
};
export const inqueueHd = [
  { id: 'name', label: 'Name', sortOption: false },
  { label: 'Asset', sortOption: false },
  { id: 'size', label: 'Size', sortOption: false },
  { label: 'Uploaded By', sortOption: false },
  { label: 'Updated By', sortOption: false },
  { label: 'Last Modified At', sortOption: false },
  { label: 'Status', sortOption: false },
  { label: 'OCR Status', sortOption: false, actionCell: true },
];
export const toBeRenamedHd = [
  { id: 'name', label: 'Name', sortOption: false },
  { label: 'Asset', sortOption: false },
  { id: 'size', label: 'Size', sortOption: false },
  { label: 'Uploaded By', sortOption: false },
  { label: 'Updated By', sortOption: false },
  { label: 'Last Modified At', sortOption: false },
  { label: 'Status', sortOption: false, actionCell: true }
];
export const ocrQueueHd = [
  { id: 'name', label: 'Name', sortOption: false },
  { label: 'Asset', sortOption: false },
  { id: 'size', label: 'Size', sortOption: false },
  { label: 'Uploaded By', sortOption: false },
  { label: 'Updated By', sortOption: false },
  { label: 'Last Modified At', sortOption: false },
  { label: 'OCR Status', sortOption: false, actionCell: true },
];
export const ocrRejectedHd = [
  { id: 'name', label: 'Name', sortOption: false },
  { label: 'Asset', sortOption: false },
  { id: 'size', label: 'Size', sortOption: false },
  { label: 'Uploaded By', sortOption: false },
  { label: 'Updated By', sortOption: false },
  { label: 'Last Modified At', sortOption: false },
  { label: 'Action', sortOption: false, actionCell: true },
];
export const corruptedQueueHd = [
  { id: 'name', label: 'Name', sortOption: false },
  { label: 'Asset', sortOption: false },
  { id: 'size', label: 'Size', sortOption: false },
  { label: 'Uploaded By', sortOption: false },
  { label: 'Updated By', sortOption: false },
  { label: 'Last Modified At', sortOption: false },
  { label: 'Action', sortOption: false, actionCell: true },
];
export function toggleBulkOpsFn(flag, type, id = null) {
  const { actionDocks } = this.state;
  if (type === 'all') {
    this.setState(previous => ({
      ...previous,
      bulkOperation: {
        ...previous.bulkOperation,
        ids: flag ? actionDocks.list.map(item => item.uuid) : []
      }
    }))
  } else {
    this.setState(previous => ({
      ...previous,
      bulkOperation: {
        ...previous.bulkOperation,
        ids: flag ? [...previous.bulkOperation.ids, id] : previous.bulkOperation.ids.filter(item => item !== id)
      }
    }))
  }
}
export function applyFilterFn(keyParam, value) {
  this.setState(prevState => ({
    ...prevState,
    applyingFilter: {
      ...prevState.applyingFilter,
      [keyParam]: value
    }
  }))
}
export function onChangeBulkOps(title) {
  this.setState(prevState => ({
    ...prevState,
    bulkOperation: {
      ...prevState.bulkOperation,
      modal: true,
      title: title,
      multi: true
    }
  }))
}
export function docksSingleActionFn(title, id) {
  this.setState(prevState => ({
    ...prevState,
    bulkOperation: {
      ...prevState.bulkOperation,
      ids: [id],
      modal: true,
      title: title,
      multi: false
    }
  }))
}
export function getTableHD() {
  switch (this.props.match.params.type) {
    case 'in_queue':
      return inqueueHd;
    case 'rename_queue':
      return toBeRenamedHd;
    case 'ocr_queue':
      return ocrQueueHd;
    case 'ocr_rejected_queue':
      return ocrRejectedHd;
      case 'corrupted_queue':
        return corruptedQueueHd
    default:
      return ocrRejectedHd;
  }
}

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { checkPermission } from '../../../utils';
const KitsBulkOps = ({ match, recordsRepos, bulkOperation, onChangeBulkOps, toggleShareContent, downloadResourceKit, dataKits }) => {
  return (
    <div className="dock-bulk-ops">
      <ul className="list-inline dock-bulk-ops-list">
        {recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.technical_records && checkPermission('records','category','C') ?
          <li className="list-inline-item">
            <Button startIcon={<AddIcon />} color="primary" variant="outlined">
              <Link to={`/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/${recordsRepos.record_folders.technical_records.uuid}`}>Add Files</Link>
            </Button>
          </li> : null
        }
        {bulkOperation.ids.length && checkPermission('records','category','D') ?
          <li className="list-inline-item">
            <Button onClick={() => onChangeBulkOps('Remove')} variant="outlined" color="secondary">Remove ({bulkOperation.ids.length})</Button>
          </li> : null
        }
        { dataKits?.list?.length ?
          <>
            { checkPermission('records','category','D') ?
              <li className="list-inline-item">
                <Button onClick={() => onChangeBulkOps('Remove All')} variant="contained" color="secondary">Remove All</Button>
              </li>:null
            }
            { checkPermission('records','category','DN') ?
              <li className="list-inline-item">
                <Button onClick={downloadResourceKit} variant="contained" color="primary">Download All</Button>
              </li>:null
            }
            { checkPermission('records','category','SHR') ?
              <li className="list-inline-item">
                <Tooltip title={`Shares Complete ${window.location.href.includes('engineering_kit') ? 'Engineering' : 'Marketing'} Data Kit`} arrow>
                  <Button variant="contained" onClick={toggleShareContent} color="primary">Share</Button>
                </Tooltip>
              </li>:null
            }
          </>: null
        }
      </ul>
    </div>
  )
}
export default withRouter(KitsBulkOps);

import React from 'react';
import Info from '@material-ui/icons/Info';
import { Tooltip } from '@material-ui/core';

const DmFileCountCard = ({ title, count, tooltip }) => {
  return (
    <div className="file-count-card">
      <span className="vertical-bar"></span>
      {
        tooltip ?
            <p style={{ display: 'flex', alignItems: 'center' }}>{title}
              <Tooltip title={tooltip} arrow placement='top'>
                <Info style={{ fontSize: '20px', marginLeft: '3px' }} />
              </Tooltip>
            </p>
            :
            <p>{title}</p>
      }
      <h3>{count}</h3>
    </div>
  )
}
export default DmFileCountCard;

import React, { Fragment } from 'react';
import { withRouter} from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
const RenameFileFolderModal = ({dataRename, saveFileFolderName, toggleModalFn}) => {
  const [name, setName] = React.useState(dataRename.data.name);
  const onChangeName = (name) => {
    setName(name);
  }
  return(
    <Dialog
      open={dataRename.modal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        Rename
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2} style={{display:'block'}}>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => onChangeName(e.target.value)}
              InputLabelProps={{shrink: true}}
            />
          </Grid>
          {dataRename && dataRename.data && dataRename.data.naming_convention  ? dataRename.data.naming_convention.map((name, index) =>
            <p style={{margin:"0px 0px 4px 10px"}}>{name}</p>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleModalFn(false)}>Cancel</Button>
        <Button disabled={name.trim().length ?false:true} onClick={() => saveFileFolderName(name)} variant="contained"  color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  )
}
export default RenameFileFolderModal;

import React, { Fragment } from 'react';
import { withRouter} from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import DriveToolManu from './DriveToolManu';
const DataUploadModal = ({dataRoom, uploadDataModal, toggleUploadModalFn, uploadDataRoomFile}) => {
  return(
    <Dialog
      open={uploadDataModal}
      onClose={() => toggleUploadModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        Upload Data To "<b>{dataRoom.navigation && dataRoom.navigation.length ? dataRoom.navigation[dataRoom.navigation.length-1].name:'loading...'}</b>"
      </DialogTitle>
      <DialogContent dividers={true} className="data-upload-modal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="data-upload-instructions">
              <div className="info-area">
                <p><b>Recommendation</b>: Try to keep the individual file size within 10-20MB</p>
              </div>
              <p className="alert-msg"><b>Note:</b> Data in excess of 500 Mb can take longer to upload. We recommend using the Drive Tool Application for faster bulk upload tasks. To install Drive Tool, select here <DriveToolManu /></p>
            </div>
            <div className="dropzone-area">
              <DropzoneArea
                filesLimit={1000}
                maxFileSize={8000000000}
                showPreviewsInDropzone={false}
                showPreviews={false}
                dropzoneText={<p>Drag & Drop files<br/> OR <br/> Click Here</p>}
                dropzoneClass="drag-drop-cnt"
                maxWidth="sm"
                showAlerts={['error', 'info']}
                alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                onDrop={(files) => uploadDataRoomFile(files)}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleUploadModalFn(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
export default withSnackbar(withRouter(DataUploadModal));

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Dialog, Paper, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from '@material-ui/core';
import { DeletePopUp, EmptyCollection, Pagination, PageLoader } from '../../../shared_elements'
import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../utils/globalApiServices';
import { checkApiStatus, checkPermission, localTimeFn } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Skeleton } from '@material-ui/lab';
const DataRoomRefLink = ({match, refLinkData, toggleModalFn}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false)
    const [deleteRefLink, setDeleteRefLink] = useState({modal:false, data:null});
    const [addEditRefLink, setAddEditRefLink] = useState({modal:false, mode:'', data:null});
    const [refLinkInfo, setRefLinkInfo] = useState([]);
    const [error, setError] = useState({});
    useEffect(() => {
        getRefLinks('skeletonLoader');
    },[]);
    const onFieldChange = (key, value) => {
        setAddEditRefLink({...addEditRefLink, data:{
            ...addEditRefLink.data,
            [key]:value
        }})
    }
    const getRefLinks = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true)
        globalGetService(`records/${refLinkData.type}/${refLinkData.uuid}/links/`)
        .then(response => {
            if(checkApiStatus(response)){
                setRefLinkInfo(response.data.data);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false)
        })
    }
    const onAddEditRefLink = () => {
        let validationInputs = {
            link: addEditRefLink?.data?.link?.trim()?.length ? regexConstants.webUrl.test(addEditRefLink?.data?.link) ? '':'Please enter Valid Url':'Please enter Link'
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            if(addEditRefLink.data.id){
                globalPutService(`records/${refLinkData.type}/${refLinkData.uuid}/links/${addEditRefLink.data.id}/`, addEditRefLink.data)
                .then(response => {
                    if(checkApiStatus(response)){
                        setAddEditRefLink({modal:false, mode:'', data:null});
                        getRefLinks('pageLoader');
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                })
            }else{
                globalPostService(`records/${refLinkData.type}/${refLinkData.uuid}/links/`, addEditRefLink.data)
                .then(response => {
                    if(checkApiStatus(response)){
                        setAddEditRefLink({modal:false, mode:'', data:null});
                        getRefLinks('pageLoader');
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                })
            }
        }else{
            setError(validationInputs);
        }
    }
    const onDeleteRefLink = () => {
        globalDeleteService(`records/${refLinkData.type}/${refLinkData.uuid}/links/${deleteRefLink.data.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteRefLink({modal:false, data:null});
                getRefLinks('pageLoader');
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
        })
    }
    const onCopyClipboard = (selector) => {

        var copyText = document.getElementById(selector);
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        document.execCommand("copy");
        enqueueSnackbar('Reference Link copied Successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
    }
    return(
        <>
            <Dialog
                open={refLinkData.modal}
                onClose={() => toggleModalFn(refLinkInfo.length)}
                className='records-dataroom-modal'
                maxWidth='lg'
            >
                <DialogTitle id="scroll-dialog-title"> Reference Link(s) </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'720px'}}>
                        { skeletonLoader ? <Skeleton />:
                            <>  
                                {checkPermission('records','ref_link','C') ?
                                    <Button onClick={() => setAddEditRefLink({modal:true, mode:'add', data:{link:'', remarks:''}})} color='primary' size='small' variant='contained'>Add Reference Link</Button>:null
                                }
                                <Paper>
                                    <Table className='mui-table-format' style={{marginTop:'10px'}}>
                                        <TableHead>
                                            <TableCell>Link</TableCell>
                                            <TableCell>Created By</TableCell>
                                            <TableCell>Updated By</TableCell>
                                            <TableCell align='right'>Action</TableCell>
                                        </TableHead>
                                        <TableBody>
                                            { refLinkInfo.map((item, index) =>
                                                <TableRow>
                                                    <TableCell>
                                                        <input type='text' id={`ref-link_${item.id}`} value={item.link} style={{ height:'0px', border:'none', background:'transparent', width:'100%'}}/>
                                                        { item.remarks ? <p style={{fontSize:'11px', fontStyle:'italic', marginTop:'4px'}}><b>Remarks: </b>{item.remarks}</p>:null }
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{width:'120px'}}>
                                                            {item?.created_by?.name||'--'}
                                                            <p style={{fontSize:'10px'}}>{localTimeFn(item.created_at)}</p>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{width:'120px'}}>
                                                            {item?.updated_by?.name||'--'}
                                                            {item?.updated_by?.name && item.updated_at ?
                                                                <p style={{fontSize:'10px'}}>{localTimeFn(item.updated_at)}</p>:null
                                                            }
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        <div style={{width:'80px', float:'right'}}>
                                                            <ul className='list-inline' style={{cursor:'pointer'}}>
                                                                <li className='list-inline-item' onClick={() => onCopyClipboard(`ref-link_${item.id}`)}>
                                                                    <Tooltip title='Copy Link' arrow>
                                                                        <FileCopyIcon fontSize='small' color='primary' />
                                                                    </Tooltip>
                                                                </li>
                                                                {checkPermission('records','ref_link','U') ?
                                                                    <li className='list-inline-item' onClick={() => setAddEditRefLink({modal:true, mode:'edit', data:item})}>
                                                                        <Tooltip title='Edit Link' arrow>
                                                                            <EditIcon color='primary' fontSize='small' />
                                                                        </Tooltip>
                                                                        
                                                                    </li>:null
                                                                }
                                                                {checkPermission('records','ref_link','D') ?
                                                                    <li className='list-inline-item' onClick={() => setDeleteRefLink({modal:true, data:item})}>
                                                                        <Tooltip title='Delete Link' arrow>
                                                                            <DeleteOutlineIcon color='secondary' fontSize='small' />
                                                                        </Tooltip>
                                                                    </li>:null
                                                                }
                                                            </ul>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )} 
                                            { !refLinkInfo?.length ? <TableRow><TableCell colSpan={4} align='center'>No records found</TableCell></TableRow>:null}
                                        </TableBody>
                                    </Table>    
                                </Paper>
                                    
                            </>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleModalFn(refLinkInfo.length)} color="primary" variant="text" size='small'>Close</Button>
                </DialogActions>
            </Dialog>
            { addEditRefLink.modal ?
                <Dialog
                    open={addEditRefLink.modal}
                    onClose={() => setAddEditRefLink({modal:false, mode:'', data:null})}
                    className='records-dataroom-modal'
                    maxWidth='md'
                >
                    <DialogTitle id="scroll-dialog-title"> {addEditRefLink.data.id ? 'Edit ':' Add '} Reference Link </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{width:'420px'}}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <TextField 
                                        required
                                        name='link'
                                        label='Reference Link'
                                        value={addEditRefLink?.data?.link||''}
                                        fullWidth
                                        margin="normal"
                                        error={error?.link}
                                        helperText={error?.link||''}
                                        onChange={(e) => onFieldChange('link', e.target.value)}
                                        onFocus={() => setError({...error, link:''})}
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{ maxLength: 250 }}
                                        variant='outlined'
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <TextField 
                                        id='remarks'
                                        label='Remarks'
                                        value={addEditRefLink?.data?.remarks||''}
                                        fullWidth
                                        rows={4}
                                        margin="normal"
                                        onChange={(e) => onFieldChange('remarks', e.target.value)}
                                        InputLabelProps={{shrink: true}}
                                        inputProps={{ maxLength: 250 }}
                                        variant='outlined'
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddEditRefLink({modal:false, mode:'', data:null})} color="primary" variant="outlined" size='small'>CANCEL</Button>
                        <Button onClick={onAddEditRefLink} color="primary" variant="contained" size='small'>SAVE</Button>
                    </DialogActions>
                </Dialog>:null
            }
            
            { deleteRefLink.modal ?
                <DeletePopUp
                    modal={deleteRefLink}
                    toggleModalFn={() => setDeleteRefLink({modal:false,data: null})}
                    title="Delete Reference Link"
                    content={<h4>Are you sure you want to delete?</h4>}
                    triggerEventFn={onDeleteRefLink}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </>
    )
}
export default withRouter(DataRoomRefLink);
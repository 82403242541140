import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
const DownloadShareModal = ({downloadShareData, onDownloadShareData, onFieldChange, toggleModalFn}) => {
  return(
    <Dialog
      open={downloadShareData.modal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        Download Data
      </DialogTitle>
      <DialogContent dividers={true} className="data-upload-modal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{border:'1px solid #a78701', background:'#fff1b6', padding:'5px', borderRadius:'3px'}}>
              <p style={{fontSize:'13px', color:'#2f2600'}}>
                Generating download is background process which might take time, please enter your email. We will send download link to your email once process is completed.
              </p>
            </div>
            <div>
              <TextField
                required
                id="email"
                fullWidth
                margin="normal"
                value={downloadShareData.email}
                onChange={(e) => onFieldChange(e.target.value)}
                label="Email"
                placeholder="Please enter email"
                InputLabelProps={{shrink: true}}
              />
            </div>

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleModalFn(false)}>Cancel</Button>
        <Button onClick={onDownloadShareData} disabled={downloadShareData.email.trim().length ? false:true} variant="contained" color="primary">Continue</Button>
      </DialogActions>
    </Dialog>
  )
}
export default DownloadShareModal;

import React, { Fragment } from 'react';
import { withRouter} from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { Link, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import { checkPermission } from '../../../utils';
const MiniPackCoverPic = ({dataKits, miniPackCoverPic, onChangeMiniPackCoverPic, toggleModalFn}) => {
  return(
    <Dialog
      open={miniPackCoverPic.modal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        Cover Photo
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="minipack-cover-pic-modal">
              { dataKits.minipack_details && dataKits.minipack_details.cover_photo && dataKits.minipack_details.cover_photo.cover_photo ?
                <div className="cover-pic-action">
                  <ul className="list-inline flex-centered">
                    { checkPermission('records','mini-pack','DN') ?
                      <li className="list-inline-item">
                        <Button color="primary" variant="outlined">
                          <Link href={dataKits.minipack_details.cover_photo.cover_photo} target="_blank">Download</Link>
                        </Button>
                      </li>:null
                    }
                    { checkPermission('records','mini-pack','U') ?
                      <li className="list-inline-item">
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          style={{display:'none'}}
                          onChange={(e) => onChangeMiniPackCoverPic(e.target.files,'change', dataKits.minipack_details.cover_photo.id)}
                        />
                        <label htmlFor="contained-button-file">
                          <span color="primary" variant="outlined">CHANGE</span>
                        </label>
                      </li>:null
                    }
                    {checkPermission('records','mini-pack','D') ?
                      <li className="list-inline-item">
                        <Button onClick={()=> onChangeMiniPackCoverPic({}, 'remove', dataKits.minipack_details.cover_photo.id)} color="secondary" variant="outlined">Remove</Button>
                      </li>:null
                    }
                  </ul>
                  <img width="200" style={{margin:'0 auto', display:'block'}} src={dataKits.minipack_details.cover_photo.cover_photo} alt="" />
                </div>:
                <div className="dropzone-area" style={{ width:'300px', position:'relative', margin:'0 auto'}}>
                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={['image/jpg', 'image/jpeg', 'image/png']}
                    showPreviewsInDropzone={false}
                    showPreviews={true}
                    dropzoneText={<p>Drag & Drop Pictures<br/> OR <br/> Click Here</p>}
                    dropzoneClass="drag-drop-cnt"
                    maxWidth="sm"
                    showAlerts={['error', 'info']}
                    alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                    onDrop={(files) => onChangeMiniPackCoverPic(files, 'add')}
                  />
                  <p className="file-upload-note">Supported Extension .jpg, jpeg, .png</p>
                </div>
              }
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleModalFn(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
export default MiniPackCoverPic;

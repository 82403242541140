import React, { Fragment } from 'react';
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/core/styles';
import { trackActivity } from '../../../utils/mixpanel';
const DriveToolManu = ({ }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <Fragment>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant="contained"
        endIcon={<ArrowDropDownIcon />}
        size="small"
      >
        Drive tool
      </Button>
      <Popper style={{ zIndex: 9999 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleClose}>
                    <a href="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/tools/drive/SPARTA_Drive.dmg" target="_blank" onClick={() => trackActivity('Item Clicked', { page_title: 'Dataroom Header', event_type: 'Drive tool Download', event_desc: 'Downloaded Mac OS Drive tool', download_type: 'Mac OS' })}>
                      Mac OS
                      <CloudDownloadIcon style={{ marginLeft: 'auto', paddingLeft: '6px' }} fontSize="small" className="icon-file" color="primary" />
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a href="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/tools/drive/SPARTA_Drive.msi" target="_blank" onClick={() => trackActivity('Item Clicked', { page_title: 'Dataroom Header', event_type: 'Drive tool Download', event_desc: 'Downloaded Windows 64 bit Drive tool', download_type: 'Window 64 bit' })}>
                      Window 64 bit
                      <CloudDownloadIcon style={{ marginLeft: 'auto', paddingLeft: '6px' }} fontSize="small" className="icon-file" color="primary" />
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a href="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/tools/drive/SPARTA_Drive_32.msi" target="_blank" onClick={() => trackActivity('Item Clicked', { page_title: 'Dataroom Header', event_type: 'Drive tool Download', event_desc: 'Downloaded Windows 32 bit Drive tool', download_type: 'Window 32 bit' })}>
                      Window 32 bit
                      <CloudDownloadIcon style={{ marginLeft: 'auto', paddingLeft: '6px' }} fontSize="small" className="icon-file" color="primary" />
                    </a>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}
export default DriveToolManu;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Button, Container, Grid, Tooltip, IconButton, TextField, Badge ,Dialog,Hidden} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import UserDropDownMenu from './UserDropDownMenu';
import ReportAnIssue from './ReportAnIssue';
import PageLoader from './PageLoader';
import FAQsList from './FAQsList';
import WhatsNewCompleteList from './WhatsNewCompleteList';
import AdvanceFilter from './AdvanceFilter';
import { getLocalStorageInfo, eraseGlobalCookie,clearCookie } from '../../utils';
import { trackActivity, trackLogOut } from '../../utils/mixpanel'
import { lessorUpdateApi } from '../../application/auth/apiServices';
import { globalRecordsSearchAc, globalFilterRecordsSearchAc } from '../actionCreators';
import * as actions from '../actions';
import config from '../../config';
import Cookies from 'universal-cookie'
import ApplicationsAccess from './ApplicationsAccess';
const cookies = new Cookies();
const queryString = require('query-string');
class DesktopHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      search:queryString.parse(this.props.location.search).q,
      pageLoader:false,
      formSubmitLoader:false,
      faqsList:[],
      headerCount:{},
      appswictherModal:false
    }
    this.lessorUpdateApi = lessorUpdateApi.bind(this);
  }
  componentDidMount(){
    // this.getHeaderCartCountApi();
  }
  onSearchData = () => {
    const { search } = this.state;
    if(search && search.trim().length){
      this.props.globalRecordsSearchFn({q:search, page:1, per_page:20});
      this.props.globalRecordsSearchFilter({q:search})
    }else{
      this.props.enqueueSnackbar("Please enter the keyword for search", {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  }
  logout = () => {
    localStorage.clear();
    clearCookie('lessorAccess', '.sparta.aero', '/')
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('domain')
    eraseGlobalCookie('userName')
    cookies.remove('lessorAccess' , {path: "/", domain: "sparta.aero"})
    this.props.history.push('/login')
    trackLogOut(getLocalStorageInfo())
  }
   render(){
    const { search, whtsNewModal, whtsNewList, faqsList, headerCount, pageLoader, formSubmitLoader } = this.state;
    let baseUrl = config.domain.subDomian;
    return(
      <Fragment>
        <header className="desktop-header">
          <Container maxWidth={false}  style={{padding:'0px 5px'}}>
            <Grid container  spacing={0} alignItems="center" justify="center">
              <Grid item xl={5} xs={5}>
                <ul className="list-inline app-logo-search flex-centered">
                  <li className="list-inline-item app-logo">
                    <Link to="/assets">
                      <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Sparta" />
                    </Link>
                  </li>
                  <li>
                    <Button
                      className="app-switcher"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={() => {this.setState({appswictherModal:true}); trackActivity('Item Clicked',{page_title:'Desktop Main Header', item_type:'Application Switcher', event_desc:'Clicked on Application Switcher from Desktop main Header'})}}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      Records
                    </Button>
                  </li>
                  <li className="list-inline-item">
                    <form onSubmit={(e) => {e.preventDefault(); this.onSearchData() }}>
                      <input className='free-text-search' type="text" value={search} onChange={(e) => this.setState({search:e.target.value})} placeholder="Search for Files, Folders and Keyword" />
                    </form>
                  </li>
                </ul>
              </Grid>            
              <Grid item xl={7} xs={7}>
                <ul className="list-inline header-right-nav flex-centered">
                <Hidden only={['md']}>
                  <li className="list-inline-item">
                    <WhatsNewCompleteList />
                  </li>
                  <li className="list-inline-item">
                    <ReportAnIssue type="head" />
                  </li>
                  <li className="list-inline-item">
                    <FAQsList />
                  </li>
                  <li className="list-inline-item">
                    <Tooltip title="Notifications" arrow>
                      <IconButton size="small" color="primary">
                        <a href={`${baseUrl}notifications`} target="_blank">
                          <Badge max={99} badgeContent={headerCount.notification_count? headerCount.notification_count:0} color="secondary">
                            <NotificationsActiveIcon color="primary" fontSize="small" />
                          </Badge>
                        </a>
                      </IconButton>
                    </Tooltip>
                  </li>
                  </Hidden>
                  { getLocalStorageInfo() && getLocalStorageInfo().lessor_list && getLocalStorageInfo().lessor_list.length > 0 ?
                    <li className="list-inline-item" style={{width:'215px',borderLeft:'1px solid #d7d7d7'}}>
                      <Autocomplete
                        options = {getLocalStorageInfo() && getLocalStorageInfo().lessor_list ?  getLocalStorageInfo().lessor_list:[]}
                        clearOnBlur={false}
                        getOptionLabel={option => option.name}
                        disableClearable={true}
                        filterSelectedOptions={true}
                        value={{id:getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id ? getLocalStorageInfo().defaultLessor.id : ''  , name: getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.name ? getLocalStorageInfo().defaultLessor.name:""}}
                        onChange={(e, data) => this.lessorUpdateApi(this.props, {lessor_id:data.id})}
                        renderInput={params => <TextField variant='outlined' size='small' {...params} margin="none" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                  </li>:null
                  }
                  <li className="list-inline-item">
                    <UserDropDownMenu
                      logout={this.logout}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </header>
        <ReportAnIssue type="foot" />
        { this.state.appswictherModal ?
            <Dialog
              open={this.state.appswictherModal}
              onClose={() => this.setState({appswictherModal:false})}
              aria-labelledby="scroll-dialog-title"
              fullScreen={true}
              className='application-swicther-modal'
            >
              <ApplicationsAccess
                handleClose={() => this.setState({appswictherModal:false})}
              />
            </Dialog>
            : null
        }
        { pageLoader || formSubmitLoader ? <PageLoader/>:null }
        <div style={{height:'49px'}}></div>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  fleetFiles: state.homeReducer.fleetFiles,
  fleetActivityCount: state.homeReducer.fleetActivityCount,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    globalRecordsSearchFn: (query) => dispatch(globalRecordsSearchAc(ownProps, query)),
    globalRecordsSearchFilter: (query) => dispatch(globalFilterRecordsSearchAc(ownProps, query)),
  }
}
export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(DesktopHeader)))

import React from 'react';
import { Tooltip } from '@material-ui/core';
import { getFileSize, getFileIcon } from '../../../utils';
import { imgPath } from '../../../constants';
import folderIcon from '../../../assets/images/folder_icon.svg'
const OrgDataCard = ({item, fileIcons, onChangeNav, contextMenu}) => {
  return(
    <div className="folder-card">
      <div className="folder-icon" style={{background:"#F0F0F0"}} onClick={(e) => item.type === 'folder' ? onChangeNav() : window.open(`/records/preview/${item.uuid}`, '_blank')}>
        <img
          style={{width: item.type === 'folder' ? '50px':'36px'}}
          className="folder-img"
          src={item.type === 'folder' ? folderIcon: getFileIcon(fileIcons, item.extension)} alt="Folder"
        />
      </div>
      <div className="folder-info">
        <h5 onClick={(e) => item.type === 'folder' ? onChangeNav() : window.open(`/records/preview/${item.uuid}`, '_blank')}>
          {item.name.length < 15 ? item.name:<Tooltip title={item.name} arrow><span>{item.name.slice(0,15)+'...'}</span></Tooltip>}
        </h5>
        {item.type === 'folder'? <p>Files: {item.file_count?item.file_count:0}</p>:<p>Size: {getFileSize(item.size)}</p>}
      </div>
      { item.type === 'file' ?
        <span className="context-menu-action">
          {contextMenu}
        </span>:null
      }
    </div>
  )
}
export default OrgDataCard;

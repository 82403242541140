import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import { STableLoader } from '../../../shared_elements/loaders';
import { TableListComp, PageLoader, EmptyCollection } from '../../../shared_elements';
import { MiniPackList, ShareContentModal } from '../components';
import { getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { getMiniPackAssemblyApi, shareDataRoomDataApi } from '../apiServices';
import * as actions from '../../../shared_elements/actions'
import { imageMedia, videoTypes } from '../../../constants'
import { checkPermission, getLocalStorageInfo } from '../../../utils';
import { miniPackHd } from '../';
import { trackActivity } from '../../../utils/mixpanel';
class DataMiniPack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: false,
      pageLoader: false,
      miniPacks: { list: [] },
      shareContent: { modal: false, data: {} }
    }
    this.getMiniPackAssemblyApi = getMiniPackAssemblyApi.bind(this);
    this.shareDataRoomDataApi = shareDataRoomDataApi.bind(this);
  }
  componentDidMount() {
    this.getMiniPackAssemblyApi(this.props, {}, 'skeletonLoader');
    if (!this.props.pltConstants.filter(item => item.type === 'file_extension_drive').length) {
      this.props.getPlatformConstants();
      trackActivity('Page Visited', {
        page_title: 'Mini Packs'
      })
    }
  }
  toggleShareContent = () => {
    const { miniPacks } = this.state;
    let shareData = { ...miniPacks.shared_details, type: 'file', name: 'MiniPack' }
    this.setState(prevState => ({
      ...prevState,
      shareContent: {
        ...prevState.shareContent,
        modal: true,
        data: shareData
      }
    }));
  }

  render() {
    const { skeletonLoader, pageLoader, shareContent, miniPacks } = this.state;
    const { pltConstants } = this.props;
    return (
      <section className="action-docks">
        <div className="heading flex-centered">
          <h3>Mini Packs</h3>
          { checkPermission('records','mini-pack','SHR') && miniPacks.list.length > 0 ?
              <Button style={{ marginLeft: 'auto' }} onClick={this.toggleShareContent} color="primary" variant="contained">Share</Button>
              : null
          }
        </div>
        <Fragment>
          {skeletonLoader ?
            (<STableLoader count={5} />) :
            <Fragment>
              <TableListComp
                heads={miniPackHd}
                data={miniPacks.list.map((item, index) =>
                  <MiniPackList
                    item={item}
                    index={index}
                    fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                  />
                )}
                pagination={miniPacks.list.pagination}
                onChangePage={(event, newPage) => { this.setState({ bulkOperation: { ids: [], modal: false } }); this.getMiniPackKitApi(this.props, { tab: 'minipack', page: newPage + 1, per_page: miniPacks.pagination.per_page }, 'pageLoader') }}
                onChangeRowsPerPage={(event) => this.getMiniPackKitApi(this.props, { tab: 'minipack', page: 1, per_page: event.target.value }, 'pageLoader')}
                noRecord={miniPacks.list.length ? null :
                  <EmptyCollection
                    title="No Records Found"
                  />
                }
              />
            </Fragment>
          }
        </Fragment>
        {shareContent.modal ?
          <ShareContentModal
            shareContent={shareContent}
            toggleModalFn={() => this.setState({ shareContent: { modal: false, data: {} } })}
            sharetheDmContent={(flag, hour) => this.shareDataRoomDataApi(this.props, { type: 'folder', uuid: shareContent.data && shareContent.data.parent_uuid ? shareContent.data.parent_uuid : miniPacks.shared_details?.uuid, share: flag, hour: hour }, 'minipack')}
          /> : null
        }
        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ['file_extension_drive'] })),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(DataMiniPack))

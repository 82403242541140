import React from 'react';
import MainNavLink from './MainNavLink';

const DeskTopSidebar = () => {
  return(
    <div className="desktop-py-sidenav">
      <nav>
        <MainNavLink  />
      </nav>
    </div>
  )
}
export default DeskTopSidebar

import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import { STableLoader } from '../../../shared_elements/loaders';
import { TableListComp, PageLoader, EmptyCollection } from '../../../shared_elements';
import { BucketList } from '../components';
import { dmKitsHd } from '../';
class DataBucket extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader:false,
      pageLoader:false,
      bucketKits:{
        list:[1,1],
        pagination:null
      }
    }
  }
  componentDidMount(){

  }

  render(){
    const { skeletonLoader, bucketKits }= this.state;
    return(
      <section className="action-docks">
        <div className="heading flex-centered">
          <h3>Bucket Files</h3>
        </div>
        <div className="dock-bulk-ops">
          <ul className="list-inline dock-bulk-ops-list">
            <li className="list-inline-item"></li>
            <li className="list-inline-item">
              <Button variant="contained" color="secondary">Delete</Button>
            </li>
            <li className="list-inline-item">
              <Button variant="contained" color="primary">Download</Button>
            </li>
          </ul>
        </div>
        <Fragment>
          { skeletonLoader ?
            (<STableLoader count={5} />) :
            <Fragment>
              <TableListComp
                bulkOperation={true}
                heads={dmKitsHd}
                data={ bucketKits.list.map((item,index) =>
                  <BucketList
                    item={item}
                    index={index}
                    rowType="inqueue"
                  />
                )}
                pagination={bucketKits.pagination}
                noRecord={ bucketKits.list.length ? null:
                  <EmptyCollection
                    title="No Records Found"
                  />
                }
              />
            </Fragment>
          }
        </Fragment>
      </section>
    )
  }
}
export default withSnackbar(DataBucket)

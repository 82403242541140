import React, { useState } from 'react';
import { Container, Drawer, Grid, Paper } from '@material-ui/core';
import { SecondarySideNav } from '../../../shared_elements';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
const MobileSecondarySideNav = ({assetinfo}) => {
    const [open, setOpen] = useState(false)
    return (
        <>
         <Container style={{position:'relative', borderBottom: '1px solid #d7d7d7', background:'rgb(232 234 234)', padding: '5px',marginBottom:'3px'}}>
            <Grid className="flex-centered ">
                <MenuOpenIcon
                    style={{ marginLeft: '5px',}}
                    fontSize='medium'
                    color="primary"
                    onClick={() => setOpen(true)}
                />
                         <span style={{marginLeft:'20px',fontSize:'10px'}}>Asset side bar</span>   
            </Grid>
            </Container>
            <Drawer  anchor='left' open={open} onClose={()=>setOpen(false)}>
                <div className="mobile-main-navs" style={{height:'100%',width:'240px',background: '#8073c1'}}>  
                   <Paper className="mobile-py-link" elevation={0}>             
                      <SecondarySideNav toggleDrawer={()=>setOpen(false)} assetinfo={assetinfo} type="mobile" />
                   </Paper>
                </div>
            </Drawer>
        </>
    )
}
export default MobileSecondarySideNav;
import React from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { checkPermission } from '../../../utils'; 
import { trackActivity } from '../../../utils/mixpanel';
const ReportsTab = ({tabIndex = 'activities', history}) => {
    return(
        <Paper square style={{marginBottom:'10px'}}>
            <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => history.push(`/reports/${newValue}`)} className={'no-margin-tab'}>
                { checkPermission('reports','activities','R') ?
                    <Tab label='Activities' value="activities" onClick={() => trackActivity('Tab Clicked', { page_title: 'Reports', event_type: 'Activities Tab', event_desc: 'Clicked on Activities Tab from Reports' })} />:null
                }
                { checkPermission('reports','inventory','R') ?
                    <Tab label='Inventory' value="inventory" onClick={() => trackActivity('Tab Clicked', { page_title: 'Reports', event_type: ' Inventory Tab', event_desc: 'Clicked on Inventory Tab from Reports' })} />:null
                }
                { checkPermission('reports','custom_alert','R') ?
                 <Tab label='Custom Alerts' value="customalerts" onClick={() => trackActivity('Tab Clicked', { page_title: 'Reports', event_type: ' Custom Alerts', event_desc: 'Clicked on Custom Alerts Tab from Reports' })} />:null
                }
            </Tabs>
        </Paper>
    )
}
export default withRouter(ReportsTab);
import React from 'react';
import {Paper} from '@material-ui/core';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const FileStatusCountChart = ({filesCount}) => {
  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
      style: {
        fontSize: '11px',
      },
    },
    xAxis: {
      type: 'category'
    },
    yAxis:{
      allowDecimals: false,
      title: {
        text: 'File(s) Count',
        style: {
          fontSize: '11px'
        }
      },
      labels: {
        formatter: function () {
          return this.value;
        }
      },
      gridLineDashStyle: 'longdash'
    },
    tooltip: {
      formatter: function(){
        return '<p><b>No. of Files: </b>'+this.points[0].y+'</p>'
      },
      shared: true
    },
    credits: {
      enabled: false
    },
    series:  [
      {
        name: 'File Status',
        data: [
          {
              name: "No. of Files",
              y: filesCount.total_count,
              color:'#5B96DA'
          },
          {
              name: "Published",
              y: filesCount.published,
              color:'#82A27A'
          },
          {
              name: "To Be Renamed",
              y: filesCount.rename,
              color:'#89689D'
          },
          {
              name: "Unpublished",
              y: filesCount.uploaded,
              color:'#A3A05B'
          },
          {
              name: "Recycle Bin",
              y: filesCount.recycle_bin,
              color:'#D86A6A'
          },
          {
              name: "Rejected",
              y: filesCount.rejected,
              color:'#FA2222'
          }
        ],
      }
    ],
  };
  return(
    <Paper>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </Paper>
  )
}
export default FileStatusCountChart;

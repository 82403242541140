import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Paper, Breadcrumbs, Typography, Link, Grid } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { ShareFolderCard, DownloadShareModal, SharedListCard } from '../components';
import { getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { PageLoader, EmptyCollection, TableListComp } from '../../../shared_elements';
import { SCardLoader } from '../../../shared_elements/loaders';
import { imageMedia, videoTypes } from '../../../constants';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AppsIcon from '@material-ui/icons/Apps';
import axios from 'axios'
import { getSharedContentApi, downloadShareDataApi, shareLinkActivityApi } from '../apiServices';
import * as actions from '../../../shared_elements/actions'
import { trackPublicActivity } from '../../../utils/mixpanel';
class SharedRecordsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadShareData: {
        modal: false,
        email: ''
      },
      viewMode: 1,
      skeletonLoader: false,
      pageLoader: false,
      sharedData: {
        asset: null,
        list: [],
        navigation: []
      }
    }
    this.getSharedContentApi = getSharedContentApi.bind(this);
    this.downloadShareDataApi = downloadShareDataApi.bind(this);
    this.shareLinkActivityApi = shareLinkActivityApi.bind(this);
  }
  componentDidMount() {
    let shareType = this.props.match.params.key.slice(-2);
    this.getSharedContentApi(this.props, this.props.match.params.key, {}, 'skeletonLoader');
    this.shareLinkActivityApi(this.props, this.props.match.params.key, shareType == 'ff' ? 30 : 29);
    if (!this.props.pltConstants.filter(item => item.type === 'file_extension_drive').length) {
      this.props.getPlatformConstants();
    }
    trackPublicActivity('Page Visited', { page_title: 'Shared Content' })
  }

  onPreviewActivity = (item, event) => {
    this.shareLinkActivityApi(this.props, this.props.match.params.key, 31);
    const previewUrl = document.createElement('a')
    previewUrl.href = `${window.location.protocol}//${window.location.hostname}/records/preview/${item.uuid}?shared=true`
    previewUrl.setAttribute('target', '_blank')
    document.body.appendChild(previewUrl)
    previewUrl.click()
  }

  downloadFile = (url, name) => {
    this.setState({ pageLoader: true })
    axios.get(url, { responseType: 'arraybuffer', })
      .then(response => {
        this.setState({ pageLoader: false })
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click()
      })
      .catch(error => this.setState({ pageLoader: false }))
  }

  render() {
    const { skeletonLoader, pageLoader, sharedData, downloadShareData, viewMode } = this.state;
    const { pltConstants } = this.props;
    return (
      <section className="share-content-section">
        <div style={{ height: '70px', width: '100%' }}></div>
        <div className="share-header">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <img width="32" src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Logo" />
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <ul className="list-inline lessor-info">
                <li className="list-inline-item">
                  {sharedData.asset && sharedData.asset.lessor_logo ?
                    <img src={sharedData.asset.lessor_logo} alt={sharedData.asset.lessor} /> : null
                  }
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
        <Paper className="navigation-box">
          <h4>
            {sharedData.asset && sharedData.asset.msn ? sharedData.asset.msn : ''}
            <span>
              {
                window.location.href.includes('*xx') && sharedData.list && sharedData.list.length ?
                  <a onClick={() => this.downloadFile(decodeURIComponent(sharedData.list[0].path), sharedData.list[0].name)} download ><CloudDownloadIcon color="primary" /></a>
                  : <CloudDownloadIcon
                    onClick={() => this.setState(prevState => ({
                      ...prevState,
                      downloadShareData: {
                        ...prevState.downloadShareData,
                        modal: true,
                        email: ''
                      }
                    }
                    ))}
                    color="primary"
                  />
              }
            </span>
          </h4>
          <Breadcrumbs maxItems={3} aria-label="breadcrumb">
            {sharedData.navigation.map((nav, index) =>
              sharedData.navigation.length - 1 === index ? <Typography>{nav.name}</Typography> :
                <Link onClick={() => this.getSharedContentApi(this.props, nav.share_key, { preview: true, parent: index !== 0 ? sharedData.navigation[sharedData.navigation.length - 1].uuid : null }, 'pageLoader')}>{nav.name}</Link>
            )}
          </Breadcrumbs>
        </Paper>
        <div className="shared-content-list">
          <ul className="list-inline flex-centered data-room-layout-options">
            <li className="list-inline-item">
              <span className="data-rm-switcher">
                <span style={{ cursor: 'pointer' }} className={viewMode === 1 ? 'active' : ''} onClick={() => this.setState({ viewMode: 1 })}>
                  <AppsIcon color={viewMode === 1 ? 'primary' : 'default'} />
                </span>
                <span style={{ cursor: 'pointer' }} className={viewMode === 2 ? 'active' : ''} onClick={() => this.setState({ viewMode: 2 })}>
                  <ListAltIcon color={viewMode === 2 ? 'primary' : 'default'} />
                </span>
              </span>
            </li>
          </ul>
        </div>
        <div className="shared-content-list">
          <ul className="list-inline">
            {skeletonLoader ? <SCardLoader count={20} /> : null}
            {sharedData.list && sharedData.list.length ?
              sharedData.list.map((item) =>
                <li className="list-inline-item">
                  {
                    viewMode === 1 ?
                      <ShareFolderCard
                        trackActivity= {()=>this.shareLinkActivityApi(this.props, item.share_key, 32)}
                        item={item}
                        fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                        onChangeNav={() => this.getSharedContentApi(this.props, item.share_key, { parent: sharedData.navigation[sharedData.navigation.length - 1].uuid, preview: true }, 'pageLoader')}
                        onPreviewActivity={() => this.onPreviewActivity(item)}
                        sharedData={sharedData}
                      />
                      : null
                  }
                </li>
              ) : null}
          </ul>
          {
            viewMode === 2 ?
              <TableListComp
                heads={[
                  { label: 'Name', sortOption: false },
                  { label: 'Size / Count', sortOption: false },
                ]}
                data={sharedData.list.map((item, index) =>
                  <SharedListCard
                    item={item}
                    index={index}
                    sharedData={sharedData}
                    fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                    onChangeNav={() => this.getSharedContentApi(this.props, item.share_key, { parent: sharedData.navigation[sharedData.navigation.length - 1].uuid, preview: true }, 'pageLoader')}
                  />
                )}
                noRecord={sharedData.list.length ? null :
                  <div></div>
                }
              />
              : null
          }
        </div>
        <DownloadShareModal
          downloadShareData={downloadShareData}
          onFieldChange={(value) => this.setState(prevState => ({
            ...prevState,
            downloadShareData: {
              ...prevState.downloadShareData,
              email: value
            }
          }))}
          toggleModalFn={() => this.setState(prevState => ({
            ...prevState,
            downloadShareData: {
              ...prevState.downloadShareData,
              modal: false,
              email: ''
            }
          }))}
          onDownloadShareData={() => this.downloadShareDataApi(this.props, this.props.match.params.key, downloadShareData.email)}
        />
        {!sharedData.list.length && !skeletonLoader ? <EmptyCollection title="No Records Found" /> : null}
        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ['file_extension_drive'] })),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(SharedRecordsList))

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, Tooltip, Paper, Grid } from '@material-ui/core';
import { checkPermission } from '../../../utils';
import Info from '@material-ui/icons/Info';
import { trackActivity } from '../../../utils/mixpanel';
import Box from '@material-ui/core/Box';
const ActionDockTab = ({ actionDockCount, history, match, getFilesList }) => {
  const trackTabChangeActivity = (event, tab) => {
    trackActivity('Tab Clicked', { page_title: 'Review Dock', event_desc: event, tab_name: tab })
  }
  return (
    <>
      <Paper className="action-dock-tab">
        <Tabs
          value={match.params.type}
          onChange={(event, newValue) => { history.push(`/action-dock/${newValue}`); getFilesList(newValue) }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons='auto'
          className={'no-margin-tab'}
        >
          {checkPermission('reviewdock','inqueue','R') ? <Tab label={`Inqueue ${actionDockCount ? `(${actionDockCount ? actionDockCount.in_queue_count : 0})` : ''}`} value="in_queue" onClick={() => trackTabChangeActivity('Clicked on InQueue Tab', 'InQueue')} /> : null}
          {checkPermission('reviewdock','to_be_renamed','R') ? <Tab label={`To be Renamed ${actionDockCount ? `(${actionDockCount ? actionDockCount.rename_count : 0})` : ''}`} value="rename_queue" onClick={() => trackTabChangeActivity('Clicked on ToBeRenamed Tab', 'ToBeRenamed')} /> : null}
          {checkPermission('reviewdock','ocr_inqueue','R') ? <Tab
            label={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                OCR Inqueue {actionDockCount ? `(${actionDockCount ? actionDockCount.ocr_queue_count : 0})` : ''}
                <Tooltip title='Includes only PDF File Types' placement='top' arrow>
                  <Info style={{ marginLeft: '2px', height: '20px' }} />
                </Tooltip>
              </span>
            }
          value="ocr_queue" onClick={() => trackTabChangeActivity('Clicked on OcrInQueue Tab', 'OcrInQueue')} /> : null}
          {checkPermission('reviewdock','ocr_rejected','R') ? <Tab
            label={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                OCR Rejected {actionDockCount ? `(${actionDockCount ? actionDockCount.ocr_rejected_count : 0})` : ''}
                <Tooltip title='Includes only PDF File Types' placement='top' arrow>
                  <Info style={{ marginLeft: '2px', height: '20px' }} />
                </Tooltip>
              </span>
            }
            value="ocr_rejected_queue" onClick={() => trackTabChangeActivity('Clicked on OcrRejected Tab', 'OcrRejected')} /> : null}
          {checkPermission('reviewdock','rejected','R') ? <Tab label={`Rejected ${actionDockCount ? `(${actionDockCount ? actionDockCount.rejected_count : 0})` : ''}`} value="rejected_queue" onClick={() => trackTabChangeActivity('Clicked on Rejected Tab', 'Rejected')} /> : null}
        </Tabs>
      </Paper>
      { match.params.type === 'ocr_rejected_queue' ?
          <Grid item xs={12} className='hidden-mobile'>
            <div className="action-doc-listing-wrp">
              <span className="warning-info-text" style={{marginBottom: '10px'}}>
                <p><strong>Cause of rejection:</strong></p>
                - Encrypted & Corrupted -  If file is encrypted or corrupted , then OCR scanner cannot be performed.<br/>
                - Poor quality & Handwritten document - If file has a poor resolution or handwritten content, then OCR Scanner is unable to recognise the text contained in the document.
              </span>
            </div>
          </Grid>:null
      }
    </>
  )
}
export default withRouter(ActionDockTab);

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { STableLoader } from '../../../shared_elements/loaders';
import { TableListComp, PageLoader, EmptyCollection, FilterUIComp, DeletePopUp } from '../../../shared_elements';
import { AutoOrgBatch } from '../components';
import { getAutoOrgBatchsApi } from '../apiServices';
import { autoOrganiserHd } from '../';
import { trackActivity } from '../../../utils/mixpanel';
class AutoOrganiser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: false,
      pageLoader: false,
      tabIndex: 0,
      autoOrganiserBatch: {
        list: [],
        pagination: null
      }
    }
    this.getAutoOrgBatchsApi = getAutoOrgBatchsApi.bind(this);
  }
  componentDidMount() {
    this.getAutoOrgBatchsApi(this.props, {}, 'skeletonLoader');
    trackActivity('Page Visited', { page_title: 'Auto Organiser', page_type: 'Detail' })
  }
  onChangeTabIndex = (event, newValue) => {
    this.setState({ tabIndex: newValue });
  }
  render() {
    const { tabIndex, skeletonLoader, pageLoader, autoOrganiserBatch } = this.state;
    return (
      <section className="auto-organiser-section">
        <div className="heading flex-centered">
          <h3>Auto Organiser</h3>
        </div>
        <div className="action-dock-tab">
          <Tabs
            value={tabIndex}
            onChange={this.onChangeTabIndex}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
          >
            <Tab label={`To be Reviewed`} onClick={() => trackActivity('Tab Clicked', { page_title: 'Auto Organiser', page_type: 'Detail', tab_name: 'To be Reviewed' })} />
            <Tab label={`Reviewed`} onClick={() => trackActivity('Tab Clicked', { page_title: 'Auto Organiser', page_type: 'Detail', tab_name: 'Reviewed' })} />
          </Tabs>
        </div>
        <div>
          {skeletonLoader ? <STableLoader count={6} /> :
            <Fragment>
              <TableListComp
                heads={autoOrganiserHd}
                data={autoOrganiserBatch.list.map((item, index) =>
                  <AutoOrgBatch
                    item={item}
                    index={index}
                  />
                )}
                noRecord={autoOrganiserBatch.list.length ? null :
                  <EmptyCollection
                    title="No Records Found"
                  />
                }
              />
            </Fragment>
          }
        </div>
        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
export default AutoOrganiser;

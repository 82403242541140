import Dashboard from './containers';
// import AssetListing from './containers/AssetListing';
import PageLayout from '../../hoc/PageLayout';
import UserRoleAccess from '../../hoc/UserRoleAccess';
export const homeRoutes = [
  {
    path: '/dashboard',
    component: PageLayout(UserRoleAccess(Dashboard, ['records', 'dashboard', 'R']), {apps: 'Home', layoutPhase: 1}),
    key: 'Dashboard',
    apps:'records'
  },
  // {
  //   path: '/assets',
  //   component: PageLayout(AssetListing, {apps: 'Home', layoutPhase: 1}),
  //   key: 'AssetListing',
  //   apps:'records'
  // },
  // {
  //   path: '/archived-assets',
  //   component: PageLayout(AssetListing, {apps: 'Home', layoutPhase: 1}),
  //   key: 'ArchivedAssetListing',
  //   apps:'records'
  // },
]

import React, { Fragment } from 'react';
import { withRouter} from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
const NewFolderModal = ({newFolderModal, toggleModalFn, onCreateFolder}) => {
  const [name, setName] = React.useState('');
  const onChangeName = (name) => {
    setName(name);
  }
  return(
    <Dialog
      open={newFolderModal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        Create New Folder
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              fullWidth
              margin="normal"
              value={name}
              InputLabelProps={{shrink: true}}
              onChange={(e) => onChangeName(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleModalFn(false)}>Cancel</Button>
        <Button disabled={name.trim().length > 0 ? false:true} variant="contained" onClick={() => onCreateFolder({name:name})}  color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  )
}
export default withSnackbar(withRouter(NewFolderModal));

import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, TextField, CircularProgress } from '@material-ui/core';
export default function LessorForm({error, lessor, handleChange, onSubmit, resetErrorKey, handleError, formSubmitLoader}){
  return(
    <form onSubmit={(e) => {e.preventDefault();onSubmit()}}>
      <Autocomplete
        options = {JSON.parse(localStorage.getItem('userInfo')).lessor_list.map(lessor => {return {value: lessor.id, label: lessor.name}})}
        getOptionLabel={option => option.label}
        value={lessor}
        onChange={(e, value) => {handleChange(e, 'lessor', value); handleError(value ? value.label:'','lessor')}}
        renderInput={params => <TextField onFocus={(e) => resetErrorKey('lessor')} {...params} label="Select Lessor/Inspector Account to access" placeholder='Select account' margin="normal" error={error.lessor ? true:false} helperText={error.lessor?error.lessor:''} fullWidth InputLabelProps={{shrink: true}} />}
      />
      <div className="text-center" style={{marginTop: '30px'}}>
        <Button style={{width:'100%'}} variant="contained" type="submit" disabled={formSubmitLoader} color="primary">{ formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Continue' }</Button>
      </div>
    </form>
  )
}

export const activitiesFilterOps = {
    user_name: {
      inputType: 'text',
      placeholder: 'Enter User Name',
      title:'User Name'
    },
    asset : {
      inputType: 'dropdown',
      placeholder: 'Please Enter Asset Serial No.',
      title: 'Asset',
      options:  [],
      labelKey: 'unique_name',
      valueKey: 'id'
    },
    activity_type : {
      inputType: 'dropdown',
      placeholder: 'Please select Activity Type',
      title: 'Activity Type',
      options:  [],
      labelKey: 'label',
      valueKey: 'value'
    }
  };
  export const notificationPreferences = [
    { label: 'Immediate Changes', value: 1 },
    { label: 'Daily', value: '0' },
    { label: 'Weekly', value: 2 },
    { label: 'Monthly', value: 3 }
];
let days=[]
for ( let i=1; i<=31; i++) {
    days.push({ label: i.toString(), value: i });
}
export const notificationDays = days;

export const notificationWeekdays = [
    { label: 'Monday', value: '0' },
    { label: 'Tuesday', value: 1 },
    { label: 'Wednesday', value: 2 },
    { label: 'Thursday', value: 3 },
    { label: 'Friday', value: 4 },
    { label: 'Saturday', value: 5 },
    { label: 'Sunday', value: 6 }
];
export const customAlrtFilterOps ={
  activity_type : {
    inputType: 'dropdown',
    placeholder: 'Please select Activity Type',
    title: 'Activity Type',
    options:  [],
    labelKey: 'label',
    valueKey: 'value'
  },
  alert_frequency_type : {
    inputType: 'dropdown',
    placeholder: 'Please select Notification Preference',
    title: 'Notification Preference',
    options:  notificationPreferences,
    labelKey: 'label',
    valueKey: 'value'
  },

};

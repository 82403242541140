import React from 'react'
import { withRouter} from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
const RenameFile = ({renameFile, onChangeName, toggleModalFn, onChangeFileName, saveFileName}) => {
  return(
    <Dialog
      open={renameFile.modal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        Rename
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              fullWidth
              margin="normal"
              value={renameFile.data.name}
              onChange={(e) => onChangeFileName(e.target.value)}
              InputLabelProps={{shrink: true}}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleModalFn(false)}>Cancel</Button>
        <Button onClick={saveFileName} variant="contained"  color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  )
}
export default RenameFile;

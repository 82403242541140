import React, { Fragment, useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Drawer, Button, CircularProgress, Tooltip } from '@material-ui/core';
import { MentionsInput, Mention } from 'react-mentions'
import Comment from './Comment';
import { PageLoader, DeletePopUp } from '../../../shared_elements';
import { globalGetService, globalPostService, globalDeleteService } from '../../../utils/globalApiServices';
import { checkApiStatus, checkPermission } from '../../../utils';
import CommentIcon from '@material-ui/icons/Comment';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
const queryString = require('query-string');
const Comments = ({ match, history, location, fileInfo,navigation}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [comments, setComments] = useState([]);
    const [commentObj, setComment] = useState({ comment: '' });
    const [userList, setUserList] = useState([]);
    const [subComments, setSubComments] = useState([]);
    const [isSubCommentsOpen, setSubCommentsOpen] = useState(false);
    const [deleteComment, setDeleteComment] = useState({ modal: false, data: null })
    const [pageloder, setpageloder] = useState(true);


    useEffect(() => {
        if (window.location.search === "?comment_id") {
            getComments();
            getUserList();
            setOpen(true);
        }
    }, [window.location.search === "?comment_id"]);
    
    const getUserList = () => {
        globalGetService('records/userslist/', { application: 6 }).then((response) => {
            if (checkApiStatus(response)) {
                setUserList(response.data.data);
            }
        })
    }
    const getComments = () => {
        if (pageloder) { setpageloder(true); }
        globalGetService('records/comments/?fields=id,status,record_type,record_uuid,asset_id,redirect_url,created_at,comment,sub_comments&sort_by=created_at&sort=desc', { record_type: 2, record_uuid: match.params.uuid, }).then((response) => {
            if (checkApiStatus(response)) {
                if (pageloder) { setpageloder(false); }
                setComments(response.data.data);
                setOpen(true);
                let element = document?.getElementById("end-of-comments");
                element?.scrollIntoView({ block: "end" });
            }
        })
    }
    const addEditComment = (e, parent_comment_id) => {
        e.preventDefault();
        setLoading(true);
        globalPostService('records/comments/', { record_type: 2, record_uuid: match.params.uuid, comment: commentObj.comment, redirect_url: match.url, asset_id: fileInfo.id, parent: parent_comment_id }).then((response) => {
            setLoading(false);
            if (checkApiStatus(response)) {
                setComment({ comment: '' })
                if (parent_comment_id) {
                    getSubComments(parent_comment_id)
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    getComments();
                }
            } else {
                enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
        });

    }
    const getSubComments = (comment_id) => {
        globalGetService(`records/comments/?fields=id,status,record_type,record_uuid,asset_id,redirect_url,comment,sub_comments&sort_by=created_at&sort=desc`, { record_type: 2, record_uuid: match.params.uuid, id: comment_id }).then((response) => {
            if (checkApiStatus(response)) {
                setSubComments(response.data.data);
                setSubCommentsOpen(true);
                let element = document?.getElementById("end-of-comments");
                element.scrollIntoView({ block: "end" });
            }
        })
    }
    const onDeleteComment = () => {
        setLoading(true)
        globalDeleteService(`records/comments/${deleteComment.data.id}/`, { record_type: 2, record_uuid: match.params.uuid })
            .then((response) => {
                if (checkApiStatus(response)) {
                    setLoading(false)
                    setDeleteComment({ modal: false, data: null })
                    if (deleteComment.data?.parent) {
                        getSubComments(deleteComment?.data?.parent)
                    } else {
                        getComments();
                    }
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            });
    }
    const closeDrawer = () => {
        let query = queryString.parse(location.search);
        let urlPath = location;
        delete query.comment_id;
        history.push({...urlPath, search:queryString.stringify(query)});
    };
    return (
        <Fragment>
                  <Tooltip title="Comments" arrow>
            <CommentIcon onClick={() => { getComments(); getUserList() }} color="primary" style={{ cursor: 'pointer' }} />
            </Tooltip>
            {isOpen ?
                <Drawer anchor={'right'} open={true} onClick={closeDrawer} onClose={() => setOpen(false)} >
                    <div className='comments-wrapper'  >
                        <div className='comments-header'>
                            <div className="flex-centered">
                                <h2>Comments</h2>
                                <CloseIcon fontSize='small' onClick={() => { setOpen(false); setComments([]); setComment({}) }} style={{ marginLeft: 'auto', cursor: 'pointer' }} />
                            </div>
                            {navigation && navigation.length ?
                                        navigation.map((nav, index) =>
                                            navigation.length - 1 === index
                                            && <span style={{fontSize:'12px',fontFamily:"Conv_IBMPlexSans-Medium"}}>Folder: { nav.name}</span>
                                        ) : null
                                    }
                        </div>
                        {pageloder ? <PageLoader /> :
                            <>
                                <div className='comments-body' style={{ height: `${window.innerHeight - 212}px`, overflow: 'auto' }}>
                                    {comments.map((item, index) =>
                                        <div className='comment-card'>
                                            <Comment
                                                fileInfo={fileInfo}
                                                users={userList}
                                                item={item}
                                                subComments={subComments}
                                                getSubComments={() => getSubComments(item.id)}
                                                getResponseBack={() => getComments()}
                                                onDelete={() => setDeleteComment({ modal: true, data: item })}
                                            />
                                        </div>
                                    )}
                                    {!comments?.length > 0 && <div style={{ textAlign: 'center', marginTop: "10px" }}>No comments found</div>}
                                    <div id="end-of-comments" />
                                </div>
                                { checkPermission('records', 'comments_page','C') ?
                                    <div className='comments-footer'>
                                        <form onSubmit={(e) => addEditComment(e, null)}>
                                            <div>
                                                <MentionsInput
                                                    className="comment-input"
                                                    placeholder="Type @ to mention someone"
                                                    style={{ minHeight: '100px' }}
                                                    value={commentObj.comment} onChange={(event, newValue, newPlainTextValue, mentions) => setComment({ ...commentObj, comment: newValue })}
                                                    allowSuggestionsAboveCursor={true}
                                                    forceSuggestionsAboveCursor={true}
                                                    maxLength={500}
                                                >
                                                    <Mention
                                                        trigger="@"
                                                        data={userList.map(item => { return { id: item.email, display: item.name } })}
                                                    />
                                                </MentionsInput>

                                            </div>
                                            <Button disabled={isLoading || commentObj?.comment?.trim().length === 0} type="submit" variant="contained" color="primary" size='small'>
                                                {isLoading ? <CircularProgress size={'small'} /> : 'Submit'}
                                            </Button>
                                        </form>
                                    </div>:null
                                }     
                                
                            </>
                        }
                        {pageloder ? <h5 style={{textAlign:'center',marginTop:'20px'}}>Processing...</h5> : null}
                    </div>
                </Drawer> : null
            }
            {isSubCommentsOpen ? (
                <Drawer anchor={'right'} open={true} onClose={() => setSubCommentsOpen(false)}>
                    <div className="comments-wrapper">
                        <div className='comments-header'>
                            <div className="flex-centered">
                                <ArrowBackIcon onClick={() => { setSubCommentsOpen(false);getComments(); }} color="primary" style={{ cursor: 'pointer' }} />
                                <span>{subComments[0]?.comment}</span>
                            </div>
                        </div>
                        <div className="comments-body" style={{ height: `${window.innerHeight - 212}px`, overflow: 'auto' }}>
                            {subComments?.find(items => items?.sub_comments)?.sub_comments.map((item, index) => (
                                <div className="comment-card">
                                    <Comment
                                        fileInfo={fileInfo}
                                        users={userList}
                                        item={item}
                                        isSubCommentsOpen={isSubCommentsOpen}
                                        getResponseBack={() => getSubComments(item.parent)}
                                        getSubComments={() => getSubComments(item.parent)}
                                        onDelete={() => setDeleteComment({ modal: true, data: item })}
                                    />
                                </div>
                            ))}
                            {subComments.map(item => item.sub_comments.length === 0 && <div style={{ textAlign: 'center', marginTop: "10px" }}>No sub-comments found</div>)}
                            <div id="end-of-comments" />
                        </div>
                        {subComments[0]?.status === 1 ? null : 
                            checkPermission('records', 'comments_page','C')?
                            <div className='comments-footer'>
                                <form onSubmit={(e) => addEditComment(e, subComments[0]?.id)}>
                                    <div>
                                        <MentionsInput
                                            className="comment-input"
                                            placeholder="Type @ to mention someone"
                                            style={{ minHeight: '100px' }}
                                            value={commentObj.comment} onChange={(event, newValue, newPlainTextValue, mentions) => setComment({ ...commentObj, comment: newValue })}
                                            allowSuggestionsAboveCursor={true}
                                            forceSuggestionsAboveCursor={true}
                                            maxLength={500}
                                        >
                                            <Mention
                                                trigger="@"
                                                data={userList.map(item => { return { id: item.email, display: item.name } })}

                                            />
                                        </MentionsInput>

                                    </div>
                                    <Button disabled={isLoading || commentObj?.comment?.trim().length === 0} type="submit" variant="contained" color="primary" size='small'>
                                        {isLoading ? <CircularProgress size={'small'} /> : 'Submit'}
                                    </Button>
                                </form>
                            </div>:null
                        }
                    </div>
                </Drawer>
            ) : null}
            {deleteComment.modal ?
                <DeletePopUp
                    modal={deleteComment.modal}
                    toggleModalFn={() => setDeleteComment({ modal: false, data: null })}
                    title="Delete Comment"
                    content={<h4>Are you sure you want to delete?</h4>}
                    triggerEventFn={() => onDeleteComment()}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </Fragment>
    )
}
export default withRouter(Comments);
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import { getFileSize, getFileIcon, checkPermission } from '../../../utils';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { displayDateFormatShort, imgPath } from '../../../constants';
const DataKitList = ({item , history, fileIcons, bulkOperation, toggleBulkOps, listSingleActionFn}) => {
  return(
    <TableRow hover tabIndex={-1}>
      { checkPermission('records','category','D') ?
        <TableCell width="30">
          <Checkbox
            name={item.id}
            color="primary"
            className="checkbox-icon"
            size="small"
            onChange={(e) => toggleBulkOps(e.target.checked)}
            checked={bulkOperation.ids.includes(item.uuid)}
          />
        </TableCell>:null
      }
      <TableCell className="file-info" style={{cursor: 'pointer'}} width="480" onClick={() => window.open(`/records/preview/${item.uuid}`, '_blank')}>
        <div className="flex-centered">
          <img className="folder-icon" width="20" src={getFileIcon(fileIcons, item.extension)} alt="Folder" />
          <div>
            <h5>{item.name}</h5>
            <p>{item.location}</p>
          </div>
        </div>
      </TableCell>
      <TableCell>{getFileSize(item.size)}</TableCell>
      <TableCell className="actions-cell">
        { checkPermission('records','category','D') ?
          <HighlightOffIcon onClick={() => listSingleActionFn('Remove')} color="secondary" />:null
        }
      </TableCell>
    </TableRow>
  )
}
export default withRouter(DataKitList);

import React, { useState, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux'
import { Menu, MenuItem, TextField,Chip, Grid } from '@material-ui/core';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { checkApiStatus, localTimeFn, checkPermission } from '../../../utils';
import { getAllAssetListAc } from '../../../shared_elements/actionCreators';
import { revAssetTypeValues, assetTypeValues } from '../../../constants';
import { globalPutService } from '../../../utils/globalApiServices';
const IssueStatus = ({prsInfo, changeStatus}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (status) => {
        handleClose();
        changeStatus(status)
    };
    return(
        <Fragment>
            <span className={`issue-status`}>
                <span>
                    <Chip label={<span className='flex-centered'><span>{prsInfo?.status?.label || ''}</span>{ checkPermission('records','records_issue','U') ? <ArrowDropDownIcon   onClick={(event) => setAnchorEl(event.currentTarget)} fontSize="small" style={{ color: '#ffffff',float:'right' }} />:null}</span>} size="small"
                        style={{  background:prsInfo?.status.value === 0 && '#4b81de' || prsInfo?.status.value === 1 && '#269b2c' || prsInfo?.status.value === 2 && '#e86642'|| prsInfo?.status.value === 3 && '#dbcc28', color: '#ffffff',width: '110px'}}
                    />
                </span>
            </span>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                >
                    {(() => {
                        let statusOptions = [{label:'Closed', value:1}, {label:'Reopen', value:2}, {label:'Unresolved', value:3}].filter((item) => item.value !== prsInfo.status.value);
                        return(
                            <>
                                {statusOptions.map((item) =>
                                    <MenuItem onClick={() => handleClick(item)}>{item.label}</MenuItem>
                                )}
                            </>
                        )
                    })()}
            </Menu>
        </Fragment>
    )
}
const IssueTitle = ({match, history, prsDetail,getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [prsInfo, setPrsInfo] = useState(prsDetail)
    const allAssets = useSelector(state => state?.shareReducer?.allAssets||[])
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllAssetListAc())
    },[])
    const onUpdatePrsInfo = (key, value, required) => {
        globalPutService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/issues/${match.params.slug}/`, {[key]:value})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getResponseBack()
            }else{
                enqueueSnackbar(response.data.message, { autoHideDuration:5000, variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    return(
        <div className='prs-title-info'>
            <Grid container spacing={1} className='flex-centered' >
                <Grid item md={6}>
                    <ul className='list-inline flex-centered'>
                        <li className='list-inline-item' onClick={() => history.push(`/${match.params.assetType}/${match.params.assetSlug}/data-room/issues`)} style={{cursor:'pointer'}}>
                            <p  className='flex-centered'><ArrowBackIcon color='primary' fontSize='small' /> <span>Issues(s)</span> <span style={{paddingLeft:'4px'}}>[{prsInfo?.unique_code || ''}]</span></p>
                        </li>
                    </ul>
                </Grid>
                <Grid item md={6}>
                    {allAssets?.length ?
                        <>
                            {(() => {
                                let asset = allAssets.find(asset => asset.slug === match.params.assetSlug && asset.asset_type === revAssetTypeValues[match.params.assetType].assetType)
                                return(
                                    <div style={{float:'right'}}>
                                        <h5>{asset?.unique_name}</h5>
                                    </div>
                                )
                            })()}
                        </>:null
                    }
                </Grid>
            </Grid>
            
            
            <form>
                <TextField 
                    disabled={!checkPermission('records','records_issue','U')}
                    id="title"
                    placeholder='Issue Title'
                    fullWidth
                    margin="none"
                    value={prsInfo?.title || ''}
                    onChange={(e) => setPrsInfo({...prsInfo, title:e.target.value})}
                    onBlur={() => onUpdatePrsInfo('title', prsInfo.title, true)}
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    inputProps={{ maxLength: 100 }}
                />
            </form>
            <ul className='list-inline user-info'>
                <li className='list-inline-item status-label'>
                    <IssueStatus prsInfo={prsInfo} changeStatus={(data) => onUpdatePrsInfo('status', data.value, true)} />
                </li>
                <li className='list-inline-item'>
                    <span className='span-label'>Created By</span>: <span style={{fontSize:'13px'}}>{prsInfo?.created_by?.name || '--'}</span>
                </li>
                { prsInfo?.updated_by?.name ?
                    <li className='list-inline-item'>
                        <span className='span-label'>Last Updated By</span>: <span style={{fontSize:'13px'}}>{prsInfo?.updated_by?.name||''} at {localTimeFn(prsInfo?.updated_at||'')}</span>
                    </li>:null
                }
            </ul>
        </div>
    )
}
export default withRouter(IssueTitle);
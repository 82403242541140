import React from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { Button, Popover, Tooltip } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../constants';
const ChartDateFilter = ({customDateFlag, start_date, end_date, onChangeDays, onChangeCustomDate}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setStartDate(null);
    setEndDate(null)
  };
  const onChangeDate = (keyParam, value) => {
    if(keyParam === 'start_date'){
      setStartDate(value)
    }else{
      setEndDate(value)
    }
  }
  const onApplyDateRange = ({}) => {
    if(startDate && endDate){
      let days = moment(endDate, 'YYYY.MM.DD').endOf('day').diff(moment(startDate, 'YYYY.MM.DD').endOf('day'), 'days');
      if(days >= 0){
        handleClose();
        onChangeCustomDate(startDate, endDate);
      }else{
        enqueueSnackbar('Start date cannot be greater then end date', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    }else{
      enqueueSnackbar('Please enter both date', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return(
    <div className="date-range-filter">
      <ul className="list-inline">
        <li className={`list-inline-item ${customDateFlag ? 'active':''}`}>
          <span aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
            {customDateFlag ? `${moment(start_date).format(displayDateFormatShort)}-${moment(end_date).format(displayDateFormatShort)}`:'Custom Date'}
          </span>
          <Popover
             id={id}
             open={open}
             anchorEl={anchorEl}
             onClose={handleClose}
             anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
             }}
             transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
             }}
           >
             <div className="date-range-picker-popover">
               <ul className="list-unstyled">
                <li>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="none"
                       id="start_date"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture
                       value={startDate}
                       InputLabelProps={{shrink: true}}
                       placeholder="Start Date"
                       onChange={(data, value) => onChangeDate('start_date', data ? moment(data).format(backendDateFormat):data)}
                     />
                  </MuiPickersUtilsProvider>
                </li>
                <li>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       margin="none"
                       id="end_date"
                       format={fieldDateFormat}
                       fullWidth
                       disableFuture
                       InputLabelProps={{shrink: true}}
                       value={endDate}
                       placeholder="End Date"
                       onChange={(data, value) => onChangeDate('end_date', data ? moment(data).format(backendDateFormat):data)}
                     />
                  </MuiPickersUtilsProvider>
                </li>
               </ul>
               <ul className="list-inline">
                <li className="list-inline-item">
                  <Button size="small" color="primary" variant="outlined" onClick={handleClose}>Cancel</Button>
                </li>
                <li className="list-inline-item">
                  <Button onClick={onApplyDateRange} size="small" color="primary" variant="contained">Apply</Button>
                </li>
               </ul>
             </div>
           </Popover>
        </li>
        <li className={`list-inline-item ${ !customDateFlag && moment(end_date, 'YYYY.MM.DD').endOf('day').diff(moment(start_date, 'YYYY.MM.DD').endOf('day'), 'days') === 0 ?'active':''}`} onClick={() => onChangeDays(0)}>
          <Tooltip title={moment().subtract(0, 'days').format(displayDateFormatShort)} arrow>
            <span>Today</span>
          </Tooltip>
        </li>
        <li className={`list-inline-item ${ !customDateFlag && moment(end_date, 'YYYY.MM.DD').endOf('day').diff(moment(start_date, 'YYYY.MM.DD').endOf('day'), 'days') === 1 ?'active':''}`} onClick={() => onChangeDays(1)}>
          <Tooltip title={moment().subtract(1, 'days').format(displayDateFormatShort)} arrow>
            <span>Yesterday</span>
          </Tooltip>
        </li>
        <li className={`list-inline-item ${ !customDateFlag && moment(end_date, 'YYYY.MM.DD').endOf('day').diff(moment(start_date, 'YYYY.MM.DD').endOf('day'), 'days') === 7 ?'active':''}`} onClick={() => onChangeDays(7)}>
          <Tooltip title={`${moment().subtract(7, 'days').format(displayDateFormatShort)} ${moment().format(displayDateFormatShort)}`} arrow>
            <span>7D</span>
          </Tooltip>
        </li>
        <li className={`list-inline-item ${ !customDateFlag && moment(end_date, 'YYYY.MM.DD').endOf('day').diff(moment(start_date, 'YYYY.MM.DD').endOf('day'), 'days') === 30 ?'active':''}`} onClick={() => onChangeDays(30)}>
          <Tooltip title={`${moment().subtract(30, 'days').format(displayDateFormatShort)}-${moment().format(displayDateFormatShort)}`} arrow>
            <span>1M</span>
          </Tooltip>
        </li>
      </ul>
    </div>
  )
}
export default ChartDateFilter;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack';
import 'rc-tree/assets/index.css';
import { isMobile } from 'react-device-detect';
import Tree, { TreeNode } from 'rc-tree';
import { globalGetService } from '../../../utils/globalApiServices';
import { checkPermission, checkApiStatus, getFileIcon } from '../../../utils';
import { ReportsTab } from '../components';
import { ExportManu, PageLoader, EmptyCollection } from '../../../shared_elements';
import { assetTypeValues } from '../../../constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Grid, Paper } from '@material-ui/core';
import folderIcon from '../../../assets/images/folder_icon.svg';
import openFolderIcon from '../../../assets/images/open_folder.svg';
import { getAllAssetListAc } from '../../../shared_elements/actionCreators';
const InventoryTree = ({treeList, onSelect, fileIcons=[]}) => {
    const loop = (data) => {
        return data.map((item) => {
            if (item.children) {
                return <TreeNode icon={(props) => props.expanded ? <img width="14" src={openFolderIcon} alt="" /> : <img width="14" src={folderIcon} alt="" />} title={item.name} type={item.type} uuid={item.uuid} fileItem={item} key={item.uuid}>{loop(item.children)}</TreeNode>;
            }
            return (
                <TreeNode icon={item.type == "file" ? <img src={getFileIcon(fileIcons, 'pdf')} width="14" /> : <img src={folderIcon} alt="" />} title={item.name} fileItem={item} type={item.type} uuid={item.uuid} key={item.uuid} isLeaf={item.type == "folder" ? false : true} disabled={item.leaf && item.type != 'folder' ? 'disabled' : ''}/>
            );
        });
    };
    let treeNodes = loop(treeList.children);
    return(
        <Tree
            onSelect={onSelect}
            showLine={true}
            multiple={true}
            autoExpandParent={true}
            defaultExpandAll={true}
            defaultExpandParent={true}
            >
            {treeNodes}
        </Tree>
    )
}
const Inventories = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [inventoriesInfo, setInventoriesInfo] = useState({name:'', children:[]});
    const [selectAsset, setSelectAsset] = useState(null)
    const allAssets = useSelector(state => state?.shareReducer?.allAssets||[])
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllAssetListAc())
    },[]);
    const onSelect = (info, node) => {
        if(node.node.props.type == 'file'){
          window.open(`/records/preview/${node.node.props.fileItem.uuid}`, '_blank')
        }
    }
    const getInventoriesInfo = (asset) => {
        setInventoriesInfo({name:'', children:[]});
        if(asset){
            setLoading(true);
            globalGetService(`records/v2/${assetTypeValues[asset.asset_type].urlType}/${asset.slug}/report/inventory/`)
            .then(response => {
                if(checkApiStatus(response)){
                    setInventoriesInfo(response.data.data)
                }
                setLoading(false);
            })
        }
    }
    const exportAssetInventory = (asset) => {
        setLoading(true)
        globalGetService(`records/v2/${assetTypeValues[asset.asset_type].urlType}/${asset.slug}/report/inventory/`,{download:true})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message,{variant:'success',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
            }else{
                enqueueSnackbar(response.data.message,{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
            }
            setLoading(false)
        });
    }
    return(
        <section className='fleets-management inventory'>
            <ReportsTab tabIndex="inventory" />
            <Paper sqaure style={{padding:'5px 10px'}}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item md={4} xs={inventoriesInfo?.children?.length > 0 ? 8 : 12}>
                        <div className='records-dataroom-modal'>
                            <Autocomplete
                                options = {allAssets.sort((a, b) => a.unique_name !== b.unique_name ? a.unique_name < b.unique_name ? -1 : 1 : 0)}
                                clearOnBlur={false}
                                getOptionLabel={option => option.unique_name}
                                filterSelectedOptions={true}
                                value={selectAsset}
                                onChange={(e, value) => {setSelectAsset(value);getInventoriesInfo(value)}}
                                renderInput={params => <TextField {...params} label="Select Asset" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                            />
                        </div>
                        
                    </Grid>
                    { inventoriesInfo?.children?.length > 0 && checkPermission('reports','inventory','EXP') ?
                        <Grid item md={8} xs={4}>
                            <ul className='list-inline' style={{float:'right'}}>
                                <li className='list-inline-item'>
                                    <ExportManu
                                        title="Export"
                                        files={[{title:'Excel', extension: 'xlsx', key:''}]}
                                        exportReportFn={(file) => exportAssetInventory(selectAsset)}
                                    />
                                </li>
                            </ul>
                        </Grid>:null
                    }
                </Grid>
            </Paper>
            <Paper className='mobile-rc-tree-paper' style={{minHeight:'380px', marginTop:'15px'}}>
                { !selectAsset?.slug ? <h5 style={{textAlign:'center', padding:'60px 0'}}>Select an asset to get Inventory Report</h5>:null}
                { inventoriesInfo?.children?.length ?
                    <div style={{overflow:'auto'}}>
                        <InventoryTree 
                            treeList={inventoriesInfo}
                            onSelect={onSelect}
                            fileIcons={[]}
                        />
                    </div>:
                    selectAsset?.slug ?
                    <EmptyCollection
                        title="No Records Found"
                    /> : null
                }
            </Paper>
            { isLoading ? <PageLoader/>:null }
        </section>
    )
}
export default Inventories;
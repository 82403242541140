export const assetHd = [
  { id:'asset_name', label:'Asset(s)', sortOption:true },
  { id:'records_count', label:'No. of File(s)', sortOption:true },
  { id:'records_size', label:'Records Size', sortOption:true },
  { label:'File(s) In Queue', sortOption:false },
  { label:'OCR File(s) In Queue', sortOption:false },
  { label:'File(s) to be renamed', sortOption:false },
  { label:'Action', sortOption:false},
]
export const assetFilterOps = {
  // serial_number: {
  //   inputType: 'text',
  //   placeholder: 'Enter asset serial number',
  //   title:'Serial No'
  // },
  aircraft_type : {
    inputType: 'dropdown',
    placeholder: 'Select Aircraft Type',
    title: 'Aircraft Type',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  engine_type : {
    inputType: 'dropdown',
    placeholder: 'Select Engine Type',
    title: 'Engine Type',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  apu_type : {
    inputType: 'dropdown',
    placeholder: 'Select APU Type',
    title: 'APU Type',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
}
export const aircraftErrorCode = {
  msn:{
    0:'',
    1:'Please enter MSN'
  },
  msnObj:{
    required:true
  },
  aircraft_type:{
    0:'',
    1:'Please select Aircraft Type'
  },
  aircraft_typeObj:{
    required:true
  },
  date_of_manufacture:{
    0:'',
    1:'Please enter Date of Manufacture'
  },
  date_of_manufactureObj:{
    required:true
  },
  status:{
    0:'',
    1:'Please select Lease Status'
  },
  statusObj:{
    required:true
  }
}
export const engineErrorCode = {
  esn:{
    0:'',
    1:'Please enter ESN'
  },
  esnObj:{
    required:true
  },
  engine_type_id:{
    0:'',
    1:'Please select Engine Type'
  },
  engine_type_idObj:{
    required:true
  },
  engine_module_type_id:{
    0:'',
    1:'Please select Engine Module Type'
  },
  engine_module_type_idObj:{
    required:true
  },
  manufacturing_date:{
    0:'',
    1:'Please enter Date of Manufacture'
  },
  manufacturing_dateObj:{
    required:true
  },
  status:{
    0:'',
    1:'Please select Lease Status'
  },
  statusObj:{
    required:true
  },
  region_of_operation:{
    0:'',
    1:'Please enter Region of Operation'
  },
  region_of_operationObj:{
    required:true
  },
}
export const alpErrorCode = {
  serial_number:{
    0:'',
    1:'Please enter Serial Number'
  },
  serial_numberObj:{
    required:true
  },
  part_number:{
    0:'',
    1:'Please enter Part Number'
  },
  part_numberObj:{
    required:true
  },
  date_of_manufacture:{
    0:'',
    1:'Please enter Date of Manufacture'
  },
  date_of_manufactureObj:{
    required:true
  },
  apu_type:{
    0:'',
    1:'Please select APU Type'
  },
  apu_typeObj:{
    required:true
  },
  position:{
    0:'',
    1:'Please select Position'
  },
  positionObj:{
    required:true
  },
  status:{
    0:'',
    1:'Please select Lease Status'
  },
  statusObj:{
    required:true
  }
}

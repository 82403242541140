import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './assets/styles/fonts.scss';
import './assets/styles/common.scss';
import './assets/styles/layout.scss';
import './assets/styles/home.scss';
import './assets/styles/action_docks.scss';
import './assets/styles/data_room.scss';
import './assets/styles/normalize.scss';
import './assets/styles/material_ui_override.scss';
import './assets/styles/fonts.scss';
import './assets/styles/auth.scss';
import './assets/styles/users.scss';
import './assets/styles/auto_organiser.scss';
import './assets/styles/settings.scss';
import './assets/styles/data_templates.scss';
import reportWebVitals from './reportWebVitals';
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

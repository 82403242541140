import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import Tree, { TreeNode } from 'rc-tree';
import 'rc-tree/assets/index.css';
import { CircularProgress, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, Checkbox, FormGroup, FormControlLabel,TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getLocalStorageInfo, checkApiStatus, checkPermission } from '../../../utils'
import { globalGetService } from '../../../utils/globalApiServices';
import folderIcon from '../../../assets/images/folder_icon.svg'
import openFolderIcon from '../../../assets/images/open_folder.svg'
import { FolderCard, DataRoomHeader, DataRoomLayoutSetting, DataRoomNav, EmptyFolder, ListLayout, FileStatusCount, OcrFileCount, DataRoomChart, NewFolderModal, DataUploadModal, ShareContentModal, RenameFileFolderModal, MoveDataModal, ContextMenuList, Properties, UploadFilesIssueModal, LicenseFailModal, DataRoomSearch, DataRoomRefLink, DataRoomTags, DMTagsPreview } from '../components';
import { TableListComp, PageLoader, ChartDateFilter, LicenceModal } from '../../../shared_elements';
import { SCardLoader, STableLoader } from '../../../shared_elements/loaders';
import {getRecordsDetailApiV_2,
  getRecordsDetailApi, getDataRoomListApi, getDataRoomTreeListApi, getMiniPackAssemblyApi, changeDMFilesStatusApi, getDMDataPropertiesApi, onDeleteDMDataApi, onRenameDMDataApi, addFilesToMiniPackAndKitApi, getReourcesKitsFolderApi, downloadResourceKitApi, createNewFolderApi, getMoveFoldersApi, onMoveDmDataApi, shareDataRoomDataApi, getFodlerCollabUserApi, uploadFileCheckerApi, uploadFileDataApi, getDataRoomAnalyticApi, downloadDataRoomApi, trackFileDownloadActivity
,getParentuuid,getParentuuidApi, getMoveBackFoldersApi} from '../apiServices';
import { getPlatformConstantsAc, getAllAssetListAc, getStorageSassUsageAc, getRecordsReposAc } from '../../../shared_elements/actionCreators';
import { updateTheFileStacksAc, updateAfterFileStacksAc } from '../actionCreators';
import { imgPath, backendDateFormat, revAssetTypeValues } from '../../../constants'
import { dmRoomListHd, dmRoomOcrListHd } from '../';
import { UNMOUNT_ASSET_LIST } from '../../../shared_elements/actions'
import { Autocomplete } from '@material-ui/lab';
import { assetTypeValues } from '../../../constants';
import * as actions from '../actions';
import PaginationComp from '../../../shared_elements/components/PaginationComp';
import SendViaEmail from '../components/SendViaEmail';
import ApplyTemplate from '../components/ApplyTemplate';
class DataRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAsset:'',
      skeletonLoader: false,
      pageLoader: false,
      licenseModal: false,
      licenceModalFlag: false,
      licenceModalMessage: '',
      sort_by: '',
      sort: '',
      start_date: moment().subtract(7, 'days').format(backendDateFormat),
      end_date: moment().format(backendDateFormat),
      customDateFlag: false,
      showUpload: true,
      selecteduuid: '',
      viewMode: 2, // 1: Card layout, 2: List Layout 3: Grahp Layout
      newFolderModal: false,
      isRootNavigation: false,
      selectedItems: {
        ids: []
      },
      dataRename: {
        modal: false,
        data: null
      },
      dataRoomTree: [],
      resourcesKits: [],
      dataRoom: {
        list: [],
        navigation: [],
        pagination: null
      },
      treeNavs: [],
      moveData: {
        moveType: '',
        name: '',
        moveFolderuuid: '',
        modal: false,
        sourceId: '',
        sourceType: '',
        navigation: [],
        list: []
      },
      shareContent: {
        modal: false,
        data: {}
      },
      dataProperties: {
        open: false,
        data: null
      },
      uploadFileIssue: {
        modal: false,
        file_naming_conventions: [],
        improper_files: [],
        overwrite_files: []
      },
      infoModal: {
        open: false,
        item: {}
      },
      asset: {},
      analyticStats: {},
      parentUuid:'',
      fileInfo:{},
      dmRefLink:{modal:false, uuid:'', type:''},
      dmTags:{modal:false, data:null},
      applyTemplateMoldal:false
    };
    this.getDataRoomListApi = getDataRoomListApi.bind(this);
    this.getParentuuidApi = getParentuuidApi.bind(this);
    this.getMoveBackFoldersApi = getMoveBackFoldersApi.bind(this);
    this.getParentuuid = getParentuuid.bind(this);
    this.getDataRoomTreeListApi = getDataRoomTreeListApi.bind(this);
    this.getMiniPackAssemblyApi = getMiniPackAssemblyApi.bind(this);
    this.getDMDataPropertiesApi = getDMDataPropertiesApi.bind(this);
    this.changeDMFilesStatusApi = changeDMFilesStatusApi.bind(this);
    this.onDeleteDMDataApi = onDeleteDMDataApi.bind(this);
    this.onRenameDMDataApi = onRenameDMDataApi.bind(this);
    this.addFilesToMiniPackAndKitApi = addFilesToMiniPackAndKitApi.bind(this);
    this.getReourcesKitsFolderApi = getReourcesKitsFolderApi.bind(this);
    this.downloadResourceKitApi = downloadResourceKitApi.bind(this);
    this.createNewFolderApi = createNewFolderApi.bind(this);
    this.getMoveFoldersApi = getMoveFoldersApi.bind(this);
    this.onMoveDmDataApi = onMoveDmDataApi.bind(this);
    this.shareDataRoomDataApi = shareDataRoomDataApi.bind(this);
    this.getFodlerCollabUserApi = getFodlerCollabUserApi.bind(this);
    this.uploadFileCheckerApi = uploadFileCheckerApi.bind(this);
    this.uploadFileDataApi = uploadFileDataApi.bind(this);
    this.getDataRoomAnalyticApi = getDataRoomAnalyticApi.bind(this);
    this.getRecordsDetailApi = getRecordsDetailApi.bind(this);
    this.downloadDataRoomApi = downloadDataRoomApi.bind(this);
    this.trackFileDownloadActivity = trackFileDownloadActivity.bind(this);
    this.getRecordsDetailApiV_2 = getRecordsDetailApiV_2.bind(this);
    
  }
  componentDidMount() {
    if (getLocalStorageInfo().user.permission.records.is_saas) {
      this.props.getStorageSassUsage();
    }
    if (!this.props.allAssets.length) {
      this.props.getAllAssetListFn()
    }
    this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: 'asc', per_page: 40, page: 1 }, 'skeletonLoader');
    this.getDataRoomTreeListApi(this.props, this.props.match.params.uuid, { root_structure: true, sort: 'name', sort_by: 'asc' });
    this.getMiniPackAssemblyApi(this.props);
    this.getReourcesKitsFolderApi(this.props);
    this.getRecordsDetailApi({ asset_type: revAssetTypeValues[this.props.match.params.assetType].assetType, slug: this.props.match.params.assetSlug })
      .then(response => this.setState({ asset: response.data.data }))
    if (!this.props.pltConstants.filter(item => item.type === 'file_extension_drive').length) {
      this.props.getPlatformConstants();
    }
    //hidden to manage usage
    // if (this.props.match.params.type === 'technical') {
    //   this.getDataRoomAnalyticApi(this.props, { folder_uuid: this.props.match.params.uuid })
    // }

    this.getRecordsDetailApiV_2(this.props);

  }
  componentWillReceiveProps(nextProps, state) {
    if (nextProps.match.params.uuid !== this.props.match.params.uuid) {
      this.getDataRoomListApi(this.props, nextProps.match.params.uuid, { sort: 'name', sort_by: 'asc', per_page: 40, page: 1 }, 'pageLoader');
    }
  }
  componentWillUnmount() {
    this.props.unmountAssetList()
  }
  fetchContextMenu = (item) => {
    const { resourcesKits, selectedItems, dmRefLink } = this.state;
    return (
      <ContextMenuList
      onSendViaEmail ={this.onSendViaEmail}
        resourcesKits={resourcesKits}
        item={item}
        setselectedAsset={this.setselectedAsset}
        onChangeNav={() => { this.props.history.push(`/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/data-room/${this.props.match.params.type}/${item.uuid}`); this.setState({ selectedItems: { ids: [] } }) }}
        changeDMFilesStatus={(data) => this.changeDMFilesStatusApi(this.props, data)}
        getDMDataProperties={(data) => this.getDMDataPropertiesApi(this.props, data)}
        onDeleteDMData={(data) => this.onDeleteDMDataApi(this.props, [item])}
        addFilesToMiniPackAndKit={(data) => this.addFilesToMiniPackAndKitApi(this.props, data)}
        getMoveFolders={() => this.getMoveFoldersApi(this.props, item.uuid, item.type, this.props.match.params.uuid, item.name, 'single')}
        onShareContent={() => { this.setState({ shareContent: { modal: true, data: item }, isRootNavigation: false }); this.getFodlerCollabUserApi(this.props, item.uuid) }}
        onSetActiveUuid={(uuid) => this.setState({ selecteduuid: uuid })}
        onDownloadData={() => this.downloadDataRoomApi(this.props, [item])}
        onSelection={(flag) => this.onSelection(flag, item)}
        onMoveSelection={() => this.onMoveSelection(item)}
        onOpenRefLnk={() => this.setState({dmRefLink:{modal:true, uuid:item.uuid, type:item.type}})}
        onRenameDMData={(data) =>
          this.setState(prevState => ({
            ...prevState,
            dataRename: {
              ...prevState.dataRename,
              modal: true,
              data: data
            }
          }))
        }
      />
    )
  }
  setselectedAsset =()=>{
    const {allAssets, match}= this.props
    const selectedasset = allAssets.find(i => i.slug === match.params.assetSlug)
    this.setState({selectedAsset: selectedasset, parentUuid:''})
  }
  getuuidParent =(value)=>{
    this.setState({parentUuid:value})
  }
  getAssetFolder =(value)=>{
    const {moveData, parentUuid,selectedAsset}= this.state
    this.getParentuuidApi(value === null ? selectedAsset : value)
    .then(response=>{
      if (checkApiStatus(response)) {
        this.setState({ parentUuid: response.data.data && response.data.data.technical_records ? response.data.data.technical_records.uuid : '' })
        this.getMoveFoldersApi(this.props, moveData.sourceId, moveData.sourceType, this.props.match.params.uuid, moveData.name,'' )
      }
      else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
  }
  changeAssetSwitcher =()=>{
    const{moveData, selectedAsset, parentUuid}= this.state
    const{ allAssets}= this.props
    return(
      <Autocomplete
      options={allAssets}
      getOptionLabel={option => option.unique_name}
      value={selectedAsset}
      onChange={(e, value) => {this.setState({selectedAsset:value}) ;this.getAssetFolder(value)}}
      // setTimeout(() => {
      //   this.getMoveFoldersApi(this.props, moveData.sourceId, moveData.sourceType, this.props.match.params.uuid, moveData.name,'', value, parentUuid )
      // }, 2000)}}
      renderInput={param => <TextField {...param} margin='none' InputLabelProps={{ shrink: true }} />}
    />
    )
  }
  getMoveFirstFolders = () => {
    this.setState(prevState => ({
      ...prevState,
      moveData: {
        ...prevState.moveData,
        list: prevState.moveData.navigation.slice(0, 1),
        navigation: []
      }
    }))
  }

  getNewTreeData(treeData, curKey, child, level) {
    const loop = (data) => {
      // if (level < 1 || curKey.length - 3 > level * 2) return;
      data.forEach((item) => {
        if (item.children) {
          loop(item.children);
        } else {
          if (curKey.indexOf(item.uuid) === 0) {
            item.children = child;
          }
        }
      });
    };
    loop(treeData);
    // setLeaf(treeData, curKey, level);
  }

  redirectToAsset = () => {
    window.open(`${window.location.protocol}//${window.location.host}/${this.state.infoModal.item.stand_alone_folder_asset}/${this.state.infoModal.item.stand_alone_folder_slug}/data-room/${this.props.match.params.type}/${this.state.infoModal.item.stand_alone_folder_details.uuid}`, '_blank')
    setTimeout(() => {
      this.setState({
        infoModal: { open: false, item: {} }
      })
    }, 500);
  }

  onChangeNav = (item) => {
    this.setState({ selectedItems: { ids: [] } });
    if (item.remarks === 'stand_alone_folder') {
      this.setState({
        infoModal: {
          open: true,
          item: item
        }
      })
    } else {
      this.props.history.push(`/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/data-room/${this.props.match.params.type}/${item.uuid}${this.props.location.search.includes('archived-assets')? '?archived-assets': '' }`);
    }
  }

  onSelect = (info, node) => {
    if (node.node.type == 'file') {
      window.open(`/records/preview/${node.node.props.fileItem.uuid}`, '_blank')
    }
    if (node.node.type == 'folder' && info.length) {
      if (node.node.fileItem.remarks === 'stand_alone_folder') {
        this.setState({
          infoModal: {
            open: true,
            item: node.node.fileItem
          }
        })
      } else {
        this.props.location.search === "?archived-assets" ?
        this.props.history.push({
          pathname: `/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/data-room/${this.props.match.params.type}/${info[info.length - 1]}`,
          search: `${this.props.location.search}`
        })
        :
         this.props.history.push(`/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/data-room/${this.props.match.params.type}/${info[info.length - 1]}`)
      }
    }
  }
  

  onLoadData = (treeNode) => {
    return new Promise((resolve) => {
      var dataRoomTree = [...this.state.dataRoomTree];
      globalGetService('records/v2/' + this.props.match.params.assetType + '/' + this.props.match.params.assetSlug + '/data-room/' + treeNode.props.uuid + '/', { tree_structure: true, sort: 'name', sort_by: 'asc'})
        .then(response => {
          this.getNewTreeData(dataRoomTree, treeNode.props.eventKey, response.data.data.list);
          this.setState({ dataRoomTree }, () => resolve());
        })
    })
  }
  uploadFileChecker = (files) => {
    const { fileUploadingFlag, storageUsage } = this.props;
    if (getLocalStorageInfo().user.permission.records.is_saas) {
      if (storageUsage.used >= storageUsage.available) {
        this.setState({ licenseModal: true, uploadDataModal: false });
        return
      }
    }
    if (!fileUploadingFlag) {
      this.setState({ pageLoader: true });
      this.props.clearFilesStack();
      this.uploadFileCheckerApi(this.props, files)
        .then(response => {
          this.setState({ pageLoader: false });
          if (checkApiStatus(response)) {
            if (response.data.data.improper_files.length || response.data.data.overwrite_files.length) {
              this.setState({ uploadDataModal: false, uploadFileIssue: { modal: true, files: files, ...response.data.data } })
            } else {
              this.props.updateTheFileStacks(files)
                .then(response => {
                  this.uploadFileDataApi(this.props);
                })
              this.setState({ uploadDataModal: false, selectedItems: { ids: [] } });
            }
          } else {

          }
        })
    } else {
      this.props.enqueueSnackbar('Data uploading is aleady in process, wait till finished', { variant: 'warning', autoHideDuration: 10000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  }
  uploadWithOverWrite = () => {
    const { uploadFileIssue } = this.state;
    this.setState({ uploadFileIssue: { modal: false, files: null, file_naming_conventions: [], improper_files: [], overwrite_files: [] } })
    this.props.updateTheFileStacks(uploadFileIssue.files)
      .then(response => {
        this.uploadFileDataApi(this.props);
      })
  }
  contineWithSkip = () => {
    const { uploadFileIssue } = this.state;
    let issueFile = [...uploadFileIssue.improper_files, ...uploadFileIssue.overwrite_files]
    let files = Array.from(uploadFileIssue.files).filter(file => !issueFile.find(item => (item.name === file.name && item.path === file.path)))
    this.setState({
      uploadFileIssue: {
        ...uploadFileIssue,
        modal: false,
      }
    })
    this.props.updateTheFileStacks(files)
      .then(response => {
        this.uploadFileDataApi(this.props);
      })
  }
  moveNextPage = () => {
    const { dataRoom, sort_by } = this.state;
    let nextPage = parseInt(dataRoom.pagination.page) + 1;
    this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: 'asc', per_page: dataRoom.pagination.per_page, page: nextPage }, 'pageLoader');
  }
  onBulkDMMove = () => {
    const { selectedItems , selectedAsset} = this.state;
    let folderItems = selectedItems.ids.filter(item => item.type === 'folder');
    if (folderItems.length) {
      this.getMoveFoldersApi(this.props, folderItems[0].uuid, 'folder', this.props.match.params.uuid, '', 'multi')
    } else {
      this.getMoveFoldersApi(this.props, selectedItems.ids[0].uuid, 'file', this.props.match.params.uuid, '', 'multi')
    }
  }
  onSelection = (flag, item) => {
    this.setState(prevState => ({
      ...prevState,
      selectedItems: {
        ...prevState.selectedItems,
        ids: flag ? [...prevState.selectedItems.ids, { uuid: item.uuid, type: item.type }] : prevState.selectedItems.ids.filter(listItem => !(listItem.uuid === item.uuid && listItem.type === item.type))
      }
    }))
  }
  onMoveSelection = (item) => {
    this.setState(prevState => ({
      ...prevState,
      selectedItems: {
        ...prevState.selectedItems,
        ids: [item]
      }
    }))
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter, tabIndex, dataRoom } = this.state;
    this.setState({
      pageLoader: 'true',
      sort: sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc' : 'asc' : 'asc')
    });
    if (sortField === sort) {
      if (sort_by === 'asc') {
        this.getDataRoomListApi(this.props, this.props.match.params.uuid, { ...filter, sort: sortField, sort_by: 'desc', per_page: dataRoom.pagination.per_page, asset_type: tabIndex }, 'pageLoader', true);
      } else {
        this.getDataRoomListApi(this.props, this.props.match.params.uuid, { ...filter, sort: sortField, sort_by: 'asc', per_page: dataRoom.pagination.per_page, asset_type: tabIndex }, 'pageLoader', true);
      }
    } else {
      this.getDataRoomListApi(this.props, this.props.match.params.uuid, { ...filter, sort: sortField, sort_by: 'asc', per_page: dataRoom.pagination.per_page, asset_type: tabIndex }, 'pageLoader', true);
    }
  }
  onCloseRefLinkModal = (count,uuid) => {
    this.setState(prevState => ({
      ...prevState,
      dmRefLink:{modal:false, uuid:'', type:''},
      dataRoom: {
        ...prevState.dataRoom,
        list:prevState.dataRoom.list.map((item) => item.uuid !== uuid ? item: {...item,external_links_count:count})
      }
    }))
  }
  onCloseTagsModal = (tags,id) => {
    debugger
    this.setState(prevState => ({
      ...prevState,
      dmTags:{modal:false, data:null},
      dataRoom: {
        ...prevState.dataRoom,
        list:prevState.dataRoom.list.map((item) => item.id !== id ? item: {...item,tags:tags})
      }
    }))
  }
  onSendViaEmail =(selectedItems)=>{
    this.setState({sendViaMail:{flag:true, selectedItems:selectedItems?.ids, params:this.props?.match?.params}})
  }
  applyTemplate =(data)=>{
    this.setState({applyTemplateModal:{flag:true, data:this.state.dataRoom?.navigation}})
  }
  render() {
    const {fileInfo, skeletonLoader, pageLoader, selectedItems, start_date, end_date, customDateFlag, analyticStats, uploadFileIssue, dataProperties, sort_by, sort, dataRename, moveData, shareContent, dataRoom, resourcesKits, viewMode, newFolderModal, uploadDataModal, isRootNavigation, showUpload, licenceModalFlag, licenceModalMessage, dmRefLink, dmTags, sendViaMail , applyTemplateModal} = this.state;
    const { allAssets, pltConstants, fileStacks, afterUploadStacks, fileStacksFlag, fileUploadingFlag } = this.props;
    const fileIcons = pltConstants.filter(icon => icon.type === 'file_extension_drive')
    console.log('dataRoom',dataRoom);
    const loop = (data) => {
      if (data) {
        return data.map((item) => {
          if (item.children) {
            return <TreeNode className='node-p' style={{ verticalAlign: 'middle' }} icon={(props) => props.expanded ? <img src={openFolderIcon} style={{ width: '15px' }} /> : <img src={folderIcon} style={{ width: '15px' }} />} title={
              <div style={ item.type == "file" ? {}:{height:"35px"}}>
               <div>{ item.name}</div>
               <div style={{position:"relative", top:"-7px", fontSize:'11px'}}>( <span>File Count -</span> { item.file_count || '0'} )</div>
             </div>
            } type={item.type} uuid={item.uuid} fileItem={item} key={item.uuid}>{loop(item.children)}</TreeNode>;
          }
          return (
            <TreeNode className='node-p'style={{ verticalAlign: 'middle' }}  icon={item.type == "file" ? <img src={imgPath + 'file-icons/' + item.extension.toLowerCase() + '_icon.svg'} width="14" /> : <img src={folderIcon} style={{ width: '15px' }} />} title={
              <div style={ item.type == "file" ? {}:{height:"35px"}}>
              <div>{ item.name}</div>
              {item.type == "file" ? null :   <div style={{position:"relative", top:"-7px", fontSize:'11px'}}>( <span>File Count -</span> { item.file_count || '0'} )</div>}
            </div>
            } fileItem={item} type={item.type} uuid={item.uuid} key={item.uuid} isLeaf={item.type == "folder" ? false : true} disabled={item.leaf && item.type != 'folder' ? 'disabled' : ''} />
          );
        });
      }
    };
    var treeNodes = loop(this.state.dataRoomTree);
    let asset = this.state.asset
    let uuid = '';
    let activeCategory = {}
    if (asset && asset.record_folders && dataRoom.navigation && dataRoom.navigation.length === 1) {
      activeCategory = dataRoom.navigation[0]
      switch (this.props.match.params.type) {
        case 'technical':
          uuid = asset.record_folders.technical_records && asset.record_folders.technical_records.uuid ? asset.record_folders.technical_records.uuid : '';
          break;
        case 'inspection':
          uuid = asset.record_folders.technical_records && asset.record_folders.inspection_records.uuid ? asset.record_folders.inspection_records.uuid : '';
          break;
        case 'contract':
          uuid = asset.record_folders.technical_records && asset.record_folders.contract_records?.uuid ? asset.record_folders.contract_records?.uuid : '';
          break;
        case 'maintanence':
          uuid = asset.record_folders.technical_records && asset.record_folders.maintanence_records.uuid ? asset.record_folders.maintanence_records.uuid : '';
          break;
        case 'discrepancy':
          uuid = asset.record_folders.technical_records && asset.record_folders.discrepancy_records.uuid ? asset.record_folders.discrepancy_records.uuid : '';
          break;
        default:
      }
    } else if (dataRoom.navigation && dataRoom.navigation.length > 1) {
      uuid = dataRoom.navigation[dataRoom.navigation.length - 1].uuid
    }
    return (
      <section className="data-room-section">
        {allAssets.length ?
          <DataRoomHeader
            allAssets={allAssets}
            resourcesKits={resourcesKits.filter(kit => kit.name === 'Marketing Kit' || kit.name === 'Engineering Kit')}
            downloadResourceKit={(query) => this.downloadResourceKitApi(this.props, query)}
          /> : null
        }

        <DataRoomLayoutSetting
          fileInfo={fileInfo}
          allAssets={allAssets}
          dataRoom={dataRoom}
          viewMode={viewMode}
          dataRoom={dataRoom}
          navigation={dataRoom.navigation}
          onChangeMode={(mode) => this.setState({ viewMode: mode, sort: 'name' }, () => {
            this.getDataRoomListApi(this.props, this.props.match.params.uuid, { ...this.state.filter, sort: 'name', sort_by: 'asc', per_page: dataRoom && dataRoom.pagination && dataRoom.pagination.per_page? dataRoom.pagination.per_page : null , asset_type: this.state.tabIndex }, 'pageLoader')
          // onChangeMode={(mode) => this.setState({ viewMode: mode }, () => {
            if (mode === 3) {
              this.getDataRoomAnalyticApi(this.props, { folder_uuid: this.props.match.params.uuid })
            }
          })}
          onChangeNav={(item) => {this.props.location.search === "?archived-assets" ? this.props.history.push({
            pathname: `/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/data-room/${this.props.match.params.type}/${item.uuid}`,
            search: `${this.props.location.search}`
          }): this.props.history.push(`/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}/data-room/${this.props.match.params.type}/${item.uuid}`); }}
          sort_by={sort_by}
          onSortDataRoom={(sort_by) => { this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: sort_by, per_page: dataRoom.pagination.per_page, page: 1 }, 'pageLoader'); this.setState({ selectedItems: { ids: [] } }) }}
        />
        {
          dataRoom && dataRoom.list ?
            <DataRoomSearch 
            viewMode={viewMode}
            dataRoom={dataRoom} 
            toggleModalFn={(flag) => this.setState({ newFolderModal: flag })}
            shareRecord={() => { this.setState({ isRootNavigation: true, shareContent: { modal: true, data: dataRoom.navigation.length === 1 ? activeCategory : { uuid: uuid, name: dataRoom.navigation && dataRoom.navigation.length === 1 ? this.props.match.params.type : dataRoom.navigation[dataRoom.navigation.length - 1].name, type: "folder" } } }); this.getFodlerCollabUserApi(this.props, uuid) }}
            toggleUploadModalFn={(flag) => this.setState({ uploadDataModal: flag })}
            folderName={dataRoom.navigation && dataRoom.navigation.length ? dataRoom.navigation[dataRoom.navigation.length - 1].name : ''} 
            showUpload={this.state.showUpload} clearSearch={(search) => this.setState({ sort_by: 'asc', showUpload: true }, () => this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: sort_by, per_page: dataRoom.pagination.per_page, page: 1 }, 'pageLoader'))} 
            searchDataRoom={(search) => this.setState({ sort_by: 'asc', showUpload: false }, () => this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: sort_by, search: search, per_page: dataRoom.pagination.per_page, page: 1 }, 'pageLoader'))} />
            : null
        }
        {viewMode !== 3 ?
          <DataRoomNav
          // applyTemplate={this.applyTemplate}
          onSendViaEmail ={this.onSendViaEmail}
            toggleModalFn={(flag) => this.setState({ newFolderModal: flag })}
            toggleUploadModalFn={(flag) => this.setState({ uploadDataModal: flag })}
            dataRoom={dataRoom}
            setselectedAsset={this.setselectedAsset}
            analyticStats={analyticStats}
            shareRecord={() => { this.setState({ isRootNavigation: true, shareContent: { modal: true, data: dataRoom.navigation.length === 1 ? activeCategory : { uuid: uuid, name: dataRoom.navigation && dataRoom.navigation.length === 1 ? this.props.match.params.type : dataRoom.navigation[dataRoom.navigation.length - 1].name, type: "folder" } } }); this.getFodlerCollabUserApi(this.props, uuid) }}
            onDownloadData={(type) => this.downloadDataRoomApi(this.props, type === 'single' ? [{ uuid: this.props.match.params.uuid, type: 'folder' }] : selectedItems.ids)}
            changeDMFilesStatus={(data) => this.changeDMFilesStatusApi(this.props, data, true)}
            selectedItems={selectedItems}
            selectedAsset={this.state.selectedAsset}
            onBulkDMMove={this.onBulkDMMove}
            onDeleteDMData={() => this.onDeleteDMDataApi(this.props, selectedItems.ids)}
          /> : null
        }
        <div style={{ display: viewMode !== 3 ? 'flex' : 'block' }}>
          <Grid container spacing={1}>
            {treeNodes && treeNodes.length ?
              <Grid item xs={12} md={3}>
                {viewMode !== 3 ?
                  <Paper className="dm-folder-tree" style={{position:'relative',overflowX:'hidden' }}>
                    <Tree
                      onSelect={this.onSelect}
                      loadData={this.onLoadData}
                      showLine={true}
                      multiple={true}
                      style={{ height: window.innerHeight - 330 + 'px' }}
                      selectedKeys={dataRoom?.navigation?.map(nav => nav.uuid)}
                    >
                      {treeNodes}
                    </Tree>
                  </Paper> : null
                }
              </Grid> : null
            }

            <Grid item xs={12} md={treeNodes && treeNodes.length ? 9 : 12} style={{paddingBottom:'0px'}}>
              {viewMode === 1 ?
                <div className="dm-card-layout" >
                  {
                    !skeletonLoader && dataRoom.list && dataRoom.list.length && (checkPermission('records','data_room','DN') || checkPermission('records','data_room','MOV') || checkPermission('records','data_room','D')) ?
                      <FormGroup style={{ paddingLeft: '10px', background: 'rgb(235,243,243)', marginBottom: '10px', marginRight: '4%' }}>
                        <FormControlLabel control={
                          <Checkbox
                            size="small"
                            checked={dataRoom.list && dataRoom.list.length && this.state.selectedItems.ids.length && this.state.selectedItems.ids.length === dataRoom.list.length}
                            onChange={(evt) => {
                              this.setState(prevState => ({
                                ...prevState,
                                selectedItems: {
                                  ...prevState.selectedItems,
                                  ids: evt.target.checked ? dataRoom.list.map(idItem => {
                                    return { uuid: idItem.uuid, type: idItem.type }
                                  }) : []
                                }
                              }))
                            }}
                            name="bulk_operation"
                            color="primary"
                          />
                        }
                          label={dataRoom.list && dataRoom.list.length && this.state.selectedItems.ids.length && this.state.selectedItems.ids.length === dataRoom.list.length ? 'Unselect All' : 'Select All'}
                        />
                      </FormGroup>
                      : null
                  }
                  <div>
                    <ul className="list-inline dm-card-layout-row" id="scrollableDiv">
                      {skeletonLoader ? <SCardLoader count={20} /> : null}
                      {dataRoom && dataRoom.list ? dataRoom.list.map((item) =>
                        <li className="list-inline-item dm-card-layout-blk">
                          <FolderCard
                            openDmTags={() => this.setState({dmTags:{modal:true, data:item}})}
                            onOpenRefLnk={() => this.setState({dmRefLink:{modal:true, uuid:item.uuid, type:item.type}})}
                            item={item}
                            conntextMenu={this.fetchContextMenu(item)}
                            fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                            onChangeNav={() => this.onChangeNav(item)}
                            onShareContent={() => this.setState({ shareContent: { modal: true, data: item }, isRootNavigation: false }, () => this.getFodlerCollabUserApi(this.props, item.uuid))}
                            onSelection={(flag) => this.onSelection(flag, item)}
                            selectedItems={selectedItems}
                          />
                        </li>
                      ) 
                      : null}
                    </ul>
                    </div>
                  {!skeletonLoader && !pageLoader && dataRoom.list && !dataRoom.list.length ?
                    <EmptyFolder
                    applyTemplate={this.applyTemplate}
                      toggleUploadModalFn={() => this.setState({ uploadDataModal: true })}
                      showUpload={showUpload}
                    /> : null
                  }
                </div> : null
              }
              {viewMode === 2 ?
                skeletonLoader ? <STableLoader count={4} /> :
                  <div className="dm-list-layout action-docks">
                    {/* <InfiniteScroll
                      dataLength={dataRoom.list ? dataRoom.list.length : 0}
                      next={() => this.moveNextPage()}
                      hasMore={dataRoom.list && dataRoom.pagination ? dataRoom.list.length < dataRoom.pagination.total : false}
                      loader={<h4 style={{ clear: 'both', textAlign: 'center', padding: '10px' }}>Loading...</h4>}
                      endMessage={dataRoom.list && dataRoom.list.length !== 0 ? <p style={{ clear: 'both', textAlign: 'center', padding: '10px' }}>Yay! You have seen it all</p> : null}
                    > */}
                      <TableListComp
                        heads={dmRoomListHd}
                        bulkOperation={dataRoom.list && dataRoom.list.length && (checkPermission('records','data_room','DN') || checkPermission('records','data_room','MOV') || checkPermission('records','data_room','D'))}
                        bulkFlag={dataRoom.list && dataRoom.list.length && this.state.selectedItems.ids.length && this.state.selectedItems.ids.length === dataRoom.list.length}
                        toggleBulkOps={(flag) => {
                          this.setState(prevState => ({
                            ...prevState,
                            selectedItems: {
                              ...prevState.selectedItems,
                              ids: flag ? dataRoom.list.map(idItem => {
                                return { uuid: idItem.uuid, type: idItem.type }
                              }) : []
                            }
                          }))
                        }}
                        sort_by={sort_by}
                        sort={sort}
                        createSortHandler={this.createSortHandler}
                        data={dataRoom.list.map((item, index) =>
                          <ListLayout
                            openDmTags={() => this.setState({dmTags:{modal:true, data:item}})}
                            onOpenRefLnk={() => this.setState({dmRefLink:{modal:true, uuid:item.uuid, type:item.type}})}
                            item={item}
                            index={index}
                            fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                            onChangeNav={() => this.onChangeNav(item)}
                            conntextMenu={this.fetchContextMenu(item)}
                            onShareContent={() => this.setState({ shareContent: { modal: true, data: item } },() => this.getFodlerCollabUserApi(this.props, item.uuid))}
                            onSelection={(flag) => this.onSelection(flag, item)}
                            selectedItems={selectedItems}
                          />
                        )}
                        noRecord={dataRoom.list.length ? null :
                          <EmptyFolder
                          applyTemplate={this.applyTemplate}
                            title="No Records Found"
                            toggleUploadModalFn={() => this.setState({ uploadDataModal: true })}
                            showUpload={showUpload}
                          />
                        }
                      />
                    {/* </InfiniteScroll> */}
                  </div> : null
              }
              {
                dataRoom.list.length ? dataRoom?.pagination?.total >40  ?
                null:
                <p style={{ clear: 'both', textAlign: 'center', padding: '10px' }}>Yay! You have seen it all</p> :null
              }
            </Grid>
            {
              dataRoom.list.length && dataRoom?.pagination?.total > 40 ? <>
                <Grid item md={treeNodes && treeNodes.length ? 3 : 0} xs={0} >
                </Grid>
                <Grid item xs={12} md={treeNodes && treeNodes.length ? 9 : 12} style={{ paddingTop: '0px' }}>
                  <PaginationComp
                    pagination={dataRoom.pagination}
                    onChangePage={(event, newPage) => this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: 'asc', per_page: dataRoom.pagination.per_page, page: newPage + 1 })}
                    onChangeRowsPerPage={(event) => this.getDataRoomListApi(this.props, this.props.match.params.uuid, { ...this.state.filter, sort: 'name', sort_by: 'asc', per_page: event.target.value, asset_type: this.state.tabIndex, page: 1 }, '', true)}

                  />
                </Grid>
              </>
                : null
            }
          </Grid>
          {viewMode === 3 ?
            <div className="data-room-graphic">
              {Object.keys(analyticStats).length ?
                <Fragment>
                  <div>
                    <FileStatusCount analyticStats={analyticStats} />
                    <OcrFileCount analyticStats={analyticStats} />
                    <ChartDateFilter
                      start_date={start_date}
                      end_date={end_date}
                      onChangeDays={(days) => { this.getDataRoomAnalyticApi(this.props, { start_date: moment().subtract(days, 'days').format(backendDateFormat), end_date: moment().format(backendDateFormat), folder_uuid: this.props.match.params.uuid }); this.setState({ start_date: moment().subtract(days, 'days').format(backendDateFormat), end_date: moment().format(backendDateFormat), customDateFlag: false }) }}
                      customDateFlag={customDateFlag}
                      onChangeCustomDate={(startDate, endDate) => { this.getDataRoomAnalyticApi(this.props, { start_date: startDate, end_date: endDate, folder_uuid: this.props.match.params.uuid }); this.setState({ start_date: startDate, end_date: endDate, customDateFlag: true }) }}
                    />
                    <DataRoomChart
                      analyticStats={analyticStats}
                    />
                  </div>
                </Fragment> : null
              }
            </div> : null
          }
        </div>
        <NewFolderModal
          newFolderModal={newFolderModal}
          toggleModalFn={(flag) => this.setState({ newFolderModal: flag })}
          onCreateFolder={(data) => this.createNewFolderApi(this.props, data)}
        />
        {uploadDataModal ?
          <DataUploadModal
            dataRoom={dataRoom}
            uploadDataModal={uploadDataModal}
            toggleUploadModalFn={(flag) => this.setState({ uploadDataModal: flag })}
            uploadDataRoomFile={(files) => this.uploadFileChecker(files)}
          /> : null
        }

        {dataRename.modal ?
          <RenameFileFolderModal
            dataRename={dataRename}
            toggleModalFn={() => this.setState({ dataRename: { modal: false, data: null } })}
            onChangeName={(value) =>
              this.setState(prevState => ({
                ...prevState,
                dataRename: {
                  ...prevState.dataRename,
                  data: {
                    ...prevState.dataRename.data,
                    name: value
                  }
                }
              }))
            }
            saveFileFolderName={(name) => this.onRenameDMDataApi(this.props, dataRename, name)}
          /> : null
        }
        {shareContent.modal ?
          <ShareContentModal
          getCollabResponseBack={()=> {this.getFodlerCollabUserApi(this.props, shareContent?.data.uuid); this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: 'asc', per_page: 40, page: 1 }, 'skeletonLoader')} }
            shareContent={shareContent}
            isRootNavigation={isRootNavigation}
            navigation={dataRoom.navigation}
            toggleModalFn={() => this.setState({ shareContent: { modal: false, data: null } })}
            sharetheDmContent={(flag, hour) => this.shareDataRoomDataApi(this.props, { type: shareContent.data.type, uuid: shareContent.data.uuid, share: flag, hour: hour })}
          /> : null
        }
        {moveData.modal ?
          <MoveDataModal
            allAssets={allAssets}
            getMoveBackFolders= {(parentuuid)=>this.getMoveBackFoldersApi(this.props, moveData.sourceId, moveData.sourceType, parentuuid, moveData.name, )}
            getuuidParent={this.getuuidParent}
            moveData={moveData}
            selectedAsset={this.state.selectedAsset}
            changeAssetSwitcher={this.changeAssetSwitcher()}
            toggleModalFn={() => this.setState({ moveData: { modal: false, list: [], navigation: [], sourceType: '', sourceId: '' } })}
            getMoveFolders={(parentuuid) => this.getMoveFoldersApi(this.props, moveData.sourceId, moveData.sourceType, parentuuid, moveData.name, )}
            onMoveDmData={() => this.onMoveDmDataApi(this.props, moveData, dataRoom)}
            getMoveFirstFolders={this.getMoveFirstFolders}
            selectedItems={selectedItems}
            setMoveFolder={(uuid, flag) => this.setState(prevState => ({
              ...prevState,
              moveData: {
                ...prevState.moveData,
                moveFolderuuid: prevState.moveData.moveFolderuuid === uuid ? '' : uuid
              }
            }))}
          /> : null
        }
        <Properties
          width={'380px'}
          dataProperties={dataProperties}
          onCloseDrawer={() => this.setState({ dataProperties: { open: false, data: null } })}
          fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
        />
        {uploadFileIssue.modal ?
          <UploadFilesIssueModal
            uploadFileIssue={uploadFileIssue}
            contineOnOverwrite={() => this.uploadWithOverWrite()}
            contineWithSkip={() => this.contineWithSkip()}
            toggleModalFn={() => this.setState({ uploadFileIssue: { modal: false, improper_files: [], overwrite_files: [], file_naming_conventions: [] } })}
          /> : null
        }
        {pageLoader  ? <PageLoader /> : null}
        {skeletonLoader ? <STableLoader count={6} />  : null}

        <Dialog
          onClose={() => this.setState({ infoModal: { open: false, item: {} } })}
          aria-labelledby="customized-dialog-title"
          open={this.state.infoModal.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={() => this.setState({ infoModal: { open: false, item: {} } })}>
            Redirect to {this.state.infoModal.item.name}
            <CloseIcon style={{ float: 'right', position: 'relative', top: '5px', cursor: 'pointer' }} onClick={() => this.setState({ infoModal: { open: false, item: {} } })} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {
                this.state.infoModal.item.stand_alone_folder_asset != null && this.state.infoModal.item.stand_alone_folder_slug != null ?
                  `Click PROCEED to access ${this.state.infoModal.item.name} records data room.`
                  : ` To access ${this.state.infoModal.item.name}, please create stand alone asset on SPARTA`
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.state.infoModal.item.stand_alone_folder_asset != null && this.state.infoModal.item.stand_alone_folder_slug != null ?
              <React.Fragment>
                <Button variant="contained" color="secondary" onClick={() => this.redirectToAsset()}>Continue</Button>
                <Button variant="contained" onClick={() => this.setState({ infoModal: { open: false, item: {} } })}>Cancel</Button>
              </React.Fragment>
              : <Button variant="contained" onClick={() => this.setState({ infoModal: { open: false, item: {} } })}>Ok</Button>
            }
          </DialogActions>
        </Dialog>
        {fileStacks.length && !licenceModalFlag ?
          <div className="file-upload-stats" style={{ height: fileStacksFlag ? window.innerHeight : '42px' }}>
            <h4 className="flex-centered">
              {fileUploadingFlag ? <CircularProgress size={20} style={{ marginRight: '5px' }} /> : null}
              <span>{` ${afterUploadStacks.length}/${fileStacks.length} Upload File Progress`}</span>
              <ul className="list-inline">
                <li className="list-inline-item">
                  {fileStacksFlag ? <span onClick={() => this.props.toggleFileStatsBar(false)}><KeyboardArrowDownIcon /></span> : <span onClick={() => this.props.toggleFileStatsBar(true)}><KeyboardArrowUpIcon /></span>}
                </li>
                {fileStacks.length === afterUploadStacks.length ?
                  <li className="list-inline-item">
                    <CloseIcon onClick={this.props.clearFilesStack} fontSize='small' color="primary" />
                  </li> : null
                }
              </ul>
            </h4>
            {fileStacksFlag ?
              <div className="file-list" style={{ height: window.innerHeight }}>
                <ul className="list-unstyled">
                  {Array.from(fileStacks).map((file, index) =>
                    <li className="file-item">
                      <h5>{file.name}</h5>
                      {file.name !== file.path ? <p>{file.path.replace(file.name, '')}</p> : null}
                      <span className="file-status">
                        {afterUploadStacks.find(fileItem => file.name === fileItem.name && file.path === fileItem.path) ? <CheckCircleIcon style={{ color: '#006400' }} /> : <CircularProgress size={16} />}
                      </span>
                    </li>
                  )}
                </ul>
              </div> : null
            }
          </div> : null
        }
        <LicenceModal
          flag={licenceModalFlag}
          message={licenceModalMessage}
          closeModal={() => {
            this.setState({ licenceModalFlag: false, licenceModalMessage: '' });
            this.props.clearFilesStack(); this.props.toggleDataUploadProgress(false);
            this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: 'asc', page: 1, per_page: 40 }, 'pageLoader');
            this.props.getRecordsRepos(this.props);
            this.setState({ uploadFileIssue: { modal: false } })
          }} />
        { dmRefLink.modal ?
          <DataRoomRefLink 
            refLinkData={dmRefLink}
            toggleModalFn={(count) => this.onCloseRefLinkModal(count, dmRefLink.uuid)}
          />:null
        }
        {
          this.state?.sendViaMail?.flag ?
          <SendViaEmail 
          uuid= {this.props.match.params.uuid}
          sendViaMail={this.state?.sendViaMail}
          toggleModal={()=>this.setState({sendViaMail:false})}
          />
          :
          null
        }
        { dmTags.modal ?
          <DataRoomTags 
            dmTags={dmTags}
            toggleModalFn={(count) => this.setState({dmTags:{modal:false, data:null}})}
            getResponseBack={(tags) => this.onCloseTagsModal(tags, dmTags.data.id)}
          />:null
        }
        {
          applyTemplateModal?.flag ?
          <ApplyTemplate 
          assetInfo={this.state?.asset}
          toggleModal={()=>this.setState({applyTemplateModal:false})}
          applyTemplateModal={applyTemplateModal}
          getResponseBack ={()=> window.location.reload()}
          />
          : null
        }
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  storageUsage: state.shareReducer.storageUsage,
  allAssets: state.shareReducer.allAssets,
  fileStacks: state.dataRoomReducer.fileStacks,
  fileStacksFlag: state.dataRoomReducer.fileStacksFlag,
  afterUploadStacks: state.dataRoomReducer.afterUploadStacks,
  fileUploadingFlag: state.dataRoomReducer.fileUploadingFlag,
  recordsRepos: state.shareReducer.recordsRepos,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRecordsRepos: (props) => dispatch(getRecordsReposAc(props)),
    getStorageSassUsage: () => dispatch(getStorageSassUsageAc()),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ['file_extension_drive'] })),
    getAllAssetListFn: () => dispatch(getAllAssetListAc()),
    updateTheFileStacks: (files) => dispatch(updateTheFileStacksAc(files)),
    updateAfterFileStacks: (file) => dispatch(updateAfterFileStacksAc(file)),
    clearFilesStack: () => dispatch({ type: actions.CLEAN_FILES_STACK, payload: null }),
    toggleFileStatsBar: (flag) => dispatch({ type: actions.TOGGLE_UPLOAD_STATS_BAR, payload: flag }),
    toggleDataUploadProgress: (flag) => dispatch({ type: actions.TOGGLE_FILE_UPLOAD_PRO, payload: flag }),
    unmountAssetList: (data, flag) => dispatch({
      type: UNMOUNT_ASSET_LIST,
      payload: []
    }),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(DataRoom))

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Button, Grid, Paper, TextField, ClickAwayListener } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { backendDateFormat, fieldDateFormat } from '../../constants';
import moment from 'moment';
import { globalGetService } from '../../utils/globalApiServices';
import { getAllAssetListAc, getPlatformConstantsAc } from '../../shared_elements/actionCreators';
import { checkApiStatus } from '../../utils';
const queryString = require('query-string');
const AdvanceFilter = ({match, location}) => {
    const [isAdvanceFilter, setAdvanceFilter] = useState(false);
    const [searchQuery, setSearchQuery] = useState({});
    const [tags, setTags] = useState([]);
    const allAssets = useSelector(state => state?.shareReducer?.allAssets||[])
    const pltConstants = useSelector(state => state?.shareReducer?.pltConstants||[])
    const dispatch = useDispatch()
    useEffect(() => {
        if(queryString.parse(location.search)){}
        getTags();
        if(!allAssets?.length){
            dispatch(getAllAssetListAc())
        }
        dispatch(getPlatformConstantsAc({constant_types: ['file_extension_drive']}))
    },[]);
    const getTags = () => {
        globalGetService(`records/tags/`)
        .then(response => {
            if(checkApiStatus(response)){
                setTags(response.data.data)
            }
        })
    }

    const onSearchData = () => {
        // if(search?.trim()?.length){
        //     this.props.globalRecordsSearchFn({q:search, page:1, per_page:20});
        //     this.props.globalRecordsSearchFilter({q:search})
        // }else{
        //     this.props.enqueueSnackbar("Please enter the keyword for search", {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        // }
    }
    return(
        <>
        <ClickAwayListener onClickAway={() => setAdvanceFilter(false)}>
        <form onSubmit={(e) => {e.preventDefault(); onSearchData() }} style={{position:'relative'}}>
            <input onFocus={() => setAdvanceFilter(true)} className='free-text-search' type="text" value={searchQuery?.q||''} onChange={(e) => setSearchQuery({...searchQuery,'q': e.target.value})} placeholder="Search for Files, Folders and Keyword" />
            {isAdvanceFilter ?
                    <Paper className='records-dataroom-modal' style={{position:'absolute', top:'40px', padding:'10px', width:'640px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={4}>
                                {(() => {
                                    let optionValue = pltConstants.filter(filterItem => filterItem.type === 'file_extension_drive').length && searchQuery?.file_type ? pltConstants.filter(filterItem => filterItem.type === '').find(optionItem => optionItem.value === searchQuery.file_type) : null
                                    return(
                                        <Autocomplete
                                            filterSelectedOptions={true}
                                            options = {pltConstants}
                                            getOptionLabel={option => option.label}
                                            id="file_type"
                                            value={optionValue}
                                            onChange={(e, value) => setSearchQuery({...searchQuery,'file_type': value})}
                                            renderInput={params => <TextField {...params} label="Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    )
                                })()}
                            </Grid>
                            <Grid item md={4}>
                                {(() => {
                                    let options = [{ label: 'Everything', value: 'none' }, { label: 'File and Folder Names', value: 'name' }, { label: 'File Content', value: 'text' }];
                                    let optionValue = searchQuery?.search_only ? options.find(optionItem => optionItem.value === searchQuery.search_only) :null
                                    return(
                                        <Autocomplete
                                            filterSelectedOptions={true}
                                            options = {options}
                                            getOptionLabel={option => option.label}
                                            id="search_only"
                                            value={optionValue}
                                            onChange={(e, value) => setSearchQuery({...searchQuery,'search_only': value})}
                                            renderInput={params => <TextField {...params} label="Search Within" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    )
                                })()}
                            </Grid>
                            <Grid item md={4}>
                                {(() => {
                                    let optionValue = allAssets?.length && searchQuery?.assets ? null :null
                                    return(
                                        <Autocomplete
                                            filterSelectedOptions={true}
                                            options = {allAssets}
                                            getOptionLabel={option => option.unique_name}
                                            id="assets"
                                            value={optionValue}
                                            onChange={(e, value) => setSearchQuery({...searchQuery,'assets': value})}
                                            renderInput={params => <TextField {...params} label="Assets" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    )
                                })()}
                            </Grid>
                            <Grid item md={4}>
                                {(() => {
                                    return(
                                        <Autocomplete
                                            filterSelectedOptions={true}
                                            options = {[]}
                                            getOptionLabel={option => option.name}
                                            id="user_id"
                                            value={searchQuery?.user_id||null}
                                            onChange={(e, value) => setSearchQuery({...searchQuery,'user_id': value})}
                                            renderInput={params => <TextField {...params} label="Created By" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    )
                                })()}
                            </Grid>
                            
                            <Grid item xs={12} md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="from_date"
                                        label="Date From"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        value={searchQuery?.from_date||null}
                                        onChange={(data, value) => setSearchQuery({...searchQuery,'from_date': data? moment(data).format(backendDateFormat):null})}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="to_date"
                                        label="Date To"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        value={searchQuery?.to_date||null}
                                        onChange={(data, value) => setSearchQuery({...searchQuery,'to_date': data ?moment(data).format(backendDateFormat):null})}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={12}>
                                {(() => {
                                    let optionValue = tags?.length && searchQuery?.tags ?[] :[]
                                    return(
                                        <Autocomplete
                                            multiple
                                            filterSelectedOptions={true}
                                            options = {tags}
                                            getOptionLabel={option => option.name}
                                            id="tags"
                                            value={optionValue}
                                            onChange={(e, value) => setSearchQuery({...searchQuery,'tags': value})}
                                            renderInput={params => <TextField {...params} label="Tags" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    )
                                })()}
                            </Grid>
                        </Grid>
                        <div style={{marginTop:'10px'}}>
                            <ul className='list-inline'>
                                <li className='list-inline-item'>
                                    <Button onClick={() => setAdvanceFilter(false)} color='primary' size='small' variant='outlined'>Cancel</Button>
                                </li>
                                <li className='list-inline-item'>
                                    <Button color='primary' size='small' variant='contained'>Apply</Button>
                                </li>
                            </ul>
                        </div>
                    </Paper>
                :null
            }
        </form>
        </ClickAwayListener>
        </>
    )
}
export default withRouter(AdvanceFilter);
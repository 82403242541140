import React, { Fragment } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Paper } from '@material-ui/core';
const SCardLoader = ({count}) => {
  return(
    <Fragment>
      {Array(count).fill(0).map((tab, index) =>
        <li className="list-inline-item dm-card-layout-blk">
          <Paper style={{width: '150px', padding:'0 10px'}}>
            <Skeleton variant="text" animation='wave' height={134} width="100%" />
            <div>
                <Skeleton variant="text" animation='wave' width="100%" />
                <Skeleton variant="text" animation='wave' width="60%" />
            </div>
          </Paper>
        </li>
      )}
    </Fragment>
  )
}
export default SCardLoader;

import axios from 'axios';
import { withSnackbar } from 'notistack';
import config from '../config';
import { getGlobalCookie, eraseGlobalCookie, setGlobalCookie } from './';
var axiosInstance = axios.create();
axiosInstance.defaults.baseURL = config.api.networkInterface;
axiosInstance.interceptors.request.use(function (config) {
  let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
  if(lessorAccess){
    config['headers']['Authorization'] = lessorAccess.access
  }
  return config;
},function (error) {
  return Promise.reject(error);
});
axiosInstance.interceptors.response.use(
  function (response) {
    // Access token expired
  if(response.data.statusCode == 404){

  }else if (response.data.statusCode == 1001) {
    localStorage.clear()
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('domain')
    if(!window.location.href.includes('login') && !window.location.href.includes('signup')){
        setGlobalCookie('redirectURIRecords', window.location.href, 3)
    }
    window.location.assign('/login')
  }else if (response.data.statusCode == 1002) {
    localStorage.clear();
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('domain')
    eraseGlobalCookie('userName')
    if(!window.location.href.includes('login') && !window.location.href.includes('signup')){
        setGlobalCookie('redirectURIRecords', window.location.href, 3)
    }
    window.location.assign('/login')
  }else if (response.data.statusCode == 3004) {
    localStorage.clear();
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('domain')
    eraseGlobalCookie('userName')
    if(!window.location.href.includes('login') && !window.location.href.includes('signup')){
        setGlobalCookie('redirectURIRecords', window.location.href, 3)
    }
    window.location.assign('/login')
  }else if (response.data.statusCode == 3001 || response.data.statusCode == 3002) {
    // window.location.assign('/mr-calculator/logs')
  }
    // Valid Lessor Acccess

    return response;
  },
  function (error) {
    return error.response;
  }
);
export default axiosInstance;

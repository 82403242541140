import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import DMTagsPreview from './DMTagsPreview';
import { checkPermission } from '../../../utils';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/Add';
const DataRoomSearch = ({ searchDataRoom, viewMode, showUpload, clearSearch, folderName, dataRoom, shareRecord, toggleModalFn, toggleUploadModalFn }) => {
  const [search, setSearch] = useState('')
  return (
    <div className="dataroom-search">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ul className="list-inline">
            {!(viewMode === 3) ?
              <>
                <li className="list-inline-item">
                  <input value={search} onChange={(evt) => setSearch(evt.target.value)} onKeyPress={(evt) => {
                    if (evt.code === "Enter") {
                      searchDataRoom(search)
                    }
                  }} type="search" placeholder={`Search file name and folder name in ${folderName}`} />
                  <SearchIcon className="search-icon" />
                  <Tooltip title={"This search is for file name and folder name search only"} placement="top" >
                    <InfoIcon style={{ fontSize: '20px', position: 'absolute', top: '5px', right: '5px' }} />
                  </Tooltip>
                </li>
                <li className="list-inline-item">
                  <Button
                    disabled={showUpload}
                    onClick={() => {
                      setSearch('')
                      setTimeout(() => clearSearch(search), 500)
                    }} variant="contained" color="primary" size="small">Clear Search</Button>
                </li>
                <li className="list-inline-item">
                  <Button onClick={() => searchDataRoom(search)} disabled={search === '' || search === null || search.trim() === ''} variant="contained" color="primary" size="small">Search</Button>
                </li>
              </> : null
            }
            {checkPermission('records', 'tags', 'R') ?
              <li className='list-inline-item' style={!(viewMode === 3)?{ borderLeft:'1px solid #d7d7d7', marginLeft: '12px', paddingLeft: '5px' }:null}>
                <DMTagsPreview />
              </li> : null
            }
            {checkPermission('records', 'data_room', 'SHR') ?
              <li className="list-inline-item">
                <Tooltip title={`Share ${dataRoom.navigation && dataRoom.navigation.length ? dataRoom.navigation[dataRoom.navigation.length - 1].name : ''}`} arrow>
                  <Button onClick={() => shareRecord()} size="small" variant="contained">Share</Button>
                </Tooltip>
              </li> : null
            }
            {checkPermission('records', 'data_room', 'UP') || (dataRoom.folder_collaboration && dataRoom.folder_collaboration.can_edit === 1) ?
              <li className="list-inline-item">
                <Button onClick={() => toggleModalFn(true)} size="small" variant="contained" startIcon={<AddIcon />}>New Folder</Button>
              </li> : null
            }
            {checkPermission('records', 'data_room', 'UP') || (dataRoom.folder_collaboration && dataRoom.folder_collaboration.can_edit === 1) ?
              <li className="list-inline-item">
                <Button onClick={() => toggleUploadModalFn(true)} size="small" variant="contained" startIcon={<CloudUploadIcon />}>Upload Data</Button>
              </li>
              : null
            }
          </ul>
        </Grid>
      </Grid>
    </div>
  )
}
export default withRouter(DataRoomSearch);

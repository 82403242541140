import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
const BucketList = ({item , history}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell width="30">
        <Checkbox
          name="checkedB"
          color="primary"
          size="small"
        />
      </TableCell>
      <TableCell className="file-info" width="380">
        <div className="flex-centered">
          <img src="" alt="" />
          <div>
            <h5>
              APU Inst-Rem History 20190623.pdf
            </h5>
            <p>
              LG RM SN234567/S. Landing Gear OEM Delivery Documents/S1. Landing Gear LLP Details OEM/L4. APU Inst-Rem History
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell>100.98 KB</TableCell>
      <TableCell>
        <DeleteIcon color="secondary" />
      </TableCell>
    </TableRow>
  )
}
export default withRouter(BucketList);

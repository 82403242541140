import React from 'react';
import { Paper, Grid, Avatar } from '@material-ui/core';
import { getFileSize, localTimeFn } from '../../../utils';
const BatchDetail = ({batchDetail}) => {
  return(
    <Paper className="auto-organiser-batch">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={2}>
          <p>Batch No.</p>
          <h5>{batchDetail && batchDetail.name ? batchDetail.name:'--'}</h5>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <p>No of files uploaded</p>
          <h5>{batchDetail && batchDetail.file_count ? batchDetail.file_count:'--'}</h5>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <p>Size</p>
          <h5>{batchDetail && batchDetail.size ? getFileSize(batchDetail.size):'--'}</h5>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <p>Uploaded By</p>
          <div className="flex-centered">
            <Avatar alt="Vijay Kumar" src="" />
            <div style={{marginLeft:'8px'}}>
              <h5>{batchDetail && batchDetail.created_by ? batchDetail.created_by.name:'--'}</h5>
              <p>{batchDetail && batchDetail.created_at ? localTimeFn(batchDetail.created_at):'--'}</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <p>Status</p>
          <h5>
            { batchDetail && batchDetail.status ?
              batchDetail.status === 0 ? 'Processing':batchDetail.status === 1 ? 'Processed':'Published':'--'
            }
          </h5>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default BatchDetail;

export const trashFilterOps = {
    name: {
      inputType: 'text',
      placeholder: 'Enter File/Folder Name',
      title: 'File/Folder Name'
    },
    asset: {
      inputType: 'dropdown',
      placeholder: 'Please Enter Asset Serial No.',
      title: 'Asset',
      options: [],
      labelKey: 'unique_name',
      valueKey: 'id'
    },
    user_id: {
      inputType: 'dropdown',
      placeholder: 'Please Enter User Name',
      title: 'User Name',
      options: [],
      labelKey: 'name',
      valueKey: 'id'
    },
  };
import React, { Fragment, Component } from 'react';
import { TableRow, TableCell, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { TableListComp, EmptyCollection } from '../../../shared_elements';
import { assetTypeValues } from '../../../constants';
import { getOCRPriorityAssetsApi } from '../apiServices';
import { trackActivity } from '../../../utils/mixpanel';
class AssetPriorityModal extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      assets:{
        list:[],
        pagination:null
      },
      filter:{}
    }
    this.getOCRPriorityAssetsApi = getOCRPriorityAssetsApi.bind(this);
  }
  render(){
    const { modal, assets, filter } = this.state;
    return(
      <Fragment>
        <Button variant="contained" color="primary" onClick={() => {this.getOCRPriorityAssetsApi(this.props); trackActivity('Item Clicked',{page_title:'Asset List', item_type:'OCR Priority Button', event_desc:'Clicked on OCR Priority Button from Asset List'}) }}>OCR Priority</Button>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
            fullWidth= 'true'
          >
            <DialogTitle id="scroll-dialog-title">
              OCR Priority Assets
            </DialogTitle>
            <DialogContent dividers={true} className="data-upload-modal">
              <TableListComp
                heads={[{label:'Asset(s)', sortOption:false},{label:'Priority', sortOption:false}]}
                data={ assets.list.map((item,index) =>
                  <TableRow hover tabIndex={-1}>
                    <TableCell className="asset-info" width="180">
                      <h5>
                        {assetTypeValues[item.asset_type].label ? assetTypeValues[item.asset_type].label: ''} {item.asset_name}
                      </h5>
                    </TableCell>
                    <TableCell className="asset-info" width="180">
                      {item.ocr_priority === 1 ? 'High' : item.ocr_priority === 2 ? 'Medium':'Low'}
                    </TableCell>
                  </TableRow>
                )}
                pagination={assets.pagination}
                onChangePage={(event, newPage) => {this.getOCRPriorityAssetsApi(this.props, {  page: newPage + 1, per_page: assets.pagination.per_page, ...filter }, 'pageLoader') }}
                onChangeRowsPerPage={(event) => this.getOCRPriorityAssetsApi(this.props, { page: 1, per_page: event.target.value, ...filter }, 'pageLoader')}

                noRecord={ assets.list.length ? null:
                  <EmptyCollection
                    title="No Records Found"
                  />
                }
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false})}>Cancel</Button>
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default AssetPriorityModal;

import React, { useState, useEffect } from 'react';
import { Paper, Button, Dialog, Grid, TextField, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow, Tabs, Tab, FormControlLabel, Checkbox } from '@material-ui/core';
// import { checkApiStatus } from '../../utils_v2';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PageLoader } from '../../../shared_elements';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
export default function TemplateAddEdit({ addTemplateModal, toggleModalFn, newFolderData, assetData, mainassetInfoData, submitWithTemplate, templateData }) {
    const [templates, setTemplates] = useState([])
    const [pres, setPres] = useState([]);
    const [checklistData, setChecklistData] = useState({});
    const [checkedFolders, setCheckedFolders] = useState([]);
    const [checkedPayload, setcheckedPayload] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [maxObj, setMaxObj] = useState(0);
    const [includeFolder, setIncludeFolder] = useState(true);
    const [importCrud, setImportCrud] = useState({ template: null });
    const [templateCrud, setTemplateCrud] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        getTemplateList()
    }, [])
    useEffect(() => {
        if (templateCrud.length > 0) {
            setChecklistData(templateCrud)
            setMaxObj(findMaxDepth(templateCrud));
        }
    }, [templateCrud]);

    const getTemplateList = (query) => {
        globalGetService(`records/v2/lessor-folder-template/`, { dropdown: true })
            .then(response => {
                if (checkApiStatus(response)) {
                    let selectedTemplate = response.data.data?.list.find(item => item?.id ==templateData?.templateDetails.id ) 
                    setImportCrud({template:selectedTemplate})
                    onChangeTemplate(selectedTemplate)
                    setTemplates(response.data.data.list)
                }
                else{
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            });
    }

    const onChangeTemplate = (template) => {
        setLoading(true)
        setImportCrud({ template });
        globalGetService(`records/v2/lessor-folder-template/get-template-structure/${template?.id}`)
        .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setTemplateCrud(response.data.data);
                }
                else{
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            })
    }
    const onFieldChange = (folder, checked) => {
        const updatedCheckedFolders = [...checkedFolders];
        const toggleCheck = (folder, isChecked) => {
            const folderIndex = updatedCheckedFolders.findIndex(item => item.folder_name === folder.folder_name && item?.parent_folder ==folder?.parent_folder ); 
            const folderData = {
                is_checked: isChecked,
                level: folder?.level,
                parent_folder: folder?.parent_folder,
                naming_conventions: folder?.naming_conventions,
                folder_name: folder?.folder_name,
                childrens: folder?.childrens
            };

            if (folderIndex === -1) {
                updatedCheckedFolders.push(folderData);
            } else {
                updatedCheckedFolders[folderIndex] = folderData;
            }

            folder.childrens.forEach(child => toggleCheck(child, isChecked));
        };
        toggleCheck(folder, checked);
        setCheckedFolders(updatedCheckedFolders);
    };
    const onSubmitData = () => {
        let payload = {
            folderDetails:checkedFolders,
            templateDetails: importCrud.template,
            recordsNewFolderName: newFolderData,
            include_selected_folder: includeFolder,
            asset_type: mainassetInfoData?.asset_type,
            asset: { serial_number: mainassetInfoData?.msn ?mainassetInfoData?.msn: mainassetInfoData?.esn ? mainassetInfoData?.esn: mainassetInfoData?.serial_number, id: mainassetInfoData?.id }
        }
        submitWithTemplate(payload, importCrud)
    };
    const findMaxDepth = (folders) => {
        let maxDepth = 0;
        const traverse = (folders, depth) => {
            folders.forEach((folder) => {
                if (folder.childrens.length > 0) {
                    traverse(folder.childrens, depth + 1);
                } else {
                    maxDepth = Math.max(maxDepth, depth);
                }
            });
        };
        traverse(folders, 1);
        return maxDepth;
    };

    const renderRows = (folders, depth = 1) => {
        const maxDepth = findMaxDepth(templateCrud);
        return folders.flatMap((folder) => {
            const rows = [];
            const indent = Array.from({ length: depth - 1 }).map(() => (
                <TableCell key={Math.random()} />
            ));
            rows.push(
                <TableRow key={folder.folder_name}>
                    {indent}
                    <TableCell>
                        <FormControlLabel control={<Checkbox size="small" color='primary' checked={checkedFolders.find(item => (item.folder_name === folder.folder_name) && (item.parent_folder?.length ? item.parent_folder[0] === folder.parent_folder[0]:true ) )?.is_checked  || false} onChange={(e) => onFieldChange(folder, e.target.checked)} name="sub_folder" />} label={<p style={{ fontSize: '13px' }}>{folder.folder_name}</p>} />
                        {/* <FormControlLabel control={<Checkbox size="small" color='primary' checked={checkedFolders[folder.folder_name]?.is_checked && (folder?.parent_folder?.length ? checkedFolders[folder?.folder_name].parent_folder[0]== folder?.parent_folder[0]:true) || false} onChange={(e) => onFieldChange(folder, e.target.checked)} name="sub_folder" />} label={<p style={{ fontSize: '13px' }}>{folder.folder_name}</p>} /> */}
                    </TableCell>
                    {Array.from({ length: maxDepth - depth }).map((_, i) => (
                        <TableCell key={`empty-${i}`} />
                    ))}
                    <TableCell>{folder.naming_conventions}</TableCell>
                </TableRow>
            );
            if (folder.childrens.length > 0) {
                rows.push(...renderRows(folder.childrens, depth + 1));
            }
            return rows;
        });
    };
    return (
        <>
        {isLoading? <PageLoader /> : null}
        <Dialog
            open={addTemplateModal?.flag}
            onClose={() => { toggleModalFn(false) }}
            className='console-management-modal'
            maxWidth={false}
        >
            <DialogTitle id="scroll-dialog-title">
               Select Template Folders
            </DialogTitle>
            <DialogContent dividers={true} >
                <div style={{ minHeight: '600px', minWidth: '500px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                style={{ marginBottom: '20px' }}
                                options={templates}
                                getOptionLabel={option => option.name}
                                id="template"
                                value={importCrud.template}
                                onChange={(e, value) => onChangeTemplate(value)}
                                renderInput={params => <TextField required {...params} label="Select Template" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
                            {importCrud.template ?
                                <FormControlLabel control={<Checkbox size="small" color='primary' checked={includeFolder} onChange={(e) => setIncludeFolder(e.target.checked)} name="sub_folder" />} label={<p style={{ fontSize: '13px' }}>Include selected Folder</p>} />
                                : null}
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        {templateCrud?.length ?
                        <div style={{overflow:"scroll", width:`${(40)+(maxObj * 20)}%`, minWidth:"100%"}}>
                            <Table className='mui-table-format'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell >Main Folders</TableCell>
                                        {
                                            Array.from({ length: maxObj - 1 }).map((item, index) => (
                                                <TableCell >Sub Folder {index + 1} </TableCell>
                                            ))
                                        }
                                        <TableCell width='200px'>Naming Conventions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {templateCrud?.length ?
                                        renderRows(templateCrud)
                                        : null
                                    }
                                </TableBody>
                            </Table>
                            </div>
                             :
                            <Paper style={{ textAlign: 'center', padding: '20px' }}>
                                {isLoading ?
                                    <CircularProgress size={24} /> :
                                    'Choose template to you wish to select.'
                                }
                            </Paper>
                        }
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={!Object.keys(checkedFolders)?.length || isLoading} onClick={onSubmitData} color='primary' size='small' variant='contained'>Save</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { getFileSize, localTimeFn } from '../../../utils';
import { TableRow, TableCell, Tooltip, Avatar } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { trackActivity } from '../../../utils/mixpanel';
const AutoOrgBatch = ({ item, match, history }) => {
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.file_count}</TableCell>
      <TableCell>{getFileSize(item.size)}</TableCell>
      <TableCell>
        <div className="flex-centered" >
          <Avatar alt="Profile Pic" src={item.created_by.profile_pic} />
          <div style={{ marginLeft: '8px' }}>
            <h5>{item.created_by.name}</h5>
            <p>{localTimeFn(item.created_at)}</p>
          </div>
        </div>
      </TableCell>
      <TableCell>{item.status === 0 ? 'Processing' : item.status === 1 ? 'Processed' : 'Published'}</TableCell>
      <TableCell>
        {item.status === 1 ? <VisibilityIcon style={{ cursor: 'pointer' }} onClick={() => { history.push(`/${match.params.assetType}/${match.params.assetSlug}/auto-organiser/view/${item.uuid}`); trackActivity('Item Clicked', { page_title: 'Auto Organiser', event_type: 'View', event_desc: `Clicked on View Icon for ${item.name}`, uuid: item.uuid }) }} color="primary" /> : null}
      </TableCell>
    </TableRow>
  )
}
export default withRouter(AutoOrgBatch);

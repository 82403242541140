import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Chip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteCreatable, PageLoader } from '../../../shared_elements';
import SharedUserList from './SharedUserList'
import { regexConstants } from '../../../constants/regEx';
import { inviteFolderCollabApi } from '../apiServices';
import { checkApiStatus, checkPermission } from '../../../utils';
import { globalDeleteService, globalPostService } from '../../../utils/globalApiServices';
class FolderCollabrationModal extends Component{
  constructor(props){
    super(props);
    this.state = {
      formSubmitLoader:false,
      loader:false,
      modal:false,
      emails:[],
      newEmails:[],
      accessType: checkPermission('records','data_room','UP') ? {label:'As Edit, Add & Delete', value:1}:checkPermission('records','data_room','DN')?{label:'As View & Download', value:0}:{label:'As View Only', value:2},
      message:''
    }
    this.inviteFolderCollabApi = inviteFolderCollabApi.bind(this);
  }
  onEmailChange = (data) => {
    let emailError = data.some(email => !regexConstants.email.test(email.inputValue))
    if(emailError){
      this.props.enqueueSnackbar('Invalid Email',{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
    }else{
      this.setState({newEmails:data});
    }
  }
  removeUser =(folder, item)=>{
    this.setState({loader:true})
    globalPostService(`records/v2/delete_user_from_folder_share/${folder?.uuid}/`, {user_id:item?.id})
    .then(response=>{
      this.setState({loader:false})
      if(checkApiStatus(response)){
        this.props.getCollabResponseBack()
        this.props.enqueueSnackbar(response?.data.message,{variant:'success',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
      }
    })
  }
  sendInvite = () => {
    const { emails, accessType, message, newEmails } = this.state;
    if(newEmails.length){
      let payload = {email:[...emails, ...newEmails].map(item => item.inputValue), can_edit:accessType.value, reason:message}
      this.inviteFolderCollabApi(this.props, this.props.item.uuid, payload)
    }else{
      this.props.enqueueSnackbar('Please enter Emails',{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
    }
  }
  render(){
    const { formSubmitLoader, loader, modal, emails, accessType, message } = this.state;
    const { item, navigation, isRootNavigation } = this.props;
    return(
      <Fragment>
        {
          loader ? <PageLoader /> : null
        }
          <div className="data-collabration-blk">
            <h4 className="flex-centered">
              Collaborate
              "{ isRootNavigation ?
                navigation && navigation.length === 1 ?
                  item.name
                : " " + navigation[navigation.length - 1].name:item.name
              }" with People
              {/* { item.userEmails && item.userEmails.length ?<SharedUserList users={item.userEmails} />:null } */}
            </h4>
            {
              item.userEmails && item.userEmails.length ?
                <div className='shared-ppl-lst'>
                  <div style={{ fontSize: '14px', fontWeight: "600", marginBottom: '5px' }}>Shared With People</div>
                  {
                    item?.userEmails?.map(chipItem => (
                      <Chip
                      style={{margin:"4px"}}
                        variant='outlined'
                        color='primary'
                        onDelete={() => this.removeUser(item, chipItem)}
                        size='small'
                        label={chipItem?.email}
                      />
                    ))
                  }
                </div> : null
            }
            {
                !modal ? 
                <p onClick={() => this.setState({modal:true, emails:item.userEmails.map(item => {return{inputValue:item.email,value:item.email}})})}>Add email addresses</p>
           :
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AutocompleteCreatable
                    placeholder="Enter the email ids"
                    options={[]}
                    value={this.state.newEmails}
                    paramsKey='email'
                    optionKey="value"
                    required={true}
                    multiple={true}
                    label="With People"
                    onFieldChange={(paramsKey, emails) => this.onEmailChange(emails)}
                  />
                </Grid>
                <Grid item xs={12}>
                  {(() => {
                    // [{label:'As Edit, Add & Delete', value:1}, {label:'As View Only', value:2}, {label:'As View & Download', value:0}]
                    let options = [{label:'As View Only', value:2}];
                    if(checkPermission('records','data_room','UP')){
                      options = [...options,{label:'As Edit, Add & Delete', value:1}]
                    }
                    if(checkPermission('records','data_room','DN')){
                      options = [...options, {label:'As View & Download', value:0}];
                    }
                    return(
                      <div style={{width:'200px'}}>
                        <Autocomplete
                          options = {options}
                          getOptionLabel={option => option.label}
                          value={accessType}
                          disableClearable={true}
                          onChange={(e, value) => {value ? this.setState({accessType:value}):e.preventDefault();}}
                          renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{shrink: true}} />}
                        />
                      </div>
                    )
                  })()}                  
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="message"
                    label="Message"
                    fullWidth
                    margin="normal"
                    value={message}
                    multiline
                    rows="4"
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => this.setState({message:e.target.value})}
                  />
                </Grid>
                <Grid item xs={12} style={{textAlign:"right"}}>
               <Button color="primary" onClick={() => this.setState({modal:false})}>Cancel</Button>
               <Button disabled={formSubmitLoader} variant="contained"  onClick={this.sendInvite} color="primary">{ formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Send Invite' }</Button>
               </Grid>
              </Grid>
          }
          </div>
          
          
        {/* } */}
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(FolderCollabrationModal));

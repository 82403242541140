import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import componentPermissionHoc from '../../../hoc/componentPermissionHoc'
import { checkPermission } from '../../../utils';
const ContextMenuList = ({match, item, resourcesKits, changeDMFilesStatus, getDMDataProperties, onDeleteDMData, onRenameDMData, onShareContent, addFilesToMiniPackAndKit, getMoveFolders, onSetActiveUuid, onChangeNav, onDownloadData, onSelection, onMoveSelection, setselectedAsset, onOpenRefLnk, onSendViaEmail}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actionEl, setActionEl] = React.useState(null);
  const [categoryEl, setCategoryEl] = React.useState(null);
  const [miniPackEl, setMiniPackEl] = React.useState(null);
  const handleClick = (event, uuid) => {
    setAnchorEl(event.currentTarget);
    setActionEl(null);
    setCategoryEl(null);
    setMiniPackEl(null);
  };
  const handleClose = () => {
    onSetActiveUuid('');
    setAnchorEl(null);
    setActionEl(null);
    setCategoryEl(null);
    setMiniPackEl(null);
  };
  const handleActionClick = (event) => {
    setActionEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setActionEl(null);
  };
  const handleCategoryClick = (event) => {
    setCategoryEl(event.currentTarget);
  };
  const handleCategoryClose = () => {
    setCategoryEl(null);
  };
  const handleMiniPackClick = (event) => {
    setMiniPackEl(event.currentTarget);
  };
  const handleMiniPackClose = () => {
    setMiniPackEl(null);
  };
  return(
    <Fragment>
      <span style={{cursor:'pointer'}} aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClick(event, item.uuid)}>
        <MoreHorizIcon />
      </span>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { item.type === 'folder' ? <MenuItem onClick={(event) => {handleClose(event);onChangeNav()}}>Open</MenuItem>:null}
        <MenuItem onClick={(event) => {handleClose(event);getDMDataProperties({type:item.type, uuid:item.uuid})}}>Properties</MenuItem>
        { checkPermission('records','data_room','DN') || (item.folder_collaboration && (item.folder_collaboration.can_edit === 1 || item.folder_collaboration.can_edit === 0)) ?
            <MenuItem onClick={(event) => {handleClose(event); onDownloadData()}}>Download</MenuItem>
          :null
        }
        { item.remarks != 'stand_alone_folder' && (checkPermission('records','data_room','MOV') || (item.folder_collaboration && item.folder_collaboration.can_edit === 1)) ?
            <MenuItem onClick={(event) => {handleClose(event); getMoveFolders(); onMoveSelection(true);setselectedAsset()}}>Move To</MenuItem>
          :null
        }
        {
          item.remarks !== 'stand_alone_folder' && checkPermission('records','data_room','SHR') ?
            <MenuItem onClick={(event) => {handleClose(event);onShareContent()}}>Share</MenuItem>
          :null
        }
        {
          item.remarks !== 'stand_alone_folder' && (checkPermission('records','data_room','D') || (item.folder_collaboration && item.folder_collaboration.can_edit === 1)) ?
            <MenuItem onClick={(event) => {handleClose(event);onDeleteDMData()}}>Trash</MenuItem>
          :null
        }
        {
          item.remarks !== 'stand_alone_folder' && (checkPermission('records','data_room','RENAME') || (item.folder_collaboration && item.folder_collaboration.can_edit === 1)) ?
            <MenuItem onClick={(event) => {handleClose(event); onRenameDMData({type:item.type, uuid:item.uuid, name:item.name, naming_convention: item.naming_convention})}}>Rename</MenuItem>
          :null
        }
        { item.type === 'file' && match.params.type === 'technical' && (checkPermission('records','data_room','PUB_REJ') || (item.folder_collaboration && item.folder_collaboration.can_edit === 1)) ?
          <MenuItem>
            <span style={{display:'inline-block', width:'100%'}} aria-controls="file-action" aria-haspopup="true" onClick={handleActionClick}>
              Action
              <ArrowForwardIosIcon color="primary" fontSize="small" style={{float:'right'}} />
            </span>
            <Menu
              id="file-action"
              anchorEl={actionEl}
              keepMounted
              open={Boolean(actionEl)}
              onClose={handleActionClose}
            >
              { item.status !== 'Published' ?
                <MenuItem onClick={(event) => {handleActionClose(event); handleClose(event); changeDMFilesStatus({status:3, uuid:[item.uuid], type:'file'})}}>Publish</MenuItem>:
                <MenuItem onClick={(event) => {handleActionClose(event); handleClose(event); changeDMFilesStatus({status:1, uuid:[item.uuid], type:'file'})}}>UnPublish</MenuItem>
              }
              <MenuItem onClick={(event) => {handleActionClose(event); handleClose(event); changeDMFilesStatus({status:4, uuid:[item.uuid], type:'file'})}}>Reject</MenuItem>
            </Menu>
          </MenuItem>:null
        }
        { item.type === 'file' && match.params.type === 'technical' && checkPermission('records','category','C') && resourcesKits.filter(category => category.name === 'Marketing Kit' || category.name === 'Engineering Kit').length ?
          <MenuItem>
            <span style={{display:'inline-block', width:'100%'}} aria-controls="category-action" aria-haspopup="true" onClick={handleCategoryClick}>
              Category
              <ArrowForwardIosIcon color="primary" fontSize="small" style={{float:'right'}} />
            </span>
            <Menu
              id="category-action"
              anchorEl={categoryEl}
              keepMounted
              open={Boolean(categoryEl)}
              onClose={handleCategoryClose}
            >
              { resourcesKits.filter(category => category.name === 'Marketing Kit' || category.name === 'Engineering Kit').map((kit) =>
                <MenuItem onClick={(event) => {handleCategoryClose(event); handleClose(event); addFilesToMiniPackAndKit({type:'file',category:kit.name === 'Marketing Kit' ? 1:2,flag:true, to_folder:kit.uuid,uuid:[item.uuid]})}}>{kit.name}</MenuItem>
              )}
            </Menu>
          </MenuItem>:null
        }

        { match.params.type === 'technical' && item.type === 'file' && resourcesKits.filter(kit => kit.name !== 'Marketing Kit' && kit.name !== 'Engineering Kit').length && checkPermission('records','mini-pack','C') ?
          <MenuItem>
            <span style={{display:'inline-block', width:'100%'}} aria-controls="minipack-action" aria-haspopup="true" onClick={handleMiniPackClick}>
              Minipack
              <ArrowForwardIosIcon color="primary" fontSize="small" style={{float:'right'}} />
            </span>
            <Menu
              id="minipack-action"
              anchorEl={miniPackEl}
              keepMounted
              open={Boolean(miniPackEl)}
              onClose={handleMiniPackClose}
            >
              { resourcesKits.filter(kit => kit.name !== 'Marketing Kit' && kit.name !== 'Engineering Kit').map((minipack) =>
                <MenuItem onClick={(event) => {handleMiniPackClose(event); handleClose(event); addFilesToMiniPackAndKit({type:'file',category:3,flag:true, to_folder:minipack.uuid,uuid:[item.uuid]})}}>{minipack.name}</MenuItem>
              )}
            </Menu>
          </MenuItem>:null
        }
        { checkPermission('records','ref_link','R') ?
          <MenuItem onClick={(event) => {handleClose(event);onOpenRefLnk()}}>Reference Link</MenuItem>:null
        }
         { checkPermission('records','data_room', 'SNDEML') ?
        <MenuItem onClick={(event) => {handleClose(event);onSendViaEmail({ids:[item]})}}>Send via Email</MenuItem>
        : null}
      </Menu>
    </Fragment>
  )
}
export default withRouter(ContextMenuList);

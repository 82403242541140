import * as actions from './actions';
export default function( state = {
  pltConstants: [],
  fleetFiles:null,
  fleetActivityCount:null,
  activityLoader:false,
  countCardLoader:false,
  dbLoader:false,
}, action){
  switch (action.type) {
    case actions.FLEET_FILES_COUNT:
      return {
        ...state,
        fleetFiles: action.payload
      }
    case actions.FLEET_FILES_ACTIVITY_COUNT:
      return {
        ...state,
        fleetActivityCount: action.payload
      }
      case actions.DB_LOADER:
        return {
          ...state,
          dbLoader: action.payload
        }
    case actions.TOGGLE_DB_LOADER:
      return {
        ...state,
        [action.payload.type]: action.payload.flag
      }
    default:
      return state;
  }
}

import AutoOrganiser from './containers'
import AutoOrganiserView from './containers/AutoOrganiserView'
import PageLayout from '../../hoc/PageLayout';
import UserRoleAccess from '../../hoc/UserRoleAccess';
export const autoOrganiserRouters = [
  {
    path: '/:assetType/:assetSlug/auto-organiser/',
    component: PageLayout(UserRoleAccess(AutoOrganiser, ['auto_bulk_organizer', 'auto_bulk_organizer', 'R']), {apps: 'AutoOrganiser', layoutPhase: 2}),
    key: 'AutoOrganiser',
    apps:'records'
  },
  {
    path: '/:assetType/:assetSlug/auto-organiser/view/:uuid',
    component: PageLayout(UserRoleAccess(AutoOrganiserView, ['auto_bulk_organizer', 'auto_bulk_organizer', 'R']), {apps: 'AutoOrganiserView', layoutPhase: 2}),
    key: 'AutoOrganiserView',
    apps:'records'
  },
]

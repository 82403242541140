/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import ViewSDKClient from './ViewSDKClient';
import { globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus, getGlobalCookie } from '../../../utils';
// import { annotations } from './annotationList';
import { getLocalStorageInfo } from "../../../utils";

class AdobePdfViewer extends Component {
    constructor(props){
        super(props);
        this.state={
            annotations:this.props.annotations,
            isLoading:false,
        }
    }
    viewerConfig = {
        /* Enable commenting APIs */
        enableAnnotationAPIs: true,  /* Default value is false */
        includePDFAnnotations: true , /* Default value is false */
        showAnnotationTools: getGlobalCookie('lessorAccess')?true:false,  /* showAnnotationTools only for logged in User */
        enableSearchAPIs: true,
    };

    
    componentDidMount() {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            /* Invoke the file preview and get the Promise object */
            this.previewFilePromise = viewSDKClient.previewFile("pdf-div", {...this.viewerConfig, ...this.props.fileInfo});
            /* Use the annotation manager interface to invoke the commenting APIs */
            this.previewFilePromise.then(adobeViewer => {
                //To enable seach from URl
                window.adobeViewer=adobeViewer;
                this.props.executeSearch()
//                adobeViewer.getAPIs().then(apis => {
//                  apis.search('foreign function interface').then(searchObject => {
//                    window.searchObject = searchObject;
//                    console.log('searchObject',searchObject);
//                  })
//                    .catch(error => console.log('Ferror',error));
//                });
                adobeViewer.getAnnotationManager().then(annotationManager => {
                    /* API to add annotations */
                    annotationManager.addAnnotations(this.props.annotations)
                        .then(() => {
                            console.log("Annotations added through API successfully");
                        })
                        .catch(error => {
                            console.log(error);
                        });
        
                    /* API to register events listener */
                    annotationManager.registerEventListener(
                        event => {
                            console.log('event',event);
                            let annotationIds = this.state.annotations.map((annotate) => annotate.id);

                            if (event.type === 'ANNOTATION_ADDED') {
                                if(annotationIds.includes(event.data.id)){
                                    console.log('Initial Loads');
                                }else{
                                    this.props.enqueueSnackbar('Data Saving...', { variant: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                                    var data_to_save = event.data
                                    if (data_to_save.bodyValue==""){
                                        var ann_type=data_to_save.target.selector.subtype;
                                        data_to_save.bodyValue="Annotation Type: " + ann_type.toUpperCase();
                                    }
                                    this.onModifyAnnotation([...this.state.annotations, data_to_save])
                                }

                            } else if (event.type === 'ANNOTATION_UPDATED') {
                                if (event.data.creator.id==getLocalStorageInfo()?.user?.slug||''){
                                    this.props.enqueueSnackbar('Data Saving...', { variant: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                                    this.onModifyAnnotation([...(this.state.annotations.filter(a => a.id !== event.data.id)), event.data], 'edit')
                                }else{
                                    this.props.enqueueSnackbar('You cannot update this annotation.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                                    //refresh page here
                                    //document.getElementById('undo').click()
                                    this.props.toggleAnnotation()
                                }
                                // viewSDKClient.annots = [...(viewSDKClient.annots.filter(a => a.id !== event.data.id)), event.data]
                            } else if (event.type === 'ANNOTATION_DELETED') {

                                if (event.data.creator.id==getLocalStorageInfo()?.user?.slug||''){
                                   if(annotationIds.includes(event.data.id)){
                                        this.props.enqueueSnackbar('Data Saving...', { variant: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                                        this.onModifyAnnotation(this.state.annotations.filter(a => a.id !== event.data.id), 'delete')
                                    }else{
                                       this.props.enqueueSnackbar('You cannot delete original file\'s annotation', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                                       this.props.toggleAnnotation()
                                    }
                                }else{
                                    this.props.enqueueSnackbar('You cannot delete this annotation', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                                    //refresh page here
                                    //document.getElementById('undo').click()
                                    this.props.toggleAnnotation()
                                }


                                // viewSDKClient.annots = viewSDKClient.annots.filter(a => a.id !== event.data.id);
                            }
                        },
                        {
                            /* Pass the list of events in listenOn. */
                            /* If no event is passed in listenOn, then all the annotation events will be received. */
                            listenOn: [
                               "ANNOTATION_ADDED", "ANNOTATION_UPDATED" ,"ANNOTATION_DELETED"
                            ]
                        }
                    );

                });

            });
            viewSDKClient.registerEventsHandler();
        });

    }

    onModifyAnnotation = (annotations, mode) => {
        this.setState({isLoading:true});
        // this.props.enqueueSnackbar('Processing Data Saving...', { variant: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        globalPutService(`records/annotations/${this.props.fileInfo.uuid}/`, {annotations:annotations})
        .then(response => {
            if(checkApiStatus(response)){
                this.setState({isLoading:false})
                this.setState({annotations:annotations})
                this.props.enqueueSnackbar('Data saved successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
        })
    }
    render() {
        return <><div id="pdf-div" className="full-window-div" style={{'height':`${window.innerHeight-85}px`}}/></>;
    }
}

export default withSnackbar(AdobePdfViewer);

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getGlobalCookie, setGlobalCookie } from '../utils';
export default function PrivateRoute ({component: Component, ...rest}) {
  if(!window.location.href.includes('login') && !window.location.href.includes('select-lessor')){
      setGlobalCookie('redirectURIRecords', window.location.href, 3)
  }
  return (
    <Route
      {...rest}
      render={ (props) => getGlobalCookie('lessorAccess') ? <Component {...props} /> : <Redirect to='/login' />
      }
    />
  )
}

import React from "react";
import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { checkPermission, getLocalStorageInfo } from '../../../utils';
import config from '../../../config';
import { castleLakeInstance, halcyonInstance } from "../../../shared_elements/components";
const AddAssetDropdown = ({}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const onAddAsset = (assetType) => {
        window.open(`${config.domain.subDomian}assets-listing?apps=records&asset_type=${assetType}`,"_self")
    }
    const jetStreamInstance =  getLocalStorageInfo()?.defaultLessor?.id === 442;
    const smbcInstance =  getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 )
    return(
        <div>
            {!window.location.href.includes('archived-assets') ?
            <Button endIcon={<ArrowDropDownIcon fontSize="small" />} color='primary' size='small' variant="contained" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Add Asset
            </Button> : null}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {checkPermission('technical_specs','aircraft', 'C') && !smbcInstance && !halcyonInstance ? <MenuItem onClick={() => {handleClose();onAddAsset(1)}}>Aircraft (MSN)</MenuItem> : null}
                {checkPermission('technical_specs','engine', 'C') ? <MenuItem onClick={() => {handleClose();onAddAsset(2);}}>Engine (ESN)</MenuItem> : null}
                {checkPermission('technical_specs','apu', 'C') && !smbcInstance && !halcyonInstance ? <MenuItem onClick={() => {handleClose();onAddAsset(3)}}>APU</MenuItem> : null}
                {checkPermission('technical_specs','landing_gears', 'C') && !smbcInstance && !halcyonInstance ? <MenuItem onClick={() => {handleClose();onAddAsset(4)}}>Landing Gear</MenuItem> : null}
                {checkPermission('technical_specs','propeller', 'C')  && !smbcInstance && !castleLakeInstance && !halcyonInstance? <MenuItem onClick={() => {handleClose();onAddAsset(5)}}>Propeller</MenuItem> : null}
                {checkPermission('technical_specs','engine', 'C')  && !jetStreamInstance  && !smbcInstance && !castleLakeInstance && !halcyonInstance? <MenuItem onClick={() => {handleClose();onAddAsset(6)}}>Engine Module</MenuItem> : null}
            </Menu>
        </div>
    )
}
export default AddAssetDropdown;
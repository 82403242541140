import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Paper, Table, TableCell, TableHead, TableBody, TableRow, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress, Menu, MenuItem, Hidden } from '@material-ui/core';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus, checkPermission } from '../../../utils';
import { revAssetTypeValues, assetTypeValues } from '../../../constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DeletePopUp, EmptyCollection, PageLoader, Pagination } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { getAllAssetListAc } from '../../../shared_elements/actionCreators';
import MobileSecondarySideNav from '../components/MobileSecondarySideNav';
const StatusMenu = ({ match, item, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onChangeStatus = (status) => {
        globalPutService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-inspection/${item.slug}/`, { ...item, status })
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar('Status updated successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    getResponseBack();
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            })
    }
    return (
        <>
            <span className={`checklist-status ${item.status.label.toLowerCase()}`} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <span className='flex-centered'>
                    <span>{item?.status?.label || '--'}</span>
                    <ArrowDropDownIcon fontSize='small' />
                </span>

            </span>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { handleClose(); onChangeStatus(item.status.label === 'Active' ? { value: 2, label: 'Completed' } : { value: 1, label: 'Active' }) }}>
                    {item.status.label === 'Active' ? 'Completed' : 'Active'}
                </MenuItem>
            </Menu>
        </>
    )
}
const AddEditCheckList = ({ match, addEditCheckList, getResponseBack, toggleModalFn }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [checkList, setCheckList] = useState(addEditCheckList.data);
    const [templates, setTemplates] = useState([]);
    const [error, setError] = useState({});
    useEffect(() => {
        getTemplatesApi();
    }, []);
    const getTemplatesApi = () => {
        globalGetService(`records/checklist-template/?retrieve-all=true`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setTemplates(response.data.data)
                }
            })
    }
    const onAddEditCheckList = () => {
        const validationInputs = {
            name: checkList?.name?.trim()?.length ? '' : 'Please enter Name',
            template: checkList.id ? '' : checkList?.template?.slug ? '' : 'Please select Template',
            status: checkList.id ? checkList?.status?.value ? '' : 'Please select Status' : ''
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            let payload = {}
            if (checkList.id) {
                payload = Object.assign({}, checkList);
            } else {
                payload = {
                    name: checkList.name,
                    description: checkList.description,
                    template_slug: checkList.template.slug
                }
            }

            if (checkList.id) {
                globalPutService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-inspection/${checkList.slug}/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            toggleModalFn(); getResponseBack();
                        } else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-inspection/`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            toggleModalFn(); getResponseBack();
                        } else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        }
                        setLoading(false);
                    })
            }

        } else {
            setError(validationInputs)
        }
    }
    return (
        <Dialog
            open={addEditCheckList.modal}
            onClose={toggleModalFn}
            className='records-dataroom-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">{checkList?.id ? 'Edit' : 'Add'} Checklist</DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ width: '100%',margin: 'auto' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                required
                                label="Name"
                                name="name"
                                fullWidth
                                margin="normal"
                                value={checkList?.name || ''}
                                error={error?.name}
                                helperText={error?.name || ''}
                                inputProps={{ maxLength: 250 }}
                                onChange={(e) => setCheckList({ ...checkList, name: e.target.value })}
                                onFocus={() => setError({ ...error, name: '' })}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        {checkList?.id && checkPermission('records', 'inspection_checklist', 'STS') && (
                            <Grid item xs={12} sm={12} md={12}>
                                <Autocomplete
                                    name="status"
                                    options={[{ value: 1, label: 'Active' }, { value: 2, label: 'Completed' }]}
                                    getOptionLabel={option => option.label}
                                    onChange={(e, value) => { setCheckList({ ...checkList, status: value }); setError({ ...error, status: '' }) }}
                                    value={checkList.status}
                                    renderInput={params => <TextField required error={error.status ? true : false} helperText={error.status ? error.status : ''} {...params} label="Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                        )}
                        {!checkList?.id && (
                            <Grid item xs={12} sm={12} md={12}>
                                <Autocomplete
                                    name="template"
                                    options={templates?.list || []}
                                    getOptionLabel={option => option.name}
                                    onChange={(e, value) => { setCheckList({ ...checkList, template: value }); setError({ ...error, template: '' }) }}
                                    renderInput={params => <TextField required error={error.template ? true : false} helperText={error.template ? error.template : ''} {...params} label="Template" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                label="Description"
                                name="description"
                                fullWidth
                                margin="normal"
                                multiline={true}
                                rows={3}
                                value={checkList?.description || ''}
                                onChange={(e) => setCheckList({ ...checkList, description: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} disabled={isLoading} size='small' color="primary" variant='outlined'>Cancel</Button>
                <Button onClick={onAddEditCheckList} disabled={isLoading} size='small' color="primary" variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>

    )
}
const CheckListHD = ({ }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Updated By</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align='right'>Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
const CheckListItem = ({ history, match, item, onEdit, onDelete, getResponseBack }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (id) => {
        setShowMore({
            ...showMore,
            [id]: !showMore[id],
        });
    };
    return (
        <TableRow>
            <TableCell>{item?.name || '--'}</TableCell>
            <Hidden only={['lg','md','xl']}>
            <TableCell >
                {/* <div style={{ maxWidth: '420px' }}>
                    {item?.description || '--'}
                </div> */}
                {item?.description && item?.description?.length > 30 ? (
                    <div  >
                        {showMore[item?.id] && showMore[item?.id] ? (
                            <span> {item?.description}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.description.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div>{item.description || '--'}</div>
                }
            </TableCell>
            </Hidden>
            <Hidden only={['sm','xs']}>
            <TableCell style={{maxWidth:'100px'}}>
                {/* <div style={{ maxWidth: '420px' }}>
                    {item?.description || '--'}
                </div> */}
                {item?.description && item?.description?.length > 30 ? (
                    <div  >
                        {showMore[item?.id] && showMore[item?.id] ? (
                            <span> {item?.description}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.description.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div>{item.description || '--'}</div>
                }
            </TableCell>

            </Hidden>
            <TableCell >{item?.created_by?.name || '--'}</TableCell>
            <TableCell>{item?.updated_by?.name || '--'}</TableCell>
            <TableCell>
                {item?.status?.label ?
                    checkPermission('records', 'inspection_checklist', 'STS') ? <StatusMenu match={match} item={item} getResponseBack={getResponseBack} /> : <span className={`checklist-status ${item.status.label.toLowerCase()}`}>{item.status.label}</span>
                    : '--'
                }
            </TableCell>
            <TableCell style={{ width: '80px' }}>
                <ul className='list-inline flex-centered' style={{ float: 'right' }}>
                    <li className='list-inline-item' onClick={() => history.push(`/${match.params.assetType}/${match.params.assetSlug}/data-room/checklist/view/${item.slug}`)} style={{ cursor: 'pointer' }}>
                        <VisibilityIcon fontSize='small' color='primary' />
                    </li>
                    {checkPermission('records', 'inspection_checklist', 'U') ?
                        <li className='list-inline-item' style={{ cursor: 'pointer' }}>
                            <EditIcon onClick={onEdit} fontSize='small' color='primary' />
                        </li> : null
                    }
                    {checkPermission('records', 'inspection_checklist', 'D') ?
                        <li className='list-inline-item' style={{ cursor: 'pointer' }}>
                            <DeleteOutlineIcon onClick={onDelete} fontSize='small' color='secondary' />
                        </li> : null
                    }
                </ul>
            </TableCell>
        </TableRow>
    )
}
const DataCheckList = ({ match, history, location }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [checkListInfo, setCheckListInfo] = useState({ list: [], pagination: null });
    const [filter, setFilter] = useState({});
    const [addEditCheckList, setAddEditCheckList] = useState({ modal: false, mode: '', data: null })
    const [deleteCheckList, setDeleteCheckList] = useState({ modal: false, data: null });
    const allAssets = useSelector(state => state?.shareReducer?.allAssets || [])
    const dispatch = useDispatch()
    useEffect(() => {
        getCheckListInfo(filter, 'skeletonLoader');
        if (!allAssets?.length) {
            dispatch(getAllAssetListAc())
        }

    }, [location.pathname])
    const getCheckListInfo = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-inspection/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setCheckListInfo(response.data.data);
                    delete query.page
                    delete query.per_page
                    delete query.sort
                    delete query.sort_by
                    setFilter(query)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteCheckList = () => {
        globalDeleteService(`records/${match.params.assetType}/${match.params.assetSlug}/checklist-inspection/${deleteCheckList.data.slug}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    setDeleteCheckList({ modal: false, data: null })
                    getCheckListInfo({ ...filter, per_page: checkListInfo?.pagination?.per_page || 20 }, 'pageLoader')
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            })
    }
    const changeAssetSwitcher = (asset) => {
        history.push(`/${assetTypeValues[asset.asset_type].urlType}/${asset.slug}/data-room/checklist/`);
        setTimeout(() => { window.location.reload(true) }, 800)
    }
    const asset = allAssets.find(asset => asset.slug === match.params.assetSlug && asset.asset_type === revAssetTypeValues[match.params.assetType].assetType)
    return (
        <section className='data-room-file-checklists'>
            <Hidden only={['lg', 'xl']} ><MobileSecondarySideNav assetinfo={asset} /></Hidden>
            {skeletonLoader ? <STableLoader count={4} /> :
                <>
                    <div style={{ marginBottom: '5px' }}>
                        <Grid container spacing={0} alignItems='center'>
                            <Hidden only={['sm', 'xs']}>
                                <Grid item md={6}>
                                    {allAssets?.length ?
                                        <>
                                            {(() => {
                                                let asset = allAssets.find(asset => asset.slug === match.params.assetSlug && asset.asset_type === revAssetTypeValues[match.params.assetType].assetType)
                                                return (
                                                    <div className='records-dataroom-modal' style={{ width: '280px' }}>
                                                        <Autocomplete
                                                            options={allAssets}
                                                            getOptionLabel={option => option.unique_name}
                                                            value={asset}
                                                            disableClearable={true}
                                                            onChange={(e, value) => value ? changeAssetSwitcher(value) : e.preventDefault()}
                                                            renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                                        />
                                                    </div>
                                                )
                                            })()}
                                        </> : null
                                    }
                                </Grid>
                            </Hidden>
                            <Grid item md={6}>
                                {checkPermission('records', 'inspection_checklist', 'C') ?
                                    <ul className='list-inline' style={{ float: 'right' }}>
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setAddEditCheckList({ modal: true, mode: 'add', data: { name: '', description: '', files: [] } })} color='primary' size='small' variant='contained'>Add Checklist</Button>
                                        </li>
                                    </ul> : null
                                }
                            </Grid>
                        </Grid>
                    </div>
                    {/* <Paper square style={{ padding: '5px 10px',overflow:'auto'}}>
                        <Grid container spacing={1}>
                            <Grid item md={12}></Grid>
                        </Grid>
                    </Paper> */}
                    <Paper style={{ maxHeight: window.innerHeight - 190 + 'px', overflow:'auto'}}>
                        <Table  className='mui-table-format' stickyHeader>
                            <CheckListHD />
                            <TableBody>
                                {checkListInfo.list.map((item, index) =>
                                    <CheckListItem
                                        item={item}
                                        onEdit={() => setAddEditCheckList({ modal: true, mode: 'edit', data: item })}
                                        onDelete={() => setDeleteCheckList({ modal: true, data: item })}
                                        history={history}
                                        match={match}
                                        getResponseBack={() => getCheckListInfo({ ...filter }, 'pageLoader')}
                                    />
                                )}
                            </TableBody>
                        </Table>
                        <Pagination
                            pagination={checkListInfo.pagination}
                            onChangePage={(event, newPage) => getCheckListInfo({ ...filter, page: newPage + 1, per_page: checkListInfo?.pagination?.per_page || 20 }, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getCheckListInfo({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                        />
                        {!checkListInfo?.list?.length ? <EmptyCollection title='No records found' /> : null}
                    </Paper>
                </>
            }

            {deleteCheckList.modal ?
                <DeletePopUp
                    modal={deleteCheckList.modal}
                    toggleModalFn={() => setDeleteCheckList({ modal: false, data: null })}
                    title="Delete Checklist"
                    content={<h4>Are you sure you want to delete?</h4>}
                    triggerEventFn={onDeleteCheckList}
                /> : null
            }
            {addEditCheckList.modal ?
                <AddEditCheckList
                    match={match}
                    addEditCheckList={addEditCheckList}
                    getResponseBack={() => getCheckListInfo({ ...filter }, 'pageLoader')}
                    toggleModalFn={() => setAddEditCheckList({ modal: false, mode: '', data: null })}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default withRouter(DataCheckList);
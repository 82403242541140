import { globalGetService, globalPostService, globalFileUploadService } from '../../utils/globalApiServices';
import * as actions from './actions';
import { checkApiStatus } from '../../utils';
export const updateTheFileStacksAc = (files) => {
  return (dispatch) => {
    return new Promise(function(resolve, reject){
      dispatch({
        type: actions.UPLOAD_FILE_STACK,
        payload: files
      });
      resolve();
    })
  }
}
export const updateAfterFileStacksAc = (file) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPLOAD_AFTER_FILE_STACK,
      payload: file
    });
  }
}
export const uploadFileDataAc = (props, fileIndex=0) => {
  return (dispatch) => {
    let uploadFiles = new FormData();
    uploadFiles.append('file_'+ (parseInt(fileIndex)+1), this.props.fileStacks[fileIndex]);
    uploadFiles.append('path_'+ (parseInt(fileIndex)+1), this.props.fileStacks[fileIndex].path);
    var config = {
  		onUploadProgress: function(progressEvent) {
  		 var percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
  		}
  	}
    globalFileUploadService(``)
  }
}

import React, { Fragment } from 'react';
import { withRouter} from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
const UploadFilesIssueModal = ({uploadFileIssue, contineOnOverwrite, contineWithSkip, toggleModalFn}) => {
  return(
    <Dialog
      open={uploadFileIssue.modal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        Issues in File(s)
      </DialogTitle>
      <DialogContent dividers={true} className="issue-files-modal">
        { uploadFileIssue.modal ?
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              { uploadFileIssue.improper_files && uploadFileIssue.improper_files.length ?
                <div>
                  <h4>Naming Convention Not Followed:</h4>
                  <ul className="list-unstyled">
                    {uploadFileIssue.improper_files && uploadFileIssue.improper_files.map((file) =>
                      <li>
                        <h5>{file.name.length < 50 ? file.name:file.name.trim(0,47)+'...'}</h5>
                        {file.path && file.name !== file.path ? <p style={{fontSize:'11px'}}>{file.path.replace(file.name,'')}</p>:null}
                      </li>
                    )}
                  </ul>
                </div>:null
              }
              { uploadFileIssue.overwrite_files && uploadFileIssue.overwrite_files.length ?
                <div>
                  <h4>Duplicate Files:</h4>
                  <ul className="list-unstyled">
                    {uploadFileIssue.overwrite_files && uploadFileIssue.overwrite_files.map((file) =>
                      <li>
                        <h5>{file.name}</h5>
                        {file.path && file.name !== file.path ? <p style={{fontSize:'11px'}}>{file.path.replace(file.name,'')}</p>:null}
                      </li>
                    )}
                  </ul>
                </div>:null
              }
            </Grid>
            { uploadFileIssue.file_naming_conventions && uploadFileIssue.file_naming_conventions.length ?
              <Grid item xs={12} md={6}>
                <h4>File(s) Naming Convention:</h4>
                <ul className="list-unstyled">
                  {uploadFileIssue.file_naming_conventions.map((name) =>
                    <li>{name}</li>
                  )}
                </ul>
              </Grid>:null
            }
          </Grid>:null
        }

      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleModalFn(false)}>Cancel</Button>
        {(uploadFileIssue.improper_files || uploadFileIssue.overwrite_files) && (uploadFileIssue.improper_files.length || uploadFileIssue.overwrite_files.length) ?
          (uploadFileIssue.improper_files.length+uploadFileIssue.overwrite_files.length) < uploadFileIssue.files.length ? <Button onClick={() => contineWithSkip()} variant="contained"  color="primary">Skip and Continue</Button>:null
          :null}
        <Button variant="contained"  color="primary" onClick={() => contineOnOverwrite()}>Overwrite and Continue</Button>
      </DialogActions>
    </Dialog>
  )
}
export default UploadFilesIssueModal;

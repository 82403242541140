import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link, withRouter } from 'react-router-dom';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import FlightIcon from '@material-ui/icons/Flight';
import folderIcon from '../../../assets/images/folder_icon.svg'
import techSpecIcon from '../../../assets/images/tech_specs_icon.svg'
import dataRmIcon from '../../../assets/images/data_rm_icon.svg'
import priorityActiveIcon from '../../../assets/images/priority_active_icon.svg'
import priorityInactiveIcon from '../../../assets/images/priority_inactive_icon.svg'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { checkPermission, getFileSize, getLocalStorageInfo } from '../../../utils';
import { assetTypeValues } from '../../../constants';
import {trackActivity} from '../../../utils/mixpanel'
import config from '../../../config';
const AssetList = ({item, changeOcrPriority, location, history}) => {
    return(
        <TableRow onClick={() => (location.pathname.includes('archived-assets')|| item?.archive_status==true) ? history.push(`/${assetTypeValues[ item?.record_folders?.technical_records?.asset_type ? item.record_folders.technical_records?.asset_type :item.asset_type].urlType}/${ item.record_folders.technical_records?.asset_slug ? item.record_folders.technical_records?.asset_slug:item.slug}/data-room/technical/${item.record_folders.technical_records.uuid}?archived-assets`): history.push(`/${assetTypeValues[ item?.record_folders?.technical_records?.asset_type ? item.record_folders.technical_records?.asset_type :item.asset_type].urlType}/${ item.record_folders.technical_records?.asset_slug ? item.record_folders.technical_records?.asset_slug:item.slug}/data-room/technical/${item.record_folders.technical_records.uuid}`) }>
            <TableCell>
                <div className="flex-centered">
                    <img style={{width:'40px'}} className="folder-icon" src={folderIcon} alt="Folder Icon" />
                    <div className="asset" style={{marginLeft:'5px'}}>
                        {(item.asset_type === 1 || item.asset_type === 2 || item.asset_type === 3) ?
                        <p>
                            { item.asset_type === 1 ? item.aircraft_type?.name : item.engine_type?.name }{ item.asset_type === 3 ? item.apu_type?.name : '' }
                        </p>:null
                        }
                        <h5>
                            {assetTypeValues[item.asset_type].label ? assetTypeValues[item.asset_type].label: ''} {item.asset_name}
                        </h5>
                        {location?.pathname.includes('/archived-assets')?null: item?.archive_status==true?<span className='archive-chip-class'> Archive </span>: null}
                        { item.current_registration_number ?
                            <span style={{fontSize:'11px'}}><b>Reg. No.</b> {item.current_registration_number ? item.current_registration_number:''}</span>:null
                        }
                    </div>
                </div>
            </TableCell>
            <TableCell>{item?.records_details?.files_count||'--'}</TableCell>
            <TableCell>{item?.records_details?.files_size ? getFileSize(item.records_details.files_size):'--'}</TableCell>
            <TableCell>{item?.records_details?.files_inqueue||'--'}</TableCell>
            <TableCell>{item?.records_details?.files_ocr_inqueue||'--'}</TableCell>
            <TableCell>{item?.records_details?.files_to_be_renamed||'--'}</TableCell>
            <TableCell width={50}>
                <ul className="list-inline flex-centered">
                    <li className="list-inline-item" style={{cursor:'pointer'}} onClick={(e) => {e.preventDefault();e.stopPropagation(); window.open(`${config.domain.subDomian}technical-specification/${assetTypeValues[item.asset_type].urlType}/${item.slug}${item?.archive_status==true? '?archived-assets':'' }`,'_blank'); trackActivity('Item Clicked', { page_title: 'Asset Listing',event_desc: 'Clicked Tech Specs icon from Action in Asset listing'})}}>
                        <Tooltip title="Technical Specification"  arrow >
                            <a><img src={techSpecIcon} alt="Tech Specs" /></a>
                        </Tooltip>
                    </li>
                    { item?.record_folders?.technical_records?.uuid ?
                        <li className="list-inline-item" style={{cursor:'pointer'}} onClick={(e) => {(location.pathname.includes('archived-assets')|| item?.archive_status==true) ? history.push(`/${assetTypeValues[ item?.record_folders?.technical_records?.asset_type ? item.record_folders.technical_records?.asset_type :item.asset_type].urlType}/${ item.record_folders.technical_records?.asset_slug ? item.record_folders.technical_records?.asset_slug:item.slug}/data-room/technical/${item.record_folders.technical_records.uuid}?archived-assets`): history.push(`/${assetTypeValues[ item?.record_folders?.technical_records?.asset_type ? item.record_folders.technical_records?.asset_type :item.asset_type].urlType}/${ item.record_folders.technical_records?.asset_slug ? item.record_folders.technical_records?.asset_slug:item.slug}/data-room/technical/${item.record_folders.technical_records.uuid}`)  ;e.preventDefault(); e.stopPropagation();trackActivity('Item Clicked', { page_title: 'Asset Listing', event_desc: 'Clicked Data room icon from Action in Asset listing'})}}>
                            <Link>
                                <Tooltip title="Data Room" arrow>
                                    <img src={dataRmIcon} alt="data room" />
                                </Tooltip>
                            </Link>
                        </li>:null
                    }
                    { !isMobile && item?.records_details?.files_count > 0 && checkPermission('records','data_room','OCR_P') ?
                        <li className="list-inline-item" style={{cursor:'pointer'}} onClick={(e) => { e.preventDefault();e.stopPropagation(); trackActivity('Item Clicked',{page_title: 'Asset Listing',event_desc: 'Clicked OCR priority icon from Action in Asset listing'})}}>
                            <Tooltip title="Change OCR Priority" arrow>
                                { item.ocr_priority === 4 ?
                                    <img onClick={() => changeOcrPriority()} src={priorityInactiveIcon} alt="priority" />:
                                    <img onClick={() => changeOcrPriority()} src={priorityActiveIcon} alt="priority" />
                                }
                            </Tooltip>
                        </li>:null
                    }
                </ul>
            </TableCell>
        </TableRow>
    )
}
export default withRouter(AssetList);
import ActionDocks from './containers';
import PageLayout from '../../hoc/PageLayout';
import UserRoleAccess from '../../hoc/UserRoleAccess';
export const actionDockRoutes = [
  {
    path: '/action-dock/:type',
    component: PageLayout(ActionDocks, {apps: 'ActionDock', layoutPhase: 1}),
    key: 'ActionDocks',
    apps:'records'
  }
]

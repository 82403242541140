import React, { useState } from 'react';
import { TableRow, TableCell, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, Paper } from '@material-ui/core';
import { EmptyCollection, Pagination, PageLoader } from '../../../shared_elements';
import { assetTypeValues } from '../../../constants';
import { trackActivity } from '../../../utils/mixpanel';
import { globalGetService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
const AssetPriorityModal = ({}) => {
  const [isLoading, setLoading] = useState(false)
  const [modal, setModal] = useState(false);
  const [assets, setAssets] = useState({list:[],pagination:null});
  const getOCRAssets = (query={}) => {
    setLoading(true)
    globalGetService('console/records-asset-list/?lite=1', window.location.href.includes('archived') ? { ...query, ocr_priority: 'all' , archive_status: 'True'} : {...query, ocr_priority: 'all' })
    .then(response => {
      if(checkApiStatus(response)){
        setModal(true);
        setAssets(response.data.data);
      }
      setLoading(false)
    })
  }
  return(
    <>
      <Button size='small' variant="contained" color="primary" onClick={() => {getOCRAssets(); trackActivity('Item Clicked',{page_title:'Asset List', item_type:'OCR Priority Button', event_desc:'Clicked on OCR Priority Button from Asset List'}) }}>OCR Priority</Button>
        { modal ?
          <Dialog
            open={modal}
            onClose={() => setModal(false)}
            className='records-dataroom-modal'
          >
            <DialogTitle id="scroll-dialog-title">
              OCR Priority Assets
            </DialogTitle>
            <DialogContent dividers={true}>
              <Paper>
                <div>
                  <Table className='mui-table-format'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Asset(s)</TableCell>
                        <TableCell>Priority</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { assets.list.map((item,index) =>
                        <TableRow hover tabIndex={-1}>
                          <TableCell width="180">
                            <h5>
                              {assetTypeValues[item.asset_type].label ? assetTypeValues[item.asset_type].label: ''} {item.asset_name}
                            </h5>
                          </TableCell>
                          <TableCell width="180">
                            {item.ocr_priority === 1 ? 'High' : item.ocr_priority === 2 ? 'Medium':'Low'}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Pagination 
                      pagination={assets.pagination}
                      onChangePage={(event, newPage) => getOCRAssets({ page:newPage+1, per_page: assets.pagination.per_page}, 'pageLoader')}
                      onChangeRowsPerPage={(event) => getOCRAssets({ page:1, per_page: event.target.value}, 'pageLoader')}
                  />
                  {!assets.list.length ? <div style={{textAlign:'center'}}><EmptyCollection title={'No records found'} /></div>:null}
                </div>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => setModal(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>:null
        }
        { isLoading ? <PageLoader />:null}
    </>
  )
}
export default AssetPriorityModal;

import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import React from 'react';
const ActionDockHd = ({match, bulkOperation, bulkFlag, toggleBulkOps}) => {
    return(
        <TableHead>
            <TableRow>
                { bulkOperation ?
                    <TableCell>
                        <Checkbox
                            color="primary"
                            className="checkbox-icon"
                            checked={bulkFlag}
                            onChange={(e)=> toggleBulkOps(e.target.checked)}
                            size="small"
                        />
                    </TableCell>:null
                }
                <TableCell > Name </TableCell>
                <TableCell> Asset </TableCell>
                <TableCell> Size </TableCell>
                <TableCell> Uploaded By </TableCell>
                <TableCell> Updated By </TableCell>
                <TableCell> Last Modified At </TableCell>
                {match.params.type === 'in_queue' || match.params.type === 'rename_queue' ?
                    <TableCell> Status </TableCell>:null
                }
                { match.params.type === 'in_queue' || match.params.type === 'ocr_queue' ?
                    <TableCell> OCR Status </TableCell>:null
                }
                { match.params.type === 'ocr_rejected_queue' || match.params.type === 'rejected_queue' ? 
                    <TableCell> Action </TableCell>:null
                }
            </TableRow>
        </TableHead>
    )
}
export default withRouter(ActionDockHd);
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Tabs, Tab, Paper, TextField, Grid } from '@material-ui/core';
import { getLocalStorageInfo } from '../../../utils'; 
import SearchIcon from '@material-ui/icons/Search';
import config from '../../../config';
import { castleLakeInstance, halcyonInstance } from '../../../shared_elements/components';
const AssetTab = ({filter, onFilterChange, onSearch, onChangeTab, assetCount}) => {
    const smbcInstance  =  getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 );
    const jetStreamInstance =  getLocalStorageInfo()?.defaultLessor?.id === 442;
    return(
        <>
            <Paper square style={{marginBottom:'10px'}}>
                <Tabs indicatorColor='primary' value={filter.asset_type} onChange={onChangeTab} variant={isMobile ? 'scrollable':'fullWidth'} scrollButtons={isMobile?'on':'off'} className={'no-margin-tab'}>
                    {smbcInstance || halcyonInstance ? null : <Tab label={`All ${assetCount?.aircraft?`(${Object.values(assetCount).reduce((t, key) => t + key, 0)})`:''}`} value={0}/>}
                    {smbcInstance || halcyonInstance ? null : <Tab label={`Aircraft ${assetCount?.aircraft?`(${assetCount?.aircraft})`:''}`} value={1} />}
                    <Tab label={`Engine ${assetCount?.engine?`(${assetCount?.engine})`:''}`} value={2} />
                    {smbcInstance || halcyonInstance || castleLakeInstance? null :<Tab label={`Propeller ${assetCount?.propeller?`(${assetCount?.propeller})`:''}`} value={5} />}
                    {smbcInstance || halcyonInstance ? null : <Tab className='lg-tab' label={`Landing Gear ${assetCount?.lg?`(${assetCount?.lg})`:''}`} value={4} />}
                    {smbcInstance || halcyonInstance ? null : <Tab label={`APU ${assetCount?.apu?`(${assetCount?.apu})`:''}`} value={3} />}
                    {jetStreamInstance || smbcInstance || halcyonInstance || castleLakeInstance ? null : <Tab label={`FAN ${assetCount?.fans?`(${assetCount?.fans})`:''}`} value={6} />}
                    {jetStreamInstance || smbcInstance || halcyonInstance || castleLakeInstance ? null : <Tab label={`HPC ${assetCount?.hpc?`(${assetCount?.hpc})`:''}`} value={7} />}
                    {jetStreamInstance || smbcInstance || halcyonInstance || castleLakeInstance ? null : <Tab label={`HPT ${assetCount?.hpt?`(${assetCount?.hpt})`:''}`} value={8} />}                        
                    {jetStreamInstance || smbcInstance || halcyonInstance || castleLakeInstance ? null : <Tab label={`LPT ${assetCount?.lpt?`(${assetCount?.lpt})`:''}`} value={9} />}                           
                </Tabs>
            </Paper>
            <div className='records-dataroom-modal' style={{background:'#fff', margin:'5px 0', padding:'0 5px'}}>
                <Grid container spacing={0}>
                    <Grid md={6} xs={12}>
                        <form className='serial-no-search' onSubmit={(e)=> {e.preventDefault();onSearch()}}>
                            <TextField
                                placeholder='Enter Serial Number to Search'
                                name="serial_number"
                                fullWidth
                                margin="none"
                                value={filter?.serial_number||''}
                                onChange={(e, value) => onFilterChange('serial_number', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 25 }}
                                variant='outlined'
                            />
                            <span className='search-icon' onClick={()=> onSearch()}>
                                <SearchIcon fontSize='small' color='primary' />
                            </span>
                        </form>
                    </Grid>
                </Grid>
                
            </div>
        </>
    )
}
export default AssetTab;
import { Paper, TablePagination } from '@material-ui/core'
import React from 'react'
export default function PaginationComp({ bulkOperation = false, size = 'medium', bulkFlag, heads, noRecord, data, sort = '', sort_by = '', pagination = {}, onChangePage, onChangeRowsPerPage, createSortHandler, classNames, toggleBulkOps, tooltipContent }) {
    return (
        <Paper>
            <TablePagination
                rowsPerPageOptions={[10, 20, 40, 50, 100]}
                component="div"
                count={pagination.total}
                rowsPerPage={pagination.per_page}
                page={pagination.page - 1}
                backIconButtonProps={{ 'aria-label': 'previous page' }}
                nextIconButtonProps={{ 'aria-label': 'next page' }}
                onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
        </Paper>
    )
}



import React from 'react';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import DmFileCountCard from './DmFileCountCard'
const OcrFileCount = ({ analyticStats }) => {
  return (
    <div className="ocr-file-status">
      <Grid container spacing={isMobile ? 1 : 3}>
        <Grid item xs={6} sm={4} md={3} className="flex-not-centered">
          <DmFileCountCard
            tooltip={"Includes only pdf files"}
            title="OCRed File(s)"
            count={analyticStats.general_info.total_ocred_file}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} className="flex-not-centered">
          <DmFileCountCard
            tooltip={"Includes only pdf files"}
            title="OCR File(s) In Process"
            count={analyticStats.general_info.ocr_files_in_process}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} className="flex-not-centered">
          <DmFileCountCard
            tooltip={"Includes only pdf files"}
            title="OCR File(s) Pending"
            count={analyticStats.general_info.ocr_file_in_queue}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} className="flex-not-centered">
          <DmFileCountCard
            tooltip={"Includes only pdf files"}
            title="OCR File(s) Rejected"
            count={analyticStats.general_info.ocr_files_rejected}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default OcrFileCount;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { STableLoader } from '../../../shared_elements/loaders';
import { TableListComp, PageLoader, EmptyCollection, DeletePopUp } from '../../../shared_elements';
import { MiniPackFile, MiniPackBulkOps, MiniPackCoverPic, ShareContentModal } from '../components';
import AddIcon from '@material-ui/icons/Add';
import { getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import { getMiniPackDetailApi, removeMiniPackFilesApi, downloadResourceKitApi, onChangeMiniPackCoverPicApi, shareDataRoomDataApi, getMiniPackAssemblyApi } from '../apiServices';
import { dmKitsHd, toggleBulkOpsFn, applyFilterFn, onChangeBulkOps, listSingleActionFn } from '../'
import * as actions from '../../../shared_elements/actions'
import { imageMedia, videoTypes } from '../../../constants'
import { checkPermission } from '../../../utils';
class dataKits extends Component{
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader:false,
      pageLoader:false,
      bulkOperation:{ids:[], modal:false},
      filter:{},
      applyingFilter:{},
      miniPackCoverPic:{
        modal:false,
        file:null
      },
      dataKits:{
        list:[],
        pagination:null
      },
      shareContent: {modal:false, data:{}}
    }
    this.getMiniPackDetailApi = getMiniPackDetailApi.bind(this);
    this.removeMiniPackFilesApi = removeMiniPackFilesApi.bind(this);
    this.onChangeMiniPackCoverPicApi = onChangeMiniPackCoverPicApi.bind(this);
    this.downloadResourceKitApi = downloadResourceKitApi.bind(this);
    this.shareDataRoomDataApi = shareDataRoomDataApi.bind(this);
    this.getMiniPackAssemblyApi = getMiniPackAssemblyApi.bind(this);

    this.toggleBulkOpsFn = toggleBulkOpsFn.bind(this);
    this.applyFilterFn = applyFilterFn.bind(this);
    this.onChangeBulkOps = onChangeBulkOps.bind(this);
    this.listSingleActionFn = listSingleActionFn.bind(this);
  }
  componentDidMount(){
    this.getMiniPackDetailApi(this.props, {tab:'minipack',folder_uuid:this.props.match.params.uuid}, 'skeletonLoader');
    if(!this.props.pltConstants.filter(item => item.type === 'file_extension_drive').length){
      this.props.getPlatformConstants();
    }
  }
  toggleShareContent = () => {
    const { dataKits } = this.state;
    let shareData = {...dataKits.shared_details, type:'file', name:'MiniPack'}
    this.setState(prevState => ({
      ...prevState,
      shareContent: {
        ...prevState.shareContent,
        modal:true,
        data:shareData
      }
    }));
  }
  render(){
    const { skeletonLoader, pageLoader, shareContent, miniPackCoverPic, bulkOperation, filter, dataKits } = this.state;
    const { pltConstants, match } = this.props;
    return(
      <section className="action-docks">
        <div className="heading flex-centered">
          <h3 >
            
            <Link className="flex-centered" to={`/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/mini-pack/list`}>
              <ArrowBackIcon /> Mini Pack {dataKits.minipack_details && dataKits.minipack_details.name ? dataKits.minipack_details.name :''}
            </Link>
          </h3>
          { dataKits.minipack_details && dataKits.minipack_details.redirect_uuid && checkPermission('records','mini-pack','C') ?
            <Button className="flex-centered" startIcon={<AddIcon />} style={{marginLeft:'15px'}} color="primary" variant="outlined" size="small">
              <Link to={`/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/${dataKits.minipack_details.redirect_uuid}`}>Add Files</Link>
            </Button>:null
          }
        </div>
        { dataKits.list.length ?
          <MiniPackBulkOps
            dataKits={dataKits}
            bulkOperation={bulkOperation}
            onChangeBulkOps={this.onChangeBulkOps}
            downloadResourceKit={(query) => this.downloadResourceKitApi(this.props, query)}
            onChangeMiniPackPic={() => this.setState({miniPackCoverPic:{modal:true, file:null}})}
            toggleShareContent={this.toggleShareContent}
          /> : null
        }
        <Fragment>
          { skeletonLoader ?
            (<STableLoader count={5} />) :
            <Fragment>
              <TableListComp
                bulkOperation={true}
                bulkFlag={dataKits.list.length === bulkOperation.ids.length && dataKits.list.length > 0}
                toggleBulkOps={(flag) => this.toggleBulkOpsFn(flag, 'all')}
                heads={dmKitsHd}
                data={ dataKits.list.map((item,index) =>
                  <MiniPackFile
                    item={item}
                    index={index}
                    fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                    bulkOperation={bulkOperation}
                    toggleBulkOps={(flag) => this.toggleBulkOpsFn(flag, 'single', item.uuid)}
                    listSingleActionFn={(title) => this.listSingleActionFn(title, item.uuid)}
                  />
                )}
                pagination={dataKits.pagination}
                onChangePage={(event, newPage) => {this.setState({bulkOperation:{ids:[],modal:false}}) ;this.getResourcesKitFilesApi(this.props, { tab:this.props.match.params.kitType, page:newPage+1, per_page: dataKits.pagination.per_page, ...filter}, 'pageLoader')}}
                onChangeRowsPerPage={(event) => this.getResourcesKitFilesApi(this.props, { tab:this.props.match.params.kitType, page:1, per_page: event.target.value}, 'pageLoader')}
                noRecord={ dataKits.list.length ? null:
                  <EmptyCollection
                    title="No Records Found"
                  />
                }
              />
            </Fragment>
          }
        </Fragment>
        { bulkOperation.modal ?
          <DeletePopUp
            modal={bulkOperation.modal}
            toggleModalFn={() =>
              this.setState(prevState => ({
                ...prevState,
                bulkOperation:{
                  ...prevState.bulkOperation,
                  modal:false,
                  ids: prevState.bulkOperation.multi ? prevState.bulkOperation.ids:[]
                }
              }))
            }
            confirmText="Remove"
            title={bulkOperation.title ? bulkOperation.title:''}
            content={<h4>{`Are you sure you want to Remove?`}</h4>}
            triggerEventFn={() => this.removeMiniPackFilesApi(this.props, bulkOperation, 'remove')}
          />:null
        }
        { miniPackCoverPic.modal ?
          <MiniPackCoverPic
            dataKits={dataKits}
            miniPackCoverPic={miniPackCoverPic}
            onChangeMiniPackCoverPic={(files, type, id) => this.onChangeMiniPackCoverPicApi(this.props, files, type, id)}
            toggleModalFn={() => this.setState({miniPackCoverPic:{modal:false, file:null}})}
          />:null
        }
        { shareContent.modal ?
          <ShareContentModal
            shareContent={shareContent}
            toggleModalFn={() => this.setState({shareContent:{modal:false, data:{}}})}
            sharetheDmContent={(flag, hour) => this.shareDataRoomDataApi(this.props, {type:'folder', uuid:this.props.match.params.uuid, share:flag, hour:hour}, 'minipack')}
          />:null
        }
        { pageLoader ? <PageLoader /> : null }
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['file_extension_drive']})),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(dataKits))

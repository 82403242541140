import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { checkApiStatus,getLocalStorageInfo } from '../../../utils';
import { addNewAssetApi, archiveAssetApi } from '../apiServices';
import { fieldValidation } from '../../../utils/formValidation';
import { trackActivity } from '../../../utils/mixpanel';
import { alpErrorCode } from '../';
import {leaseStatus} from '../../../shared_elements/components';
import { regexConstants } from '../../../constants/regEx';
class AddALP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitLoader: false,
      data: {
        ownership_type: 1,
        manufacturer: '',
        date_of_manufacture: null,
        serial_number: '',
        part_number: '',
        apu_type: null, // Applicable for APU Only
        position: null, // Applicable for LG Only
        region: null,
        location: '',
        latitude: '',
        longitude: '',
        country: '',
        country_code: '',
        credit_classification: null,
        off_lease_status: null, //Might Not Needed
        status: null,
        lessee_id: null, // lessee_id
        operator_id: null, // operator_id
        same_operator_lessee: false,
        last_utilization_updated: null, // Applicable for APU & LG Only
        tsn: null,                        // Applicable for APU & LG Only
        csn: null,                        // Applicable for APU & LG Only
        average_monthly_hours: null,      // Applicable for APU & LG Only
        average_monthly_cycles: null,     // Applicable for APU & LG Only
        owner_portfolio: null
      },
      error: {}
    }
    this.addNewAssetApi = addNewAssetApi.bind(this);
    this.archiveAssetApi = archiveAssetApi.bind(this)
  }
  onFieldChange = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [keyParam]: value
      }
    }))
  }
  onRestErrorKey = (keyParam) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: ''
      }
    }))
  }
  onAddNewAsset = () => {
    const { data, error } = this.state;
    const { addAssetType } = this.props;
    let validationInputs = {};
    validationInputs = {
      ...validationInputs,
      serial_number: alpErrorCode['serial_number'][fieldValidation({ ...alpErrorCode['serial_numberObj'], fieldval: data.serial_number })],
      part_number: alpErrorCode['part_number'][fieldValidation({ ...alpErrorCode['part_numberObj'], fieldval: data.part_number })],
      status: alpErrorCode['status'][fieldValidation({ ...alpErrorCode['statusObj'], fieldval: data.status })],
      date_of_manufacture: alpErrorCode['date_of_manufacture'][fieldValidation({ ...alpErrorCode['date_of_manufactureObj'], fieldval: data.date_of_manufacture })],
      portfolio: data.ownership_type === 2 ? data.owner_portfolio ? '' : 'Please select Portfolio' : ''
    }
    if (addAssetType === 'APU') {
      validationInputs = {
        ...validationInputs,
        apu_type: alpErrorCode['apu_type'][fieldValidation({ ...alpErrorCode['apu_typeObj'], fieldval: data.apu_type })],
      }
    }
    if (addAssetType === 'Landing Gear') {
      validationInputs = {
        ...validationInputs,
        position: alpErrorCode['position'][fieldValidation({ ...alpErrorCode['positionObj'], fieldval: data.position })],
      }
    }
    if (data.status && data.status.value == 1) {
      validationInputs = {
        ...validationInputs,
        lessee_id: data.lessee_id && data.lessee_id.id ? '' : 'Please select Lessee'
      }
      if (!data.same_operator_lessee) {
        validationInputs = {
          ...validationInputs,
          operator_id: data.operator_id && data.operator_id.id ? '' : 'Please select Operator'
        }
      }
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      let url = '';
      if (addAssetType === 'APU') {
        url = 'console/add-apu/' // Add console/add-apu/
      }
      if (addAssetType === 'Landing Gear') {
        url = 'console/add-landing-gear/' // Add console/add-landing-gear/
      }
      if (addAssetType === 'Propeller') {
        url = 'console/add-propeller/' // Add console/add-propeller/
      }
      let payload = Object.assign({}, data);
      payload = {
        ...payload,
        apu_type: payload.apu_type && payload.apu_type.id ? payload.apu_type.id : null,
        position: payload.position && payload.position.value ? payload.position.value : null,
        region: payload.region && payload.region.id ? payload.region.id : null,
        credit_classification: payload.credit_classification && payload.credit_classification.value ? payload.credit_classification.value : null,
        status: payload.status.value,
        lessee_id: payload.lessee_id && payload.lessee_id.id ? payload.lessee_id.id : null,
        operator_id: payload.operator_id && payload.operator_id.id ? payload.operator_id.id : null,
        archive_status: payload.status.value == 5 ? true : false,
        tsn: payload.tsn ? payload.tsn : 0,
        csn: payload.csn ? payload.csn : 0,
        tslsv: payload.tslsv ? payload.tslsv : 0,
        cslsv: payload.cslsv ? payload.cslsv : 0,
        average_monthly_cycles: payload.average_monthly_cycles ? payload.average_monthly_cycles : 0,
        average_monthly_hours: payload.average_monthly_hours ? payload.average_monthly_hours : 0


      }
      if (payload.ownership_type === 2) {
        if (payload.owner_portfolio.id) {
          payload = {
            ...payload,
            owner_portfolio: payload.owner_portfolio.id
          }
        }
      }
      this.setState({ formSubmitLoader: true });
      this.addNewAssetApi(this.props, url, payload)
        .then(response => {
          this.setState({ formSubmitLoader: false });
          if (checkApiStatus(response)) {
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            trackActivity('Item Added', { page_title: 'Asset Listing', asset_type: `${addAssetType}`, serial_no: data.serial_number, event_type: `Add ${addAssetType}`, event_desc: `Added ${addAssetType} data from Add ${addAssetType} form` })
            setTimeout(() => {
              if (payload.status == 5) {
                this.props.history.push('/archived-assets')
              } else {
                window.location.reload(false)
              }
            }, 800);
          } else if ([5000, 5001, 5002].includes(response.data.statusCode)) {
            this.props.toggleLicenceModalFn(true, response.data.message)
          }
          else {
            this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        })
    } else {
      this.setState({ error: validationInputs })
    }
  }
  render() {
    const { formSubmitLoader, data, error } = this.state;
    const { portfolios, regions, lessees, operators, pltConstants, apuTypes, addAssetType, addAssetModal, toggleAssetType, ownersList } = this.props;
    const {jacLeaseStatus,defaultLeaseStatus} = leaseStatus
    return (
      <Fragment>
        <Dialog
          open={addAssetModal}
          onClose={() => { toggleAssetType() }}
          aria-labelledby="scroll-dialog-title"
          fullWidth='true'
        >
          <DialogTitle id="scroll-dialog-title">
            Add {addAssetType}
          </DialogTitle>
          <DialogContent dividers={true} className="data-upload-modal">
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="serial_number"
                    label="Serial Number"
                    fullWidth
                    margin="normal"
                    value={data.serial_number}
                    error={error.serial_number ? true : false}
                    helperText={error.serial_number ? error.serial_number : ''}
                    onFocus={() => this.onRestErrorKey('serial_number')}
                    onChange={(e, value) => this.onFieldChange('serial_number', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      required
                      margin="normal"
                      id="date_of_manufacture"
                      label="Date of Manufacture"
                      format={fieldDateFormat}
                      fullWidth
                      disableFuture
                      InputLabelProps={{ shrink: true }}
                      minDate={moment().subtract(25, 'years')}
                      value={data.date_of_manufacture}
                      error={error.date_of_manufacture ? true : false}
                      helperText={error.date_of_manufacture ? error.date_of_manufacture : ''}
                      onFocus={() => this.onRestErrorKey('date_of_manufacture')}
                      onChange={(data, value) => this.onFieldChange('date_of_manufacture', moment(data).format(backendDateFormat))}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="part_number"
                    label="Part Number"
                    fullWidth
                    margin="normal"
                    value={data.part_number}
                    error={error.part_number ? true : false}
                    helperText={error.part_number ? error.part_number : ''}
                    onFocus={() => this.onRestErrorKey('part_number')}
                    onChange={(e, value) => this.onFieldChange('part_number', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="manufacturer"
                    label="Manufacturer"
                    fullWidth
                    margin="normal"
                    value={data.manufacturer}
                    onChange={(e, value) => this.onFieldChange('manufacturer', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                {addAssetType === 'APU' ?
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={apuTypes}
                      getOptionLabel={option => option.name}
                      id="apu_type"
                      value={data.apu_type}
                      onChange={(e, value) => this.onFieldChange('apu_type', value)}
                      renderInput={params => <TextField required error={error.apu_type ? true : false} helperText={error.apu_type ? error.apu_type : ''} onFocus={() => this.onRestErrorKey('apu_type')} {...params} label="APU Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                    />
                  </Grid> : null
                }
                {addAssetType === 'Landing Gear' ?
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={pltConstants.filter(item => item.type === 'lg_position')}
                      getOptionLabel={option => option.label}
                      id="position"
                      value={data.position}
                      onChange={(e, value) => this.onFieldChange('position', value)}
                      renderInput={params => <TextField required error={error.position ? true : false} helperText={error.position ? error.position : ''} onFocus={() => this.onRestErrorKey('position')} {...params} label="LG Position" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                    />
                  </Grid> : null
                }

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={regions}
                    getOptionLabel={option => option.name}
                    id="region"
                    value={data.region}
                    onChange={(e, value) => this.onFieldChange('region', value)}
                    renderInput={params => <TextField {...params} label="Region of Operation" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="location"
                    label="Location"
                    fullWidth
                    margin="normal"
                    value={data.location}
                    onChange={(e, value) => this.onFieldChange('location', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel className="MuiInputLabel-shrink" component="label">Ownership Status</FormLabel>
                    <RadioGroup row aria-label="position" name="ownership_type" defaultValue="top">
                      <FormControlLabel value={1} control={<Radio checked={data.ownership_type === 1} onChange={() => this.onFieldChange('ownership_type', 1)} color="primary" size="small" />} label="Owned" />
                      <FormControlLabel value={2} control={<Radio checked={data.ownership_type === 2} onChange={() => this.onFieldChange('ownership_type', 2)} color="primary" size="small" />} label="Managed" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {data.ownership_type === 2 ?
                  <Grid item xs={12}>
                    <Autocomplete
                      options={ownersList}
                      getOptionLabel={option => option.name}
                      id="owner_portfolio"
                      value={data.owner_portfolio}
                      onChange={(e, value) => this.onFieldChange('owner_portfolio', value)}
                      renderInput={params => <TextField error={error.portfolio ? true : false} required={false} helperText={error.portfolio ? error.portfolio : ''} onFocus={() => this.onRestErrorKey('portfolio')} {...params} label="Portfolio" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                    />
                  </Grid> : null
                }
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={pltConstants.filter(item => item.type === 'credit_classification')}
                    getOptionLabel={option => option.label}
                    id="credit_classification"
                    value={data.credit_classification}
                    onChange={(e, value) => this.onFieldChange('credit_classification', value)}
                    renderInput={params => <TextField {...params} label="Credit Classification" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options = {getLocalStorageInfo().defaultLessor.id === 442 ? jacLeaseStatus : defaultLeaseStatus }
                    getOptionLabel={option => option.label}
                    id="status"
                    value={data.status}
                    onChange={(e, value) => this.onFieldChange('status', value)}
                    renderInput={params => <TextField required {...params} label="Lease Status" error={error.status ? true : false} helperText={error.status ? error.status : ''} onFocus={() => this.onRestErrorKey('status')} margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={lessees}
                    getOptionLabel={option => option.name}
                    id="lessee_id"
                    value={data.lessee_id}
                    onChange={(e, value) => this.onFieldChange('lessee_id', value)}
                    renderInput={params => <TextField error={error.lessee_id ? true : false} required={data.status && data.status.value == 1 ? true : false} helperText={error.lessee_id ? error.lessee_id : ''} onFocus={() => this.onRestErrorKey('lessee_id')} {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                    disabled={data?.status?.value != 1 ? true : false}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel className="MuiInputLabel-shrink" component="label">Is Lessee also the Operator?</FormLabel>
                    <RadioGroup row aria-label="position" name="same_operator_lessee" defaultValue="top">
                      <FormControlLabel value="is_titled" control={<Radio onChange={() => this.onFieldChange('same_operator_lessee', true)} checked={data.same_operator_lessee} color="primary" size="small" />} label="Yes" />
                      <FormControlLabel value="is_fitted" control={<Radio onChange={() => this.onFieldChange('same_operator_lessee', false)} checked={!data.same_operator_lessee} color="primary" size="small" />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {!data.same_operator_lessee ?
                  <Grid item xs={12}>
                    <Autocomplete
                      options={operators}
                      getOptionLabel={option => option.name}
                      id="operator_id"
                      value={data.operator_id}
                      onChange={(e, value) => this.onFieldChange('operator_id', value)}
                      renderInput={params => <TextField error={error.operator_id ? true : false} required={!data.same_operator_lessee ? true : false} helperText={error.operator_id ? error.operator_id : ''} onFocus={() => this.onRestErrorKey('operator_id')} {...params} label="Operator" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                    />
                  </Grid> : null
                }
                {addAssetType !== 'Propeller' ?
                  <Grid item xs={12}>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              margin="normal"
                              id="last_utilization_updated"
                              label="Utilization as of"
                              format={fieldDateFormat}
                              fullWidth
                              disableFuture
                              InputLabelProps={{ shrink: true }}
                              value={data.last_utilization_updated}
                              onChange={(data, value) => this.onFieldChange('last_utilization_updated', moment(data).format(backendDateFormat))}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="tsn"
                            label="TSN"
                            fullWidth
                            margin="normal"
                            value={data.tsn}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange('tsn', e.target.value) : e.preventDefault() }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="csn"
                            label="CSN"
                            fullWidth
                            margin="normal"
                            value={data.csn}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange('csn', e.target.value) : e.preventDefault() }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="average_monthly_hours"
                            label="Avg. Monthly Hours"
                            fullWidth
                            margin="normal"
                            value={data.average_monthly_hours}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange('average_monthly_hours', e.target.value) : e.preventDefault() }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="average_monthly_cycles"
                            label="Avg. Monthly Cycles"
                            fullWidth
                            margin="normal"
                            value={data.average_monthly_cycles}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange('average_monthly_cycles', e.target.value) : e.preventDefault() }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid> : null
                }

              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => { toggleAssetType(); trackActivity('Item Clicked', { page_title: 'Asset List', item_type: 'Cancel', event_desc: `Clicked on Cancel Button form Add ${addAssetType} form` }) }}>Cancel</Button>
            <Button onClick={() => { this.onAddNewAsset(); trackActivity('Item Clicked', { page_title: 'Asset List', item_type: 'Save ', event_desc: `Clicked on Save Button form Add ${addAssetType} form` }) }} color="primary" variant="contained">
              {formSubmitLoader ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(AddALP));

import { React, Fragment } from 'react'
import { Button, TableRow, TableCell, IconButton, Chip, Menu, MenuItem } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import folderIcon from '../../../assets/images/folder_icon.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { checkPermission } from '../../../utils';
function CommentsList({ item, handleStatusMenuClick, handleStatusMenuClose, handleStatusMenuItemClick, statusAnchorEl, expandedComment, toggleExpandedComment }) {
    return (
        <TableRow hover tabIndex={-1}>
            <TableCell style={{ width: "455px" }}>
                {item.record_type === 2 ? <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" style={{ verticalAlign: "top", margin: '2px' }} /> : <DescriptionIcon color='primary' style={{ verticalAlign: "top" }} />}
                {item.comment.length > 50 ? (
                    <Fragment>
                        {expandedComment[item.id] ? (
                            <Fragment> {item.comment} <span> <Link to="#" onClick={() => toggleExpandedComment(item.id)}>View Less</Link> </span> </Fragment>
                        ) : (
                            <Fragment> {item.comment.slice(0, 50)}... <span> <Link to="#" onClick={() => toggleExpandedComment(item.id)}>View More</Link> </span> </Fragment>
                        )}
                    </Fragment>) : (item.comment)}
            </TableCell>
            <TableCell> {moment(item.created_at).format('lll')} </TableCell>
            <TableCell>
                <Chip
                    style={{ backgroundColor: item.status === 1 && 'green', height: "24px", width: "90px", color: 'white' }}
                    label={item.status === 0 ? 'Open' : 'Resolved'}
                    color={item.status === 0 ? 'primary' : 'default'}
                    onClick={(event) => handleStatusMenuClick(event, item.id)}
                    icon={ checkPermission('records','comments_page','U') ? <ArrowDropDownIcon fontSize="small" style={{ paddingLeft: item.status === 1 ? "59px" : "41px", position: 'absolute', color: 'white' }} /> : null}
                />
                { checkPermission('records','comments_page','U') ? <Menu
                    anchorEl={statusAnchorEl[item.id]}
                    keepMounted
                    open={Boolean(statusAnchorEl[item.id])}
                    onClose={() => handleStatusMenuClose(item.id)}
                >
                    {item.status === 1 && <MenuItem onClick={(e) => handleStatusMenuItemClick(e, item, 0)}>Open</MenuItem>}
                    {item.status === 0 && <MenuItem onClick={(e) => handleStatusMenuItemClick(e, item, 1)}>Resolved</MenuItem>}
                </Menu>: null}
            </TableCell>
            <TableCell align='right'>
                <IconButton color="primary" component={Link} to={`${item.redirect_url + "?comment_id"}`} target={item.record_type === 2 ? "_self" : "_blank"}>
                    <VisibilityIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default CommentsList
import react from 'react'
import { Dialog, DialogContent, Button, DialogTitle } from '@material-ui/core';
const LicenceModal = ({flag, message, closeModal}) => {
  return (
    <Dialog
      open={flag}
      aria-labelledby="scroll-dialog-title"
      onClose={closeModal}
      classes={{
          paper: 'classes-state-root',
          disabled: 'disabled', }
      }
      maxWidth="lg"
      maxHeight="xs"
    >
      <DialogContent dividers={true}>
        <div className="licence-description">
          <p align="center" style={{marginBottom:'20px', marginTop:'20px'}}>{message}.</p>
          <p align="center" style={{marginBottom:'20px'}}>Please contact
            <a href="mailto:sales@acumen.aero" target="_blank"> sales@acumen.aero</a> /
            <a href="mailto:support@sparta.aero" target="_blank"> support@sparta.aero</a>
          </p>
        </div>
        <div align="center" style={{marginBottom:'20px'}}>
        <Button color="primary" variant="contained" onClick={closeModal}>OKAY</Button>
        </div>
      </DialogContent>
    </Dialog>
  )

}
export default LicenceModal;

{/*  <Dialog
    open={flag}
    maxWidth="xs"
    aria-labelledby="scroll-dialog-title"
    onClose={closeModal}
    classes={{paperScrollPaper: "license-modal"}}
  >
    <DialogContent>
    <div style={{margin:'25% 0%'}}>
      <p align="center" style={{marginTop:'20px', marginBottom:'20px'}}>{message}.</p>
      <p align="center" style={{marginBottom:'20px'}}>
        Please contact
        <a href="mailto:sales@acumen.aero"> sales@acumen.aero</a> /
        <a href="mailto:support@sparta.aero"> support@sparta.aero</a>
      </p>
      <div align="center">
        <Button color="primary" variant="contained" onClick={closeModal}>OKAY</Button>
      </div>
    </div>


    </DialogContent>
  </Dialog> */}

import React, {  Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { checkPermission, getLocalStorageInfo } from '../utils';
import  PageLoader  from '../shared_elements/components/PageLoader'
import restrictIcon from '../assets/images/restrict_icon.svg';
import config from '../config';
export const lockImgPath = 'https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/permissions/';
export default function UserRoleAccess(HocComponent, extraProps=[]){
  return class extends Component{
    render(){
      if(getLocalStorageInfo()){
        let permission = getLocalStorageInfo().user.permission;
        if(permission[extraProps[0]] && permission[extraProps[0]][extraProps[1]] && permission[extraProps[0]][extraProps[1]].indexOf(extraProps[2]) != -1){
          return <HocComponent {...this.props} />
        }else{
          return (
            <div className="page-not-found">
              <Grid container spacing={0}>
                <Grid xs={12}>
                  <img src={restrictIcon} alt="Icon"/>
                    <h2>You’re not permitted to see this.</h2>
                    <p>The page you’re trying to access has restricted access,<br/>
                      please contact <a style={{color:'#3F51B5', textDecoration:'auto'}} href="mailto:support@sparta.aero">support@sparta.aero</a> to access this application.
                    </p>
                  { checkPermission('records','data_room','R') ?
                    <Link className="start-over btn btn-default return-home" to='/assets'>Return Home</Link>:
                    <Link className="start-over btn btn-default return-home" onClick={() => window.location.assign(`${config.domain.subDomian}`)}>Return Home</Link> 
                  }
                </Grid>
              </Grid>
            </div>
          )
        }
      }else{
        return <PageLoader />
      }
    }
  }
}

import Activities from './pages/Activities';
import Inventories from './pages/Inventories';
import PageLayout from '../../hoc/PageLayout';
import UserRoleAccess from '../../hoc/UserRoleAccess';
import CustomAlerts from './pages/CustomAlerts';
export const reportsRoutes = [
  {
    path: '/reports/inventory',
    component: PageLayout(UserRoleAccess(Inventories, ['reports', 'inventory', 'R']), {apps: 'Reports', layoutPhase: 1}),
    key: 'Inventories',
    apps:'records'
  },
  {
    path: '/reports/activities',
    component: PageLayout(UserRoleAccess(Activities, ['reports', 'activities', 'R']), {apps: 'Reports', layoutPhase: 1}),
    key: 'Activities',
    apps:'records'
  },
  {
    path: '/reports/customalerts',
    component: PageLayout(CustomAlerts,{apps: 'Reports', layoutPhase: 1}),
    key: 'Activities',
    apps:'records'
  },
]

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { DropzoneArea } from 'material-ui-dropzone';
import { Autocomplete } from '@material-ui/lab';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Paper, TextField, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Checkbox, Tooltip ,Chip, CircularProgress, Hidden} from '@material-ui/core';
import { DeletePopUp, EmptyCollection, Pagination, PageLoader } from '../../../shared_elements'
import { displayDateFormatShort, revAssetTypeValues, assetTypeValues } from '../../../constants';
import { STableLoader } from '../../../shared_elements/loaders';
import { globalDeleteService, globalExportService, globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { getAllAssetListAc } from '../../../shared_elements/actionCreators';
import { checkApiStatus, downloadFileType, checkPermission, removeEmptyKey } from '../../../utils';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import  { AddIssue, IssueBulkOps } from '../components'
import FilterComponent from '../../../shared_elements/filter_component_v1/'
import { addNewIssueObj } from '../'
import MobileSecondarySideNav from '../components/MobileSecondarySideNav';
const issuesFilter = {
    status: {
        inputType: 'dropdown',
        placeholder: 'Please Select Status',
        title: 'Status',
        options: [{label:'Open',value:'0'},{label:'Closed',value:'1'}, {label:'Reopen',value:'2'},{label:'Unresolved',value:'3'}],
        labelKey: 'label',
        valueKey: 'value'
    },
    priority: {
        inputType: 'dropdown',
        placeholder: 'Please Select Priority',
        title: 'Priority',
        options: [{ label: 'None', value: '0' }, { label: 'P1', value: 1 }, { label: 'P2', value: 2 }],
        labelKey: 'label',
        valueKey: 'value'
    },
    assignee: {
        inputType: 'dropdown',
        placeholder: 'Please Select Assignee',
        title: 'Assignee',
        options: [],
        labelKey: 'name',
        valueKey: 'id'
    },
    created_by: {
        inputType: 'dropdown',
        placeholder: 'Please Select Created By',
        title: 'Created By',
        options: [],
        labelKey: 'name',
        valueKey: 'id'
    },
    created_at_from: {
        inputType: 'date',
        placeholder: 'Please Select Discrepancy Raised on (from)',
        title: 'Discrepancy Raised on (from)',
    },
    created_at_to: {
        inputType: 'date',
        placeholder: 'Please Select Discrepancy Raised on (to)',
        title: 'Discrepancy Raised on (to)',
    },
    section: {
        inputType: 'dropdown',
        placeholder: 'Please Select Category',
        title: 'Category',
        options: [],
        labelKey: 'name',
        valueKey: 'id'
    }, 
    part_number: {
        inputType: 'text',
        placeholder: 'Please enter Part Number',
        title: 'Part Number',
    },
    serial_number: {
        inputType: 'text',
        placeholder: 'Please enter Serial Number',
        title: 'Serial Number',
    },
    ata: {
        inputType: 'text',
        placeholder: 'Please enter ATA',
        title: 'ATA',
    }, 
}
const ImportIssues = ({match, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [files, setFiles] = useState([]);
    const onImportIssues = () => {
        let formData = new FormData();
        formData.append('file', files[0]);
        setLoading(true)
        globalPostService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/issueReport/`, formData)
        .then(response => {
            if(checkApiStatus(response)){
                setModal(false);
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                getResponseBack()
            }
            setLoading(false)
        })
    }
    return(
        <>
            <Button onClick={() => setModal(true)} size='small' color='primary' variant='outlined'>Import Issue(s)</Button>
            {modal ?
                <Dialog
                    open={modal}
                    onClose={() => {setModal(false);setFiles([])}}
                    aria-labelledby="scroll-dialog-title"
                    fullWidth= 'true'
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import Issue(s)
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <div>
                                    <DropzoneArea
                                        acceptedFiles={['.xls, .xlsx']}
                                        filesLimit={1}
                                        showPreviewsInDropzone={false}
                                        maxFileSize={10428800}
                                        useChipsForPreview={true}
                                        showPreviews={ true }
                                        dropzoneText={<p>Drag & Drop Attachments Or Click Here </p>}
                                        dropzoneClass="full-w-drag-drop-zone"
                                        maxWidth="sm"
                                        alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                        showAlerts={['error', 'info']}
                                        onChange={(files) => setFiles(files)}
                                    />
                                    <p><span style={{background:'#ffe160', fontSize:'11px', padding:'2px 3px'}}>Format Support: .xls, .xlsx</span></p>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => {setModal(false);setFiles([])}} color="primary" variant="text">CANCEL</Button>
                        {files.length ?
                            <Button disabled={isLoading} onClick={onImportIssues} color="primary" variant="contained">
                                {isLoading ? <CircularProgress size={24} />:'SAVE'}
                            </Button>:null
                        }
                    </DialogActions>
                </Dialog>:null
            }
        </>
    )
}
const IssueHd = ({onBulkToggle, bulkFlag, createSortHandler, sortInfo}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>
                    { checkPermission('records','records_issue','U') || checkPermission('records','records_issue','D') ?
                        <Checkbox color='primary' size='small' checked={bulkFlag} onChange={(e)=> onBulkToggle('all', e.target.checked)} />:null
                    }
                </TableCell>

                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'unique_code' ? true:false}
                        direction={sortInfo.sort === 'unique_code' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('unique_code')}
                    >
                        Issue Title
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    Category
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'priority' ? true:false}
                        direction={sortInfo.sort === 'priority' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('priority')}
                    >
                        Priority
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'status' ? true:false}
                        direction={sortInfo.sort === 'status' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('status')}
                    >
                        Status
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    Status Updated By
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'assignee' ? true:false}
                        direction={sortInfo.sort === 'assignee' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('assignee')}
                    >
                        Assignee
                    </TableSortLabel>
                    
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'started_date' ? true:false}
                        direction={sortInfo.sort === 'started_date' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('started_date')}
                    >
                        Discrepancy Raised on
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'created_by' ? true:false}
                        direction={sortInfo.sort === 'created_by' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('created_by')}
                    >
                        Created By
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    P/N
                </TableCell>
                <TableCell>
                    S/N
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'ata' ? true:false}
                        direction={sortInfo.sort === 'ata' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('ata')}
                    >
                        ATA
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}
const IssueList = ({match, item, onDelete, onBulkToggle, bulkOps}) => {
    return(
        <TableRow>
            <TableCell>
                <div style={{width:'120px'}}>
                    <ul className='list-inline flex-centered'>
                        { checkPermission('records','records_issue','U') || checkPermission('records','records_issue','D') ?
                            <li className='list-inline-item'>
                                <Checkbox color='primary' size='small' checked={bulkOps.ids.includes(item.id)} onChange={(e)=> onBulkToggle('single', e.target.checked, item.id)} />
                            </li>:null
                        }
                        <li className='list-inline-item'>
                            <Link to={`/${match.params.assetType}/${match.params.assetSlug}/data-room/issues/view/${item.id}/`}>
                                {checkPermission('records','records_issue','U')?
                                    <EditIcon fontSize='small' color='primary' />:
                                    <VisibilityIcon fontSize='small' color='primary' />
                                }
                            </Link>
                        </li>
                        {checkPermission('records','records_issue','D') ?
                            <li className='list-inline-item' onClick={onDelete} style={{cursor:'pointer'}}>
                                <DeleteOutlineIcon fontSize='small' color='secondary' />
                            </li>:null
                        }
                        {item.record_uuid ?
                            <li className='list-inline-item'>
                                {(() => {
                                    let fileUrl = '';
                                    if(item?.file_details?.delete_status === 1){
                                        // Checking for Trash file Access
                                        if(checkPermission('trash','trash','R')){
                                            fileUrl = `/records/preview/${item.record_uuid}?trash=true`;
                                        }
                                    }else{
                                        if(item?.file_details?.status_number === '1'){
                                            // Checking for Unpublish file Access
                                            if(checkPermission('records','data_room','UNPUB_FILE')){
                                                fileUrl = `/records/preview/${item.record_uuid}`;
                                            }
                                        }else if(item?.file_details?.status_number === '4'){
                                            // Checking for Review Dock Rejected file Access
                                            if(checkPermission('reviewdock','rejected','R')){
                                                fileUrl = `/records/preview/${item.record_uuid}`;
                                            }
                                        }else{
                                            fileUrl = `/records/preview/${item.record_uuid}`;
                                        }
                                    }
                                    return(
                                        <Link onClick={(e) => fileUrl?.trim().length ? window.open(fileUrl, "_blank"):e.preventDefault()}>
                                            <Tooltip title={`${fileUrl?.trim().length ? '':'No Access to'} File Preview: ${item?.file_details?.name||''}`} arrow>
                                                <DescriptionIcon fontSize='small' color={item?.file_details?.delete_status ? 'secondary':'primary'} />
                                            </Tooltip>
                                        </Link>
                                    )
                                })()}
                            </li>:null
                        }
                    </ul>
                </div>
            </TableCell>
            <TableCell style={{position:'relative'}}>
                <div>
                    <span style={{fontSize:'11px'}}>{item?.unique_code || ''}</span>
                    <p className="multiline-clamp" style={{width:'140px'}}> {item?.title || '--'}</p>
                </div>
            </TableCell>
            <TableCell>
                <div className='singleline-clamp' style={{width:'180px'}}>
                    <Tooltip title={item?.category?.name||''} arrow><span> {item?.category?.name||'--'}</span></Tooltip>
                </div>
            </TableCell>
            <TableCell>
                <div className='singleline-clamp' style={{width:'110px'}}>
                    <Tooltip title={item?.priority?.label||''} arrow><span> {item?.priority?.label||'None'}</span></Tooltip>
                </div>
            </TableCell>
            <TableCell>
                <Chip size='small' label={item.status ? item.status.label:'None'} style={{width:'90px',background:item.status.value === 0 ? '#4b82de': item.status.value ===1 ? '#269b2b': item.status.value === 2 ? '#e86642':'#dbcc28', color:'#fff'}} />
            </TableCell>
            <TableCell>
                <div className='singleline-clamp' style={{width:'120px'}}>
                    <Tooltip title={item?.updated_by?.name||''} arrow><span> {item?.updated_by?.name||'--'}</span></Tooltip>
                </div>
            </TableCell>
            <TableCell>
                <div className='singleline-clamp' style={{width:'110px'}}>
                    <Tooltip title={item?.assignee?.name||'None'} arrow><span> {item?.assignee?.name||'None'}</span></Tooltip>
                </div>
            </TableCell>
            <TableCell>
                <div className='singleline-clamp' style={{width:'180px'}}>{item?.started_date?moment(item.started_date).format(displayDateFormatShort):'--'}</div>
            </TableCell>

            <TableCell>
                <div className='singleline-clamp' style={{width:'110px'}}>
                    <Tooltip title={item?.created_by?.name||''} arrow><span> {item?.created_by?.name||'--'}</span></Tooltip>
                </div>
            </TableCell>
            <TableCell>
                <div className='singleline-clamp' style={{width:'110px'}}>
                    <Tooltip title={item?.part_number||''} arrow><span> {item?.part_number||'--'}</span></Tooltip>
                </div>
            </TableCell>
            <TableCell>
                <div className='singleline-clamp' style={{width:'110px'}}>
                    <Tooltip title={item?.serial_number||''} arrow><span> {item?.serial_number||'--'}</span></Tooltip>
                </div>
            </TableCell>
            <TableCell>
                <div className='singleline-clamp' style={{width:'110px'}}>
                    <Tooltip title={item?.ata||''} arrow><span> {item?.ata||'--'}</span></Tooltip>
                </div>
            </TableCell>
        </TableRow>
    )
}
const DataRoomIssues = ({match, history, location}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [filter, setFilter] = useState({});
    const [sortInfo,setSortInfo] = useState({sort:'', sort_by:''});
    const [issuesInfo, setIssuesInfo] = useState({list:[], pagination:null});
    const [addEditIssue, setAddEditIssue] = useState({modal:false, mode:'', data:null});
    const [bulkOps, setBulkOps] = useState({type:'',ids:[]});
    const [categories, setCategories] = useState([]);
    const [users, setUsers] = useState([]);
    const [createdByUsers, setCreatedByUsers] = useState([]);
    const [deleteIssue, setDeleteIssue] = useState({modal:false, data:null});
    const [deleteBulkIssue, setDeleteBulkIssue] = useState({modal:false, ids:null});
    const allAssets = useSelector(state => state?.shareReducer?.allAssets||[])
    const dispatch = useDispatch()
    useEffect(() => {
        getIssues(filter, 'skeletonLoader');
        getCategories();
        getAssignees();
        getCreatedBy();
        dispatch(getAllAssetListAc())
    },[location.pathname]);
    const getCreatedBy = () => {
        globalGetService(`records/userslist/`,{application:6,filter_created_by:'True',asset_slug:match.params.assetSlug,asset_type:match.params.assetType}).
        then(response => {
            if(checkApiStatus(response)){
                setCreatedByUsers(response.data.data);
            }
        })
    }
    const getCategories = () => {
        globalGetService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/gap-report-sections/`)
        .then(response => {
            if(checkApiStatus(response)){
                setCategories(response.data.data.list)
            }
        })
    }
    const getAssignees = () => {
        globalGetService(`records/userslist/`,{application:6})
        .then(response => {
            if(checkApiStatus(response)){
                setUsers(response.data.data);
            }
        })
    }
    const getIssues = (query={}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/issues/`, removeEmptyKey(query))
        .then(response => {
            if(checkApiStatus(response)){
                setIssuesInfo(response.data.data);
                delete query.page
                delete query.per_page
                delete query.sort
                delete query.sort_by
                setFilter(query)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const createSortHandler = (key) => {
        let sortQuery = {sort:key};
        if(sortInfo.sort === key){
            sortQuery = { ...sortQuery, sort_by:sortInfo.sort_by === 'asc'?'desc':'asc'}
        }else{
            sortQuery = { ...sortQuery, sort_by:'asc'}
        }
        setSortInfo(sortQuery)
        getIssues({...filter, ...sortQuery, page:1, per_page:issuesInfo.pagination.per_page},'pageLoader');
    }
    const onExportIssues = () => {
        enqueueSnackbar('Exporting issues...', { variant: 'info', anchorOrigin: { horizontal: 'right', vertical: 'bottom' } })
        globalExportService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/issues/`,{...filter, extension:'xlsx',download:true})
        .then(response => {
            downloadFileType(response.data, 'issues', 'xls');
            closeSnackbar();
            enqueueSnackbar('Issues exported successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
        })
    }
    const prsBulkFieldUpdate = (opsType,value) => {
        if(opsType === 'delete'){
            setDeleteBulkIssue({modal:true});
        }else{
            setLoading(true);
            let payload = Object.assign({},{item_ids:bulkOps.ids, [opsType]:value});
            issuesBulkOps(payload);
        }
    }
    const issuesBulkOps = (payload) => {
        setLoading(true);
        globalPostService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/update_multiple/`, payload)
        .then(response => {
            if(checkApiStatus(response)){   
                setBulkOps({type:'', ids:[]});
                if(payload.hasOwnProperty('delete')){
                    setDeleteBulkIssue({modal:false})
                }
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                getIssues({...filter, ...sortInfo, per_page: issuesInfo?.pagination?.per_page||20},'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
            setLoading(false);
        })
    }
    const onBulkToggle = (opsType, flag, issueId) => {
        if(opsType === 'all'){
            if(flag){
                setBulkOps({...bulkOps, ids:issuesInfo.list.map((item)=> item.id)})
            }else{
                setBulkOps({...bulkOps, ids:[]})
            }
        }else{
            if(flag){
                let tempIds = bulkOps.ids;
                tempIds.push(issueId);
                setBulkOps({...bulkOps, ids:tempIds})
            }else{
                let tempIds = bulkOps.ids;
                tempIds = tempIds.filter((id) => id !== issueId);
                setBulkOps({...bulkOps, ids:tempIds})
            }
        }   
    }
    const onDeleteIssue = () => {
        setLoading(true)
        globalDeleteService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/issues/${deleteIssue.data.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteIssue({modal:false, data:null});
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                getIssues({...filter,...sortInfo, per_page:issuesInfo?.pagination?.per_page || 20}, 'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
            setLoading(false)
        })
    }
    const changeAssetSwitcher = (asset) => {
        history.push(`/${assetTypeValues[asset.asset_type].urlType}/${asset.slug}/data-room/issues/`);
        setTimeout(() => {window.location.reload(true)}, 800) 
    }
    let filterOptions = Object.assign({}, issuesFilter);
    filterOptions = {
        ...filterOptions,
        assignee: {
            ...filterOptions.assignee,
            options:users
        },
        section: {
            ...filterOptions.section,
            options:categories
        },
        created_by:{
            ...filterOptions.created_by,
            options:createdByUsers
        }
    }
    const asset = allAssets.find(asset => asset.slug === match.params.assetSlug && asset.asset_type === revAssetTypeValues[match.params.assetType].assetType)
    return(
        <section>
         <Hidden only={['lg', 'xl']}><MobileSecondarySideNav assetinfo={asset}  /></Hidden>
            {skeletonLoader ? <STableLoader count={11} /> :
                <>
                    <div style={{marginBottom:'5px'}}>
                        <Grid container spacing={1} alignItems='center'>
                        <Hidden only={['sm','xs']}>
                            <Grid item md={6}>
                                {allAssets?.length ?
                                    <>
                                        {(() => {
                                            let asset = allAssets.find(asset => asset.slug === match.params.assetSlug && asset.asset_type === revAssetTypeValues[match.params.assetType].assetType)
                                            return(
                                                <div className='records-dataroom-modal' style={{width:'280px'}}>
                                                    <Autocomplete
                                                        options={allAssets}
                                                        getOptionLabel={option => option.unique_name}
                                                        value={asset}
                                                        disableClearable={true}
                                                        onChange={(e, value) => value ? changeAssetSwitcher(value) : e.preventDefault()}
                                                        renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                                    />
                                                </div>
                                            )
                                        })()}
                                    </>:null
                                }
                            </Grid>
                            </Hidden>
                            <Grid item xs={12} md={6}>
                                <ul className='list-inline' style={{float:'right'}}>
                                    { checkPermission('records','records_issue','C') ?
                                        <li className='list-inline-item' style={{marginRight:'5px'}}>
                                            <Button onClick={() =>setAddEditIssue({modal:true, data:addNewIssueObj})} size='small' color='primary' variant='contained'>Add Issue</Button>
                                        </li>:null
                                    }
                                    { checkPermission('records','records_issue','IMP') ?
                                        <li className='list-inline-item' style={{marginRight:'5px'}}>
                                            <ImportIssues 
                                                match={match}
                                                getResponseBack={() => getIssues({}, 'pageLoader')}
                                            />
                                        </li>:null
                                    }
                                    { checkPermission('records','records_issue','EXP') ?
                                        <li className='list-inline-item'>
                                            <Button onClick={onExportIssues} size='small' color='primary' variant='outlined'>Export Issue(s)</Button>
                                        </li>:null
                                    }
                                </ul>
                            </Grid>
                        </Grid>
                    </div>
                    <Paper style={{padding:'5px 10px', position:'relative'}} square>
                        {bulkOps?.ids?.length ?
                            <IssueBulkOps 
                                assigneeList={users}
                                prsBulkFieldUpdate={prsBulkFieldUpdate}
                                filterOptions={filterOptions}
                                onCancelBulkOps={() => setBulkOps({type:'',ids:[]})}
                            />:null
                        }
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <FilterComponent 
                                    filter={filter}
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => {getIssues({...applyFilter, ...sortInfo, page:1, per_page:issuesInfo.pagination.per_page}, 'pageLoader');}}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper>
                        <div style={{ maxHeight: window.innerHeight - 273 + 'px', overflow:'auto'}}>
                            <Table className='mui-table-format' stickyHeader>
                                <IssueHd 
                                    sortInfo={sortInfo}
                                    createSortHandler={createSortHandler}
                                    onBulkToggle={onBulkToggle}
                                    bulkFlag={bulkOps?.ids?.length && issuesInfo?.list?.length ? bulkOps?.ids?.length === issuesInfo?.list?.length :false}
                                />
                                <TableBody>
                                    {issuesInfo.list.map((item) => 
                                        <IssueList 
                                            bulkOps={bulkOps}
                                            onBulkToggle={onBulkToggle}
                                            match={match}
                                            item={item}
                                            onEdit={() => setAddEditIssue({modal:true, mode:'edit', data:item})}
                                            onDelete={() => setDeleteIssue({modal:true, data:item})}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Paper>
                        <Pagination 
                            pagination={issuesInfo.pagination}
                            onChangePage={(event, newPage) => getIssues({...filter, ...sortInfo, page:newPage+1, per_page: issuesInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getIssues({...filter,...sortInfo, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        </Paper>
                        { !issuesInfo?.list?.length ? <EmptyCollection title={'No records found'}/>:null }
                    </Paper>
                </>
            }
            { addEditIssue.modal ?
                <AddIssue 
                    addEditIssue={addEditIssue}
                    toggleModalFn={() => setAddEditIssue({ modal: false, mode: '', data: null })}
                    getResponseBack={() => getIssues({...filter,...sortInfo}, 'pageLoader')}
                />:null
            }
            { deleteIssue.modal ?
                <DeletePopUp
                    modal={deleteIssue.modal}
                    toggleModalFn={() => setDeleteIssue({ modal: false, data: null })}
                    title="Delete Issue"
                    content={<h4>Are you sure you want to delete?</h4>}
                    triggerEventFn={onDeleteIssue}
                />:null
            }
            { deleteBulkIssue.modal ?
                <DeletePopUp
                    modal={deleteBulkIssue.modal}
                    toggleModalFn={() => setDeleteBulkIssue({ modal: false, ids: null })}
                    title="Delete Issue(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    triggerEventFn={() => issuesBulkOps({item_ids:bulkOps.ids, delete:true})}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default withRouter(DataRoomIssues)
import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { globalGetService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { STableLoader } from '../../../shared_elements/loaders';
import { TableListComp, EmptyCollection, PageLoader, Pagination } from '../../../shared_elements';
import { CommentsList } from '../components';
import { commentDetailHd, commentDetaiFilterOps } from '..';
import FilterComponent from '../../../shared_elements/components/filter_component';
import { Grid, Hidden, Paper, Table, TableBody, TableCell, TableHead, TableRow, } from '@material-ui/core';
import MobileSecondarySideNav from '../components/MobileSecondarySideNav';
const CommentHd = () => {

    return (
        <TableHead>
            <TableRow>
                <TableCell>Assets</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Action</TableCell>
            </TableRow>
        </TableHead>
    )
}

function CommentDetail(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [pageLoader, setpageLoader] = useState(false);
    const [recordComment, setRecordsComment] = useState([]);
    const [assetinfo, setAssetinfo] = useState({});
    const [filter, setFilter] = useState({});
    const [expandedComment, setExpandedComment] = useState({});

    const initialStatusAnchorEl = {};
    const [statusAnchorEl, setStatusAnchorEl] = useState(initialStatusAnchorEl);

    useEffect(() => {
        getRecordsDetailApiV_2(props);
    }, []);

    const handleStatusMenuClick = (event, commentId) => {
        setStatusAnchorEl((prevStatusAnchorEl) => ({
            ...prevStatusAnchorEl,
            [commentId]: event.currentTarget,
        }));
    };
    const handleStatusMenuClose = (commentId) => {
        setStatusAnchorEl((prevStatusAnchorEl) => ({
            ...prevStatusAnchorEl,
            [commentId]: null,
        }));
    };
    const getRecordsDetailApiV_2 = (props) => {
        globalGetService(`console/${props?.match.params.assetType}/${props?.match?.params.assetSlug}/records-detail/`)
            .then((response) => {
                if (checkApiStatus(response)) {
                    if (Object.keys(response.data.data).length) {
                        setAssetinfo({ assetinfo: response.data.data });
                        getCommentList('skeletonLoader', { assetinfo: response.data.data }, filter);
                    }
                }
            });
    };
    const getCommentList = (loaderType, assetinfo, queryparams = {}) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setpageLoader(true);
        let query = Object.assign({}, queryparams);
        globalGetService(`records/comments/${assetinfo?.assetinfo?.id}/?fields=id,record_type,record_uuid,asset_id,redirect_url,comment,created_at&sort_by=created_at&sort=desc`, queryparams)
            .then((response) => {
                if (checkApiStatus(response)) {
                    setRecordsComment(response.data.data);
                    delete query.page
                    delete query.per_page
                    setFilter(query)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setpageLoader(false);
            });
    };
    const handleStatusMenuItemClick = (e, comment, status) => {
        e.preventDefault();
        globalPutService(`records/comments/${comment.id}/`, { record_type: comment.record_type, record_uuid: comment.record_uuid, status: status })
            .then((response) => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    const updatedComments = recordComment.list.map((c) => {
                        if (c.id === comment.id) {
                            return { ...c, status: status };
                        }
                        return c;
                    });
                    const updatedPagination = { ...recordComment.pagination };
                    setRecordsComment({ list: updatedComments, pagination: updatedPagination });

                    handleStatusMenuClose(comment.id);
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            });
    };

    const onChangePage = (event, newPage) => {
        getCommentList('pageLoader', { assetinfo: assetinfo.assetinfo, }, { page: newPage + 1, per_page: recordComment.pagination.per_page, ...filter });
    };

    const onChangeRowsPerPage = (event) => {
        getCommentList('pageLoader', { assetinfo: assetinfo.assetinfo }, { page: 1, per_page: event.target.value });
    };

    const toggleExpandedComment = (commentId) => {
        setExpandedComment((prevExpandedComment) => ({
            ...prevExpandedComment,
            [commentId]: !prevExpandedComment[commentId],
        }));
    };
    return (
        <section>
            <Hidden only={['lg', 'xl']}><MobileSecondarySideNav assetinfo={assetinfo} /></Hidden>
            <div>
                <div style={{ padding: '3px' }}>
                    {skeletonLoader ? <STableLoader count={5} /> :
                        <>
                            <Paper square style={{ padding: '5px 10px' }}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item md={12}>
                                      
                                                <FilterComponent
                                                    filter={filter}
                                                    filterMenu={commentDetaiFilterOps}
                                                    getResponseBack={(applyFilter) => getCommentList('pageLoader', { assetinfo: assetinfo.assetinfo }, applyFilter)}
                                                />
                                        
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper>
                                <div  style={{overflow:'auto', maxHeight: `${window.innerHeight -  220}px` }}>
                                    <Table className='mui-table-format' stickyHeader>
                                        <CommentHd />
                                        <TableBody>
                                            {recordComment?.list?.length > 0 && recordComment?.list?.map((item, index) =>
                                                <CommentsList
                                                    item={item}
                                                    index={index}
                                                    expandedComment={expandedComment}
                                                    handleStatusMenuClick={handleStatusMenuClick}
                                                    handleStatusMenuItemClick={handleStatusMenuItemClick}
                                                    statusAnchorEl={statusAnchorEl}
                                                    handleStatusMenuClose={handleStatusMenuClose}
                                                    toggleExpandedComment={toggleExpandedComment}
                                                />
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                <Paper>
                                <Pagination
                                    pagination={recordComment.pagination}
                                    onChangePage={onChangePage}
                                    onChangeRowsPerPage={onChangeRowsPerPage}
                                />
                                </Paper>
                                {!recordComment?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                            </Paper>
                        </>
                    }
                </div>
            </div>
        </section>


    );
}
export default CommentDetail
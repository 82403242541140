import React from 'react';
import BulkOpsMenu from './BulkOpsMenu';
import CloseIcon from '@material-ui/icons/Close';
import { checkPermission } from '../../../utils';
const statusOptions = [{label:'Open',value:0},{label:'Closed',value:1}, {label:'Reopen',value:2},{label:'Unresolved',value:3}];
const IssueBulkOps = ({filterOptions, assigneeList, onCancelBulkOps, prsBulkFieldUpdate}) => {
    return(
        <div className='prs-bulk-ops'>
            <ul className='list-inline flex-centered'>
                <li className='list-inline-item' onClick={onCancelBulkOps}><CloseIcon color="primary" /></li>
                {checkPermission('records','records_issue','U') ?
                    <>
                        <li className='list-inline-item'>
                            <BulkOpsMenu label="Assignee" labelKey='name' valueKey='id' options={assigneeList?assigneeList:[]} prsBulkFieldUpdate={(data) => prsBulkFieldUpdate('assignee', data)} />
                        </li>
                        <li className='list-inline-item'>
                            <BulkOpsMenu label="Status" labelKey='label' valueKey='value' options={statusOptions} prsBulkFieldUpdate={(data) => prsBulkFieldUpdate('status', data)} />
                        </li>
                        <li className='list-inline-item'>
                            <BulkOpsMenu label="Priority" labelKey='label' valueKey='value' options={filterOptions?.priority?.options || []} prsBulkFieldUpdate={(data) => prsBulkFieldUpdate('priority', data)} />
                        </li>
                        <li className='list-inline-item'>
                            <BulkOpsMenu label="Category" labelKey='name' valueKey='id' options={filterOptions?.section?.options||[]} prsBulkFieldUpdate={(data) => prsBulkFieldUpdate('category_id', data)} />
                        </li>
                    </>:null
                }
                {checkPermission('records','records_issue','D') ?
                    <li className='list-inline-item'><span className='blk-ops-menu delete-menu' onClick={()=> prsBulkFieldUpdate('delete', true)}>Delete</span></li>:null
                }
            </ul>
        </div>
    )
}
export default IssueBulkOps;
import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@material-ui/core';
import { AutocompleteCreatable, PageLoader } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { useSnackbar } from 'notistack';
import { globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';


export default function SendViaEmail({ sendViaMail, toggleModal, uuid }) {
    const [sendMailData, setSendMailData] = useState({})
    const [isLoading, setLoading] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        setSendMailData({
            ...sendMailData,
            folders_uuid: sendViaMail?.selectedItems?.filter(item => item?.type == 'folder').map(item => item.uuid),
            files_uuid: sendViaMail?.selectedItems?.filter(item => item?.type == 'file').map(item => item.uuid)
        })
    }, [])
    const onEmailChange = (key, value) => {
        if (key == 'email') {
            onEmailCheck(value)
        }
        else {
            setSendMailData({ ...sendMailData, [key]: value })
        }
    }
    const onEmailCheck = (data) => {
        let emailError = data.some(email => !regexConstants.email.test(email.inputValue ? email.inputValue : email))
        if (emailError) {
            enqueueSnackbar('Invalid Email', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        } else {
            setSendMailData({ ...sendMailData, emails: data.map(item => item?.inputValue ? item.inputValue :item) })
        }
    }
    const submitMailDataFn = () => {
        if (sendMailData?.emails?.length) {
            setLoading(true)
            globalPostService(`records/v2/${sendViaMail?.params?.assetType}/${sendViaMail?.params?.assetSlug}/send-records/`, sendMailData)
                .then(response => {
                    setLoading(false)
                    toggleModal();
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    }
                })
        }
        else {
            enqueueSnackbar('Please enter an Email adderss', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
    }
    return (
        <div>
            {isLoading ? <PageLoader /> : null}
            <Dialog
                open={sendViaMail?.flag}
                onClose={() => toggleModal()}
                aria-labelledby="scroll-dialog-title"
                fullWidth='true'
            >
                <DialogTitle id="scroll-dialog-title">
                    Share via Email
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AutocompleteCreatable
                                placeholder="Enter the email ids"
                                options={[]}
                                value={sendMailData?.emails || []}
                                paramsKey='email'
                                optionKey="value"
                                required={true}
                                multiple={true}
                                label="With People"
                                onFieldChange={(paramsKey, emails) => onEmailChange('email', emails)}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                id="message"
                                label="Message"
                                variant='outlined'
                                fullWidth
                                margin="normal"
                                value={sendMailData?.message || ''}
                                multiline
                                rows="4"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e, value) => onEmailChange('message', e.target.value)}
                            />
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => toggleModal()}>Cancel</Button>
                    <Button disabled={sendMailData?.emails?.length ? false : true} variant="contained" onClick={submitMailDataFn} color="primary">Send</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

import { globalGetService, globalPostService, globalFileUploadService, globalStaticGetService } from '../utils/globalApiServices';
import * as actions from './actions';
import { checkApiStatus } from '../utils';
import { trackActivity } from '../utils/mixpanel';
export const getStorageSassUsageAc = (props) => {
  return (dispatch) => {
    globalGetService(`console/license/storage/usage/`)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.UPDATE_STORAGE_USAGR,
            payload: response.data.data
          })
        }
      })
  }
}
export const getRecordsReposAc = (props) => {
  return (dispatch) => {
    globalGetService(`console/${props.match.params.assetType}/${props.match.params.assetSlug}/records-detail/`)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.RECORDS_REPOS,
            payload: response.data.data
          })
        }
      })
  }
}

export const getAllAssetListAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/records-asset-list/`, { switcher: true, all_assets: true })
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.ALL_ASSETS,
            payload: response.data.data.asset
          })
        }
      })
  }
}
export const getRegionListAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/regions/`, query)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.REGION_LIST,
            payload: response.data.data.region_list
          })
        }
      })
  }
}
export const getLessorListAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/lessors/`, query)
      .then(response => {
        if (checkApiStatus(response)) {

        }
      })
  }
}
export const getLesseeListAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/lessees/`, query)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.LESSEE_LIST,
            payload: response.data.data.lessees
          })
        }
      })
  }
}
export const getOperatorListAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/operators/`, query)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.OPERATOR_LIST,
            payload: response.data.data.operators
          })
        }
      })
  }
}

export const getPlatformConstantsAc = (data) => {
  return (dispatch) => {
    globalPostService(`console/get-constants/`, data)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.PLATFORM_CONTANTS,
            payload: response.data.data
          })
        }
      })
  }
}

export const getEngineTypesAc = (query = {}) => {
  return (dispatch) => {
    globalGetService(`console/engine-types/`, query)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.ENGINE_TYPES,
            payload: response.data.data.engineTypes.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
          })
        }
      })
  }
}
export const getAircraftTypesAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/aircraft-types/`, query)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.AIRCRAFT_TYPES,
            payload: response.data.data.aircraftTypes.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
          })
        }
      })
  }
}
export const getAircraftMntProgramAc = (aircraftId) => {
  return (dispatch) => {
    globalGetService(`console/aircraft-type/${aircraftId}/events/`)
      .then(response => {
        if (checkApiStatus(response)) {
          let maintanenceList = [];
          const data = response.data.data;
          Object.keys(data).map((item, index) => {
            maintanenceList.push({ label: data[item].replace(/,/g, '/') + '', value: item })
          });
          dispatch({
            type: actions.AIRCRAFT_MNT_GRP,
            payload: maintanenceList
          })
        }
      })
  }
}
export const getAPUTypesAc = (query = {}) => {
  return (dispatch) => {
    globalGetService(`console/apu-types/`, query)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.APU_TYPES,
            payload: response.data.data.apu_types.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
          })
        }
      })
  }
}
export const getPortfolioListAc = (query = {}) => {
  return (dispatch) => {
    globalGetService(`console/portfolios/`, { status: 3 })
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.PORTFOLIO_LIST,
            payload: response.data.data.map(item => { return { id: item.id, name: item.name } }).sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
          })
        }
      })
  }
}
export const globalRecordsSearchAc = (props, queryParams = {}) => {
  return (dispatch) => {
    if (queryParams.page === 1) {
      dispatch({
        type: actions.SEARCH_LOADER,
        payload: true
      });
    }
      dispatch({
        type:actions.LOAD_MORE,
        payload: true
      })
    props.history.push(`/data-room/search?q=${queryParams.q}`);
    trackActivity('Item Searched', { page_title: 'Global Search', search_key: queryParams.q })
    globalGetService(`records/v2/search/`, queryParams)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.RECORDS_SEARCH_RESULT,
            queryParams: queryParams,
            payload: response.data.data
          });
          trackActivity('Item Searched', { page_title: 'Global Search Advance Filter', filter_applied: queryParams })
        }
        dispatch({
          type: actions.SEARCH_LOADER,
          payload: false
        });
        dispatch({
          type: actions.LOAD_MORE,
          payload: false
        });
      })
  }
}

export const globalFilterRecordsSearchAc = (props, queryParams = {}) => {
  return (dispatch) => {
    globalGetService(`records/v2/get-filter-details/`, queryParams)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.RECORDS_SEARCH_FILTER,
            payload: response.data.data
          })
        }
      })
  }
}
export const filteredAircraftTypeListAc = (query) => {
  return (dispatch) => {
    globalGetService('console/aircraft-types/', query)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.FILTERED_AIRCRAFT_TYPE_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const filteredEngTypeListAc = (query) => {
  return (dispatch) => {
    globalGetService('console/engine-types/', query)
    .then(response => {
      dispatch({
        type: actions.FILTERED_ENGINE_TYPE_LIST,
        payload: response.data.data
      })
    })
  }
}
// export const filteredApuTypeListAc = (query) => {
//   return (dispatch) => {
//     globalGetService('console/apu-types/', query)
//     .then(response => {
//       dispatch({
//         type: actions.FILTERED_APU_TYPE_LIST,
//         payload: response.data.data
//       })
//     })
//   }
// }

import React, { useState } from 'react'
import { withRouter } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Chip, Divider} from '@material-ui/core';
import { EmptyCollection, Pagination, PageLoader } from '../../../shared_elements'
import { globalGetService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
const DMTagsPreview = ({match}) => {
    const [isFetching, setFetching] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [tagsInfo, setTagsInfo] = useState([])
    const [modal, setModal] = useState(false);
    const [tagId, setTagId] = useState('');
    const [previewInfo, setPreviewInfo] = useState({list:[], pagination:null});
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        getTagsInfo();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const getTagsInfo = (query={}) => {
        setLoading(true);
        globalGetService(`records/tags/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setTagsInfo(response.data.data);
            }
            setLoading(false);
        })
    }
    const onTagsSearch = (value) => {
        setFilter({...filter,'name': value});
        getTagsInfo({name:value}, 'pageLoader')
    }
    const onPreviewData = (query={}) => {
        setFetching(true)
        globalGetService(`records/tags/`,{with_records:true,asset_type:match.params.assetType, asset_slug:match.params.assetSlug, ...query})
        .then(response => {
            if(checkApiStatus(response)){
                setModal(true); setTagId(query.tag_ids);
                setPreviewInfo(response.data.data);
            }
            setFetching(false)
        })
    }
    return(
        <>
            <Button size='small' variant='contained' color='primary' aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} endIcon={<ArrowDropDownIcon fontSize='small' />}>
                Tag(s)
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {!isLoading ?
                    <div>
                        {(() => {
                            let tagsOptions = filter?.name?.trim()?.length ? tagsInfo.filter((tag) => tag.name.includes(filter.name)):tagsInfo;
                            return(
                                <div className='records-dataroom-modal'>
                                    <div style={{padding:'4px'}}>
                                        <TextField 
                                            placeholder='Search by Name'
                                            id='title'
                                            value={filter?.name||''}
                                            fullWidth
                                            margin="none"
                                            onChange={(e) => setFilter({...filter,name:e.target.value})}
                                            InputLabelProps={{shrink: true}}
                                            variant='outlined'
                                            inputProps={{ maxLength: 100 }}
                                        />
                                    </div>
                                    {!tagsOptions.length ? <p style={{textAlign:'center', padding:'0 5px'}}>No records found</p>:null}
                                    <div style={{maxHeight:'420px', overflow:'auto'}}>
                                        {tagsOptions.map((item, index) =>
                                            <>
                                                <MenuItem key={index} onClick={() => {handleClose();onPreviewData({tag_ids:item.id})}}>{item.name}</MenuItem>
                                                <Divider/>
                                            </>
                                        )}
                                    </div>
                                    
                                </div>
                            )
                        })()}
                    </div>:<MenuItem>Loading...</MenuItem>
                }
            </Menu>

            { modal ?
                <Dialog
                    open={modal}
                    onClose={()=> {setModal(false); setTagId(''); setPreviewInfo({list:[], pagination:null})}}
                    className='records-dataroom-modal'
                    maxWidth= 'md'
                >
                    <DialogTitle id="scroll-dialog-title"> Preview Data </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{width:'820px'}}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <div style={{overflow:'auto', height:'480px', padding:'0 8px'}}>
                                        <Table className='mui-table-format' stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align='right'>Tags</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { previewInfo.list.map((item, index) =>
                                                    <TableRow>
                                                        <TableCell>
                                                            <div>
                                                                <p onClick={() => item?.record?.type === 'folder' ? window.open(`/${item.record.asset_details.asset_type}/${item.record.asset_details.asset_slug}/data-room/${item.record.category_slug}/${item.record.uuid}`, '_blank'): window.open(`/records/preview/${item.record.uuid}`, '_blank')} style={{cursor:'pointer'}} className='flex-centered'> {item?.record?.type === 'folder' ? <FolderIcon fontSize='small' color='primary' />:<DescriptionIcon fontSize='small' color='primary' />} <span style={{fontSize:'15px', paddingLeft:'5px', color:'#3f51b5'}}>{item?.record?.name||''}</span></p>
                                                            </div>
                                                            <p style={{fontSize:'13px'}}>{item?.record?.location||''}</p>
                                                        </TableCell>
                                                        <TableCell align='right' style={{width:'320px'}}>
                                                            <div style={{width:'320px'}}>
                                                                {item?.record?.tags?.length ?
                                                                    item.record.tags.map((tag, index)=>
                                                                        <Chip key={index} style={{margin:'0 2px 2px 0'}} color={tag.id === tagId ? 'primary':'default'} label={tag.name} size='small' />
                                                                    )
                                                                    :null
                                                                }
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                        {!previewInfo?.list?.length ? <div style={{textAlign:'center'}}><EmptyCollection title='No records found' /></div>:null}
                                    </div>
                                    <Pagination
                                        pagination={previewInfo.pagination}
                                        onChangePage={(event, newPage) => onPreviewData({page:newPage+1, per_page: previewInfo.pagination.per_page, tag_ids:tagId}, 'pageLoader')}
                                        onChangeRowsPerPage={(event) => onPreviewData({page:1, per_page: event.target.value, tag_ids:tagId}, 'pageLoader')}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=> {setModal(false); setTagId(''); setPreviewInfo({list:[], pagination:null})}} color="primary" variant="text">Close</Button>
                    </DialogActions>
                </Dialog>:null
            }
            { isFetching ? <PageLoader/>:null }
        </>
    )
}
export default withRouter(DMTagsPreview);
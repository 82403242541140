import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { getFileSize, getFileIcon, localTimeFn } from '../../../utils';
import { TableRow, TableCell, Tooltip, Avatar } from '@material-ui/core';
const UnOrgansiedList = ({item, fileIcons, contextMenu}) => {
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell onClick={() => window.open(`/records/preview/${item.uuid}`, '_blank')}><img style={{width:'20px', marginRight:'10px'}} src={getFileIcon(fileIcons, item.extension)} alt="icon" />{item.name}</TableCell>
      <TableCell>{getFileSize(item.size)}</TableCell>
      <TableCell>
        <div className="flex-centered" >
          <Avatar alt="Vijay Kumar" src={item.created_by.profile_pic} />
          <div style={{marginLeft:'8px'}}>
            <h5>{item.created_by.name}</h5>
            <p>{localTimeFn(item.created)}</p>
          </div>
        </div>
      </TableCell>
      <TableCell>{localTimeFn(item.modified)}</TableCell>
      <TableCell>
        {contextMenu}
      </TableCell>
    </TableRow>
  )
}
export default UnOrgansiedList;

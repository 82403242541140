import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Paper, TextField, Table, TableBody, TableCell, TableHead, TableRow, Tooltip ,Chip, CircularProgress} from '@material-ui/core';
import { DeletePopUp, EmptyCollection, Pagination, PageLoader } from '../../../shared_elements'
import { STableLoader } from '../../../shared_elements/loaders';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus, checkPermission } from '../../../utils';
import { revAssetTypeValues } from '../../../constants'
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
const PreviewTaggedData = ({item}) => {
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [previewInfo, setPreviewInfo] = useState({list:[], pagination:null});
    const onPreviewData = (query={}) => {
        setLoading(true)
        globalGetService(`records/tags/`,{with_records:true,tag_ids:item.id, ...query})
        .then(response => {
            if(checkApiStatus(response)){
                setModal(true)
                setPreviewInfo(response.data.data);
            }
            setLoading(false)
        })
    }
    return(
        <>
            <Tooltip title='Preview Data' arrow><VisibilityIcon onClick={() => onPreviewData()} fontSize='small' color='primary' /></Tooltip>
            {modal ?
                <Dialog
                    open={modal}
                    onClose={()=> setModal(false)}
                    className='records-dataroom-modal'
                    maxWidth= 'md'
                >
                    <DialogTitle id="scroll-dialog-title">
                        Preview Data
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{width:'820px'}}>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <div style={{overflow:'auto', height:'480px', padding:'0 8px'}}>
                                        <Table className='mui-table-format' stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align='right'>Tags</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { previewInfo.list.map((previewData, index) =>
                                                    <TableRow>
                                                        <TableCell>
                                                            <div>
                                                                <p onClick={() => previewData?.record?.type === 'folder' ? window.open(`/${previewData.record.asset_details.asset_type}/${previewData.record.asset_details.asset_slug}/data-room/${previewData.record.category_slug}/${previewData.record.uuid}`, '_blank'): window.open(`/records/preview/${previewData.record.uuid}`, '_blank')} style={{cursor:'pointer'}} className='flex-centered'> {previewData?.record?.type === 'folder' ? <FolderIcon fontSize='small' color='primary' />:<DescriptionIcon fontSize='small' color='primary' />} <span style={{fontSize:'15px', paddingLeft:'5px', color:'#3f51b5'}}>{previewData?.record?.name||''}</span></p>
                                                                <p style={{fontSize:'13px'}}>
                                                                    <b>{revAssetTypeValues[previewData?.record?.asset_details.asset_type].label}</b>
                                                                    <b style={{paddingLeft:'2px'}}>{previewData?.record?.asset_details?.asset_name||''}</b>/{previewData?.record?.location||''}
                                                                </p>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="right" style={{width:'320px'}}>
                                                            <div style={{width:'320px'}}>
                                                                {previewData?.record?.tags?.length ?
                                                                    previewData.record.tags.map((tag, index)=>
                                                                        <Chip style={{margin:'0 2px 2px 0'}} color={tag.id === item.id ? 'primary':'default'} label={tag.name} size='small' />
                                                                    )
                                                                    :null
                                                                }
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                        {!previewInfo?.list?.length ? <div style={{textAlign:'center'}}><EmptyCollection title='No records found' /></div>:null}
                                    </div>
                                    <Pagination
                                        pagination={previewInfo.pagination}
                                        onChangePage={(event, newPage) => onPreviewData({page:newPage+1, per_page: previewInfo.pagination.per_page}, 'pageLoader')}
                                        onChangeRowsPerPage={(event) => onPreviewData({page:1, per_page: event.target.value}, 'pageLoader')}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=> setModal(false)} color="primary" variant="text">Close</Button>
                    </DialogActions>
                </Dialog>:null
            }
            {isLoading ? <PageLoader/>:null }
        </>
    )
}
const AddEditTag = ({addEditTag, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [tag, setTag] = useState(addEditTag.data);
    const [error, setError] = useState({});
    const onAddEditTag = () => {
        if(tag?.name?.trim()?.length){
            setLoading(true);
            if(tag.id){
                globalPutService(`records/tags/${tag.id}/`, {name:tag.name})
                .then(response => {
                    if(checkApiStatus(response)){
                        toggleModalFn();getResponseBack();
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                    setLoading(false);
                })
            }else{
                globalPostService(``)
                .then(response => {
                    if(checkApiStatus(response)){
                        toggleModalFn();getResponseBack();
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                    setLoading(false);
                })
            }
        }else{
            setError({name:'Please enter Name'})
        }
    }
    return(
        <Dialog
            open={addEditTag.modal}
            onClose={toggleModalFn}
            className='records-dataroom-modal'
            maxWidth= 'md'
        >
            <DialogTitle id="scroll-dialog-title">
                {tag.id ? 'Edit': 'Add'} Tag
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{width:'420px'}}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <TextField 
                                required
                                name='tag'
                                label='Tag'
                                value={tag?.name||''}
                                fullWidth
                                margin="normal"
                                error={error.name}
                                helperText={error?.name||''}
                                onChange={(e) => setTag({...tag,'name': e.target.value})}
                                InputLabelProps={{shrink: true}}
                                variant='outlined'
                                onFocus={() => setError({...error,name:''})}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" variant="text">CANCEL</Button>
                <Button disabled={isLoading} onClick={onAddEditTag} color="primary" variant="contained">
                        {isLoading ? <CircularProgress size={24} />:'SAVE'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const Tags = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [tagsInfo, setTagsInfo] = useState({list:[], pagination:null})
    const [addEditTag, setAddEditTag] = useState({modal:false, data:null})
    const [deleteTags, setDeleteTags] = useState({modal:false, data:null})
    useEffect(() => {
        getTagsInfo({page:1,per_page:20}, 'skeletonLoader');
    },[]);
    const getTagsInfo = (query={}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`records/tags/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setTagsInfo(response.data.data);
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const onTagsSearch = (value) => {
        setFilter({...filter,'name': value});
        getTagsInfo({name:value, page:1, per_page: tagsInfo?.pagination?.per_page||20}, 'pageLoader')
    }
    const onDeleteTags = () => {
        setLoading(true);
        globalDeleteService(`records/tags/${deleteTags.data.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteTags({modal:false, data:null})
                getTagsInfo({...filter, page:tagsInfo?.pagination?.page||1, per_page:tagsInfo?.pagination?.per_page||20}, 'pageLoader');
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
            setLoading(false);
        })
    }
    return(
        <section className="records-templates">
            {skeletonLoader ? <STableLoader count={3} />:
                <>
                    <Paper style={{padding:'5px 10px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={9}>
                                <form className='records-dataroom-modal'>
                                    <TextField 
                                        placeholder='Search tags by Name'
                                        name='tag'
                                        value={filter?.name||''}
                                        fullWidth
                                        margin="none"
                                        onChange={(e) => onTagsSearch(e.target.value)}
                                        InputLabelProps={{shrink: true}}
                                        variant='outlined'
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </form>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper>
                        <div style={{overflow:'auto'}}>
                            <Table className='mui-table-format'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align='right'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { tagsInfo?.list.map((item, index) =>
                                        <TableRow>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell align='right'>
                                                <ul className='list-inline'>
                                                    <li className='list-inline-item' style={{cursor:'pointer'}}>
                                                        <PreviewTaggedData item={item} />
                                                    </li>
                                                    { checkPermission('records','tags','U') ?
                                                        <li className='list-inline-item' onClick={() => setAddEditTag({modal:true, data:item})} style={{cursor:'pointer'}}>
                                                            <Tooltip title='Edit Tag' arrow><EditIcon fontSize='small' color='primary' /></Tooltip>
                                                        </li>:null
                                                    }
                                                    { checkPermission('records','tags','D') ?
                                                        <li className='list-inline-item' onClick={() => setDeleteTags({modal:true, data:item})} style={{cursor:'pointer'}}>
                                                            <Tooltip title='Delete Tag' arrow><DeleteOutlineIcon fontSize='small' color='secondary' /></Tooltip>
                                                        </li>:null
                                                    }
                                                </ul>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {!tagsInfo?.list?.length ? <EmptyCollection title='No records found' />:null}
                                </TableBody>
                            </Table>
                            <Pagination 
                                pagination={tagsInfo.pagination}
                                onChangePage={(event, newPage) => getTagsInfo({...filter,  page:newPage+1, per_page: tagsInfo.pagination.per_page}, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getTagsInfo({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                            />
                        </div>
                    </Paper>
                </>
            }
            { addEditTag.modal ? 
                <AddEditTag 
                    addEditTag={addEditTag}
                    toggleModalFn={() => setAddEditTag({modal:false, data:null})}
                    getResponseBack={() => getTagsInfo({...filter, page:tagsInfo.pagination.page||1, per_page:tagsInfo.pagination.per_page||20}, 'pageLoader')}
                />:null
            }
            { deleteTags.modal ?
                <DeletePopUp
                    modal={deleteTags.modal}
                    toggleModalFn={() => setDeleteTags({ modal: false, data: null })}
                    title="Delete Tag"
                    content={<h4>Are you sure you want to delete?</h4>}
                    triggerEventFn={onDeleteTags}
                />:null
            }
            { isLoading ? <PageLoader/>:null }
        </section>
    )
}
export default Tags;
import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Paper, Table, TableCell, TableHead, TableBody, TableRow, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress, Menu, MenuItem, Chip, Tooltip } from '@material-ui/core';
import { globalDeleteService, globalGetService, globalOpenPostService, globalPostService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus, checkPermission, getLocalStorageInfo } from '../../../utils';
import {DropzoneArea} from 'material-ui-dropzone';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DeletePopUp, EmptyCollection, PageLoader, Pagination } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
const SampleTemplateDownLoad = ({}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const downloadTemplate = (type) => {
        if(type === 'section'){
            window.open('https://sparta-aims.ams3.cdn.digitaloceanspaces.com/media/folder_structure/Without%20Sub-Section.xlsx')
        }else{
            window.open(`https://sparta-aims.ams3.cdn.digitaloceanspaces.com/media/folder_structure/With%20Sub-Section.xlsx`)
        }
    }
    return(
        <>
            <p style={{textAlign:'right'}}>
                <span>
                    Refer the sample template
                </span>
                <Button style={{fontFamily:'"Conv_IBMPlexSans-Bold"', fontWeight:'700'}} size='small' color='primary' variant='text' aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} endIcon={<ArrowDropDownIcon fontSize='small' />}>
                    Download
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={(e) =>{ handleClose(); downloadTemplate('section')}}>Without Sub-Section</MenuItem>
                    <MenuItem onClick={(e) => {handleClose(); downloadTemplate()}}>With Sub-Section</MenuItem>
                </Menu>
            </p>
        </>
    )
}
const AddEditCheckList = ({addEditCheckList, getResponseBack, toggleModalFn}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [checkList, setCheckList] = useState(addEditCheckList.data);
    const [error, setError] = useState({})
    const onAddEditCheckList = () => {
        const validationInputs = {
            name:checkList?.name?.trim()?.length ? '':'Please enter Name',
            files:checkList?.id ? '':checkList?.files?.length ? '':'Please upload File'
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            var formData = new FormData();
            formData.append('name', checkList.name)
            formData.append('description', checkList.description);
            if(checkList?.files?.length){
                formData.append('file', checkList.files[0])
            }
            if(checkList.id){
                globalPutService(`records/checklist-template/${checkList.slug}/`,formData)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn();getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error', autoHideDuration: 10000, anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
            }else{
                globalPostService(`records/checklist-template/`, formData)
                .then(response => {
                    if(checkApiStatus(response)){
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                        toggleModalFn();getResponseBack();
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error', autoHideDuration: 10000, anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false);
                })
            }
            
        }else{
            setError(validationInputs)
        }
    }
    return(
        <Dialog
            open={addEditCheckList.modal}
            onClose={toggleModalFn}
            className='records-dataroom-modal'
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title"> {checkList?.id ? 'Edit':'Add'} Template </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            required
                            label="Name"
                            name="name"
                            fullWidth
                            margin="normal"
                            value={checkList?.name||''}
                            error={error?.name}
                            helperText={error?.name ||''}
                            inputProps={{ maxLength: 250}}
                            onChange={(e) => setCheckList({...checkList,name:e.target.value})}
                            onFocus={() => setError({...error, name:''})}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            label="Description"
                            name="description"
                            fullWidth
                            margin="normal"
                            multiline={true}
                            rows={3}
                            maxRows={3}
                            value={checkList?.description||''}
                            onChange={(e) => setCheckList({...checkList,description:e.target.value})}
                            InputLabelProps={{shrink: true}}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Grid container spacing={0} alignItems='bottom'>
                            <Grid item md={6}>
                                <p style={{fontFamily:'"Conv_IBMPlexSans-Medium"', fontSize:'11px'}}>Attachment<sup>*</sup></p>
                            </Grid>
                            <Grid item md={6}>
                                <SampleTemplateDownLoad />
                            </Grid>
                        </Grid>
                        
                        <div className="dropzone-area">
                            <DropzoneArea
                                filesLimit={1}
                                acceptedFiles={['.xlsx','.xls']}
                                maxFileSize={1000000}
                                showPreviewsInDropzone={false}
                                showPreviews={true}
                                useChipsForPreview={true}
                                dropzoneText={<p>Drag & Drop files<br/> OR <br/> Click Here</p>}
                                dropzoneClass="drag-drop-cnt"
                                maxWidth="sm"
                                showAlerts={['error', 'info']}
                                alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                onDrop={(files) => {setCheckList({...checkList, files:files}); setError({...error, files:''})}}
                            />
                            <p className="file-upload-note">Only Excel File is supported</p>
                            {error.files ? <p className='error-msg'>{error.files}</p>:null }                            
                        </div>
                        {checkList?.id && !checkList.files?.length && checkPermission('templates','templates','DN') ?
                            <Chip onClick={() => window.open(checkList.file_path,'download')} size='small' variant='outlined' label={checkList.file_name} color='primary' />:null
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} disabled={isLoading} size='small' color="primary" variant='outlined'>Cancel</Button>
                <Button onClick={onAddEditCheckList} disabled={isLoading} size='small' color="primary" variant='contained'>
                    {isLoading ? <CircularProgress size={24} />:'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const CheckListHD = ({}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Updated By</TableCell>
                <TableCell align='right'>Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
const CheckListItem = ({item, onEdit, onDelete, activityTrack}) => {
    return(
        <TableRow>
            <TableCell>
                <div style={{width:'180px'}}>{item?.name||'--'}</div>
            </TableCell>
            <TableCell>
                <div style={{maxWidth:'520px'}}>
                    {item?.description||'--'}
                </div>
            </TableCell>
            <TableCell>
                <div style={{width:'120px'}}>{item?.created_by?.name||''}</div></TableCell>
            <TableCell>
                <div style={{width:'120px'}}>{item?.updated_by?.name||'--'}</div>
            </TableCell>
            <TableCell align='right' style={{width:'80px'}}>
                <ul className='list-inline mobile-cta' style={{float:'right'}}>
                    { item.file_path && checkPermission('templates','templates','DN') ?
                        <li className='list-inline-item' style={{cursor:'pointer'}}>
                            <Tooltip title={`Download Attachment ${item.file_name}`}>
                                <GetAppIcon onClick={() =>{
                                    activityTrack()
                                    window.open(item.file_path, 'download')} 
                                } 
                                    fontSize='small' color='primary' />
                            </Tooltip>
                        </li>:null
                    }
                    { checkPermission('templates','templates','U') ?
                        <li className='list-inline-item' style={{cursor:'pointer'}}>
                            <EditIcon onClick={onEdit} fontSize='small' color='primary' />
                        </li>:null
                    }
                    { checkPermission('templates','templates','D') ?
                        <li className='list-inline-item' style={{cursor:'pointer'}}>
                            <DeleteOutlineIcon onClick={onDelete} fontSize='small' color='secondary' />
                        </li>:null
                    }
                </ul>
            </TableCell>
        </TableRow>
    )
}
const CheckListTemplate = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [checkListInfo, setCheckListInfo] = useState({list:[], pagination:null});
    const [filter, setFilter] = useState({});
    const [addEditCheckList, setAddEditCheckList] = useState({modal:false, mode:'', data:null})
    const [deleteCheckList, setDeleteCheckList] = useState({modal:false, data:null});
    useEffect(() => {
        getCheckListInfo(filter,'skeletonLoader');
    },[])
    const getCheckListInfo = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true)
        globalGetService(`records/checklist-template/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setCheckListInfo(response.data.data);
                delete query.page
                delete query.per_page
                delete query.sort
                delete query.sort_by
                setFilter(query)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false)
        })
    }
    const onDeleteCheckList = () => {
        globalDeleteService(`records/checklist-template/${deleteCheckList.data.slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                setDeleteCheckList({modal:false, data:null})
                getCheckListInfo({...filter, per_page: checkListInfo.pagination.per_page}, 'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
            }
        })
    }
    const  shareLinkActivityApi =( shareKey, activityId, userId,itemId, itemName ) =>{
        globalOpenPostService(`records/v2/share/activity/`, {item_id:itemId, item_name:itemName, user_id: userId, share_link: false, activity_type: activityId, details: shareKey })
          .then(response => {
            if (checkApiStatus(response)) {
      
            }
          })
      }
      const userId = getLocalStorageInfo()?.user?.id
    return(
        <section>
            { skeletonLoader ? <STableLoader count={4} />:
                <>
                    { checkPermission('templates','templates','C') ?
                        <Paper square style={{padding:'5px 10px'}}>
                            <Grid container spacing={1}>
                                <Grid item md={9} sm={9} xs={6}></Grid>
                                <Grid item md={3} sm={3} xs={6}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        <li className='list-inline-item'>
                                            <Button onClick={() =>setAddEditCheckList({modal:true, mode:'add', data:{name:'', description:'', files:[]}})} color='primary' size='small' variant='contained'>Add Template</Button>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>:null
                    }
                    <Paper>
                        <div style={{overflow:'auto'}}>
                            <Table className='mui-table-format'>
                                <CheckListHD />
                                <TableBody>
                                    {checkListInfo.list.map((item, index) => 
                                        <CheckListItem 
                                            userId={userId}
                                            activityTrack={()=>shareLinkActivityApi('Downloaded', 48, userId, item?.id, item?.name)}
                                            item={item}
                                            onEdit={() => setAddEditCheckList({modal:true, mode:'edit', data:item})}
                                            onDelete={() => setDeleteCheckList({modal:true, data:item})}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <Pagination 
                            pagination={checkListInfo.pagination}
                            onChangePage={(event, newPage) => getCheckListInfo({...filter, page:newPage+1, per_page: checkListInfo?.pagination?.per_page||20}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getCheckListInfo({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        { !checkListInfo?.list?.length ? <EmptyCollection title='No records found'/>:null} 
                    </Paper>
                </>
            }
            
            { deleteCheckList.modal ?
                <DeletePopUp
                    modal={deleteCheckList.modal}
                    toggleModalFn={() => setDeleteCheckList({modal:false, data:null})}
                    title="Delete Template"
                    content={<h4>Are you sure you want to delete?</h4>}
                    triggerEventFn={onDeleteCheckList}
                />:null
            }
            { addEditCheckList.modal ?
                <AddEditCheckList 
                    addEditCheckList={addEditCheckList}
                    getResponseBack={() => getCheckListInfo({...filter}, 'pageLoader')}
                    toggleModalFn={() => setAddEditCheckList({modal:false, mode:'', data:null})}
                />:null
            }
            { isLoading ? <PageLoader />:null}
        </section>
    )
}
export default CheckListTemplate;
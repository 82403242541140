import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Grid, Breadcrumbs, Link, Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from '@material-ui/core';
import { checkApiStatus } from '../../../utils';
import { PageLoader } from '../../../shared_elements';
import { globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
const ChecklistFileLinking = ({match, fileLinkInfo, toggleModalFn, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [checkListData, setCheckListData] = useState({})
  const [selectedFiles, setSelectedFiles] = useState([]);
  useEffect(() => {
    getCheckListLinkData(fileLinkInfo.data.uuid)
  },[]);
  const getCheckListLinkData = (uuid) => {
    setLoading(true);
    globalGetService(`records/v2/${match.params.assetType}/${match.params.assetSlug}/data-room/${uuid}/`, {lite:1, fields:'id,name,uuid,type'})
    .then(response => {
      if(checkApiStatus(response)){
        setCheckListData(response.data.data);
      }
      setLoading(false);
    })
  }
  const onAddRemoveFile = (flag, file) => {
    if(flag){
      setSelectedFiles([...selectedFiles, file]);
    }else{
      setSelectedFiles(selectedFiles.filter(item => item.id !== file.id));
    }
  }
  const onSubmitFileLink = () => {
    let payload = {
      checklist_id:fileLinkInfo.data.id,
      file_uuid: selectedFiles.map(file => file.uuid),
    };
    globalPostService(`records/checklist-linking/`, payload)
    .then(response => {
      if(checkApiStatus(response)){
        toggleModalFn();
        getResponseBack(response.data.data);
        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
      }else{
        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
      }
    });
  }
  return(
    <Fragment>
      <Dialog
        open={fileLinkInfo.modal}
        onClose={() => toggleModalFn()}
        className='project-management-modal'
        maxWidth='lg'
      >
        <DialogTitle id="scroll-dialog-title">Link File(s)</DialogTitle>
        <DialogContent dividers={true}>
          <div  className='checklist-file-linking file-link-width'>
            <Grid container spacing={1}>
              <Grid item xs={12} md={7}>
                <div className='linking-navigation'>
                  <Breadcrumbs maxItems={3} aria-label="breadcrumb">
                    {checkListData?.navigation?.length && checkListData.navigation.map((nav, index) =>
                      checkListData.navigation.length - 1 == index ?
                        <Link onClick={() => getCheckListLinkData(nav.uuid)} className="list-inline-item navigation_modal">{nav.name}</Link>
                        :
                        <Link onClick={() => getCheckListLinkData(nav.uuid)} className="list-inline-item navigation_modal">{nav.name}</Link>
                    )}
                  </Breadcrumbs>
                </div>
                <Grid container spacing={1}> 
                    {checkListData?.list?.length ?   
                        <>
                            {checkListData.list.map((item, index) => 
                                <>
                                    {item.type === 'folder' ?
                                        <Grid item xs={12} md={12}>
                                            <h4 className='flex-centered' onClick={() => getCheckListLinkData(item.uuid)}>
                                                <FolderOpenIcon color='primary' fontSize='small' /> <span>{item.name}</span>
                                            </h4>
                                        </Grid>:
                                        <Grid item xs={12} md={12}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={10} md={10}>
                                                    <h4 className='flex-centered'>
                                                        <InsertDriveFileIcon color='primary' fontSize='small' /> 
                                                        <span>{item.name}</span>
                                                    </h4>
                                                    <p className='file-location'>{item.location}</p>
                                                </Grid>
                                                <Grid item xs={2} md={2}>
                                                    <Checkbox 
                                                        checked ={selectedFiles.map(file => file.id).includes(item.id)?true:false}
                                                        onChange={(e) => onAddRemoveFile(e.target.checked, item)}
                                                        color='primary' 
                                                        size='small'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </>
                            )}   
                        </>
                        :
                        <Grid item xs={12} md={12}>
                            <p style={{textAlign:'center'}}>No folder / file(s) found</p>
                        </Grid>
                    }
                </Grid>
              </Grid>
              <Grid item md={5} xs={12} style={{borderLeft: '1px solid #d7d7d7'}}>
                <div className='selected-files'>
                    <h3>Selected File's ({selectedFiles.length})</h3>
                    {selectedFiles.length ?
                      <div className='selected-files-blk'>
                        {selectedFiles.map((file, index) => 
                          <div className='file-list' key={index}>
                            <div>
                              <h4><span>{file.name}</span></h4>
                              <p>{file.location}</p>
                            </div>
                            <span onClick={() => onAddRemoveFile(false, file)} className='remove-file'><RemoveCircleIcon color='secondary' fontSize='small' /></span>
                          </div>
                        )}
                      </div>:<p style={{textAlign:'center',background:'#fff'}}>No file(s) selected</p>
                    }
                  </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModalFn()} color="primary" size='small'>Cancel</Button>
          { selectedFiles.length ?
              <Button onClick={onSubmitFileLink} variant="contained" color="primary" size='small'>Save</Button>:null
          }
        </DialogActions>
      </Dialog>
      { isLoading ? <PageLoader/>:null }
    </Fragment>
  )
}
export default withRouter(ChecklistFileLinking);

import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const DataRoomChart = ({analyticStats}) => {
  const customToolTip = (points) => {
    let tlTip = `<p>Date: ${moment(points[0].x).format('MMM YYYY')}</p><br/>`;
    points.map(point => {
      tlTip += '<b>'+point.series.name+'</b>:'+point.y+'</p><br/>';
    });
    return tlTip
  }
  const getTimePlot = (data) => {
    if(data){
      return data.map((item) => {return [new Date(item[0]).getTime(), item[1]]})
    }else{
      return []
    }
  }
  const options = {
    chart: {
      type: 'line',
      zoomType: 'x'
    },
    title: {
        text: ''
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis:{
      allowDecimals: false,
      title: {
        text: 'File(s) Count',
        style: {
          fontSize: '11px'
        }
      },
      labels: {
        formatter: function () {
          return this.value;
        }
      },
      gridLineDashStyle: 'longdash'
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter: function(){
        return customToolTip(this.points);
      },
      shared: true
    },
    series: [
      {
        name: 'To be Renamed',
        data: getTimePlot(analyticStats.plots && analyticStats.plots.rename ? analyticStats.plots.rename : [])
      },
      {
        name: 'OCR Rejected',
        data: getTimePlot(analyticStats.plots && analyticStats.plots.issue ? analyticStats.plots.issue : [])
      },
      {
        name: 'Upload',
        data: getTimePlot(analyticStats.plots && analyticStats.plots.uploaded ? analyticStats.plots.uploaded : [])
      },
      {
        name: 'Published',
        data: getTimePlot(analyticStats.plots && analyticStats.plots.published ? analyticStats.plots.published : [])
      },
      {
        name: 'OCRed',
        data: getTimePlot(analyticStats.plots && analyticStats.plots.completed ? analyticStats.plots.completed : [])
      }
    ]
  };
  return(
    <div style={{clear:'both'}}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export default DataRoomChart;

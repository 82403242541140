import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Dialog, Paper, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, Tooltip, CircularProgress, Popover } from '@material-ui/core';
import { AutocompleteCreatable } from '../../../shared_elements'
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus, checkPermission } from '../../../utils';
const DataRoomTags = ({dmTags, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState(dmTags?.data?.tags||[]);
    useEffect(() => {
        getTags();
    },[])
    const getTags = () => {
        globalGetService(`records/tags/`)
        .then(response => {
            if(checkApiStatus(response)){
                setTags(response.data.data)
            }
        })
    }
    
    const onAddTagsToData = () => {
        let payload = {tags:selectedTags}
        if(dmTags.data.type === 'file'){
            payload = {
                ...payload,
                file_ids:[dmTags.data.id]
            }
        }else{
            payload = {
                ...payload,
                folder_ids:[dmTags.data.id]
            }
        }
        setLoading(true);
        globalPostService(`records/tags/`,payload)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                getResponseBack(response.data.data); toggleModalFn();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error', autoHideDuration:5000, anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
            setLoading(false);
        })
    }
    const onFieldChange = (value) => {
        let newTag = value.length ? value[value.length-1]:null;
        if(newTag && newTag.hasOwnProperty('inputValue')){
            if(newTag.inputValue.length <= 50){
                setSelectedTags(value)
            }else{
                enqueueSnackbar('Max Character for tag should be less than 50', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
        }else{
            setSelectedTags(value)
        }
        
    }
    return(
        <>
            <Dialog
                open={dmTags.modal}
                onClose={() => toggleModalFn()}
                className='records-dataroom-modal'
                maxWidth='lg'
            >
                <DialogTitle id="scroll-dialog-title"> 
                    {dmTags.data.name}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'620px'}}>
                        {(() => {
                            let selectedTagIds = dmTags.data.tags.map((tagId) => tagId.id);
                            let tagOptions = tags.filter(item => !selectedTagIds.includes(item.id))
                            return(
                                <AutocompleteCreatable
                                    disabled={!checkPermission('records','tags','C')}
                                    placeholder="Enter Tags"
                                    options={tagOptions}
                                    value={selectedTags}
                                    paramsKey='tags'
                                    optionKey="name"
                                    required={false}
                                    multiple={true}
                                    label="Tags"
                                    onFieldChange={(paramsKey, value) => onFieldChange(value)}
                                />
                            )
                        })()}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={() => toggleModalFn()} color="primary" variant="outlined" size='small'>Cancel</Button>
                    { checkPermission('records','tags','C') ?
                        <Button disabled={isLoading} onClick={onAddTagsToData} color="primary" variant="contained" size='small'>
                            {isLoading ? <CircularProgress size={24} />:'Save'}
                        </Button>:null
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}
export default DataRoomTags;
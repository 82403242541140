import React from 'react';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import DmFileCountCard from './DmFileCountCard'
const FileStatusCount = ({analyticStats}) => {
  return(
    <div className="file-count-stats">
      <Grid container spacing={isMobile ? 1 : 3}>
        <Grid item xs={6} sm={4} md='auto' lg={true} className="flex-not-centered">
          <DmFileCountCard
            title="No Of File(s)"
            count={analyticStats.general_info.total_files}
          />
        </Grid>
        <Grid item xs={6} sm={4} md='auto' lg={true} className="flex-not-centered">
          <DmFileCountCard
            title="File(s) Published"
            count={analyticStats.general_info.publish_files}
          />
        </Grid>
        <Grid item xs={6} sm={4} md='auto' lg={true} className="flex-not-centered">
          <DmFileCountCard
            title="File(s) to be renamed"
            count={analyticStats.general_info.to_be_renamed}
          />
        </Grid>
        <Grid item xs={6} sm={4} md='auto' lg={true} className="flex-not-centered">
          <DmFileCountCard
            title="File(s) Unpublished"
            count={analyticStats.general_info.unpublished_files}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default FileStatusCount;

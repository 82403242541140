import React from 'react';
import {Paper} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
const OCRStatsChart = ({actionDockAnalytic}) => {
  const getTimePlot = (data) => {
    return data.map((item) => {return [new Date(item[0]).getTime(), item[1]]})
  }
  const options = {
    chart: {
      type: 'line',
      zoomType: 'x'
    },
    title: {
        text: ''
    },
    xAxis: {
      type: 'datetime'
    },
    yAxis:{
      allowDecimals: false,
      title: {
        text: 'Files(s) Count',
        style: {
          fontSize: '11px'
        }
      },
      labels: {
        formatter: function () {
          return this.value;
        }
      },
      gridLineDashStyle: 'longdash'
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'OCR Rejected',
        data: getTimePlot(actionDockAnalytic.plots.issue)
      },
      {
        name: 'OCRed',
        data: getTimePlot(actionDockAnalytic.plots.completed)
      }
    ]
  };
  return(
    <Paper style={{clear:'both'}}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </Paper>
  )
}
export default OCRStatsChart;

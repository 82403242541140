import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { checkApiStatus } from '../../../utils';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { useSnackbar } from 'notistack';
import { PageLoader } from '../../../shared_elements';
import TemplateAddEdit from './TemplateAddEdit';
const assetTypeOptions = [{ label: 'Aircraft', value: 1 }, { label: 'Engine', value: 2 }, { label: 'APU', value: 3 }, { label: 'Landing Gear', value: 4 }, { label: 'Propeller', value: 5 }, { label: 'Engine Modules', value: 10 }]
export default function ApplyTemplate({ applyTemplateModal, toggleModal, assetInfo, getResponseBack }) {
    const [templateData, setTemplateData] = useState({})
    const [templateList, setTemplates] = useState([])
    const [error, setError] = useState({})
    const [majorAssembliesList, setMajorAssembliesList] = useState([])
    const [isLoading, setLoader] = useState(false)
    const [addTemplateModal, setAddTemplateModal] = useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (assetInfo?.asset_type != 1) {
            getTemplateList( {value:assetInfo?.asset_type})
            setTemplateData({ ...templateData, asset_type: assetTypeOptions.find(item => [2, 3, 4, 5].includes(item?.value) ? item?.value == assetInfo?.asset_type : item?.value == 10) })
        }
    }, [])
    const getTemplateList = (query) => {
        globalGetService(`records/v2/lessor-folder-template/`, { dropdown: true })
            .then(response => {
                if (checkApiStatus(response)) {
                    setTemplates(response.data.data.list)
                }
            });
    }
    const handleChange = (key, value) => {
        getTemplateList({value:1})
        if (key == 'asset_type' ) {
            if(value?.value == 1){
                setTemplateData({ ...templateData, majorAssemblyAsset: null, [key]: value, templateDetails:null  })
            }
            else{
                getMajorAssemblies(value)
                setTemplateData({ ...templateData, [key]: value,  templateDetails:null })
            }
        }
        else{
            setTemplateData({ ...templateData, [key]: value })
        }
    }
    const getMajorAssemblies = (assetType) => {
        setMajorAssembliesList([])
        globalGetService(`console/v2/aircraft/${assetInfo?.slug}/major-assemblies/`, { asset_type: assetType?.value })
            .then(response => {
                if (checkApiStatus(response)) {
                    setMajorAssembliesList(response.data.data?.list);
                }
                else{
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            });
    }

    const onSubmit = (queryType, data) => {
        let validateInput = {}
        let showMA = (assetInfo?.asset_type == 1) && [2, 3, 4, 5, 10].includes(templateData?.asset_type?.value)
        validateInput = {
            ...validateInput,
            templateDetails: templateData.templateDetails?.id ? '' : 'Please select Template',
            asset_type: templateData.asset_type ? '' : 'Please select Asset Type',
            // majorAssemblyAsset: (assetInfo?.asset_type == 1) && [2, 3, 4, 5, 10].includes(templateData?.asset_type?.value) ? templateData?.majorAssemblyAsset ? '' :"Please select the Major Assembly":''
        }
        if (Object.keys(validateInput).every(item => validateInput[item] === '')) {
            let payload = templateData
            payload = {
                ...payload,
                majorAssemblyAssetType:templateData.asset_type,
                asset_type:assetInfo?.asset_type,
                majorAssemblyAsset:showMA ? templateData?.majorAssemblyAsset : null,
                majorAssembly: showMA ? true: false,
                asset: { asset_name: assetInfo?.asset_name, id: assetInfo?.id },
                recordsParentFolder: applyTemplateModal?.data[applyTemplateModal?.data?.length - 1]
            }
            if(queryType == 'template_creation'){
                payload = {
                    ...payload,
                    ...data,
            }
            }
            if(!data){
                setAddTemplateModal({flag:true, data:{} ,maType:templateData.asset_type})
            }
            else{
                setLoader(true)
            globalPostService(`records/v2/lessor-folder-template/apply-template/${templateData.templateDetails?.id}/`, payload)
                .then(response => {
                    setLoader(false)
                    if (checkApiStatus(response)) {
                        getResponseBack()
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                    else{
                        enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                });
            }
        }
        else {
            setError(validateInput)
        }
    }
    return (
        <>
            {isLoading ? <PageLoader /> : null}
            <Dialog
                open={true}
                onClose={() => toggleModal()}
                aria-labelledby="scroll-dialog-title"
                className='records-dataroom-modal'
                fullWidth='true'
            >
                <DialogTitle id="scroll-dialog-title">
                    Apply Template
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={1} alignItems='center'>

                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    style={{ paddingTop: "13px", marginBottom: '10px' }}
                                    disabled={assetInfo?.asset_type != 1}
                                    options={[{ label: 'Aircraft', value: 1 }, { label: 'Engine', value: 2 }, { label: 'APU', value: 3 }, { label: 'Landing Gear', value: 4 }, { label: 'Propeller', value: 5 }, { label: 'Engine Modules', value: 10 }]}
                                    getOptionLabel={option => option.label}
                                    value={templateData?.asset_type}
                                    onChange={(e, data) => handleChange('asset_type', data)}
                                    renderInput={params => <TextField required variant='outlined' onFocus={() => setError({ ...error, asset_type: '' })} error={error?.asset_type} helperText={error?.asset_type || ''} placeholder="Please select Asset Type" size='small' {...params} margin="none" label='Asset Type' fullWidth InputLabelProps={{ shrink: true }} />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    style={{ marginBottom: '10px' }}
                                    options={templateList}
                                    getOptionLabel={option => option.name}
                                    id="template"
                                    disabled={templateData?.asset_type?.value ? false :true}
                                    value={templateData.templateDetails ? templateData.templateDetails : null}
                                    onChange={(e, value) => handleChange('templateDetails', value)}
                                    renderInput={params => <TextField required {...params} onFocus={() => setError({ ...error, templateDetails: '' })} error={error?.templateDetails} helperText={error?.templateDetails || ''} label="Select Template" placeholder="Please select Template" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            {
                               (assetInfo?.asset_type == 1) && [2, 3, 4, 5, 10].includes(templateData?.asset_type?.value) ?
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            style={{ paddingTop: "13px", marginBottom: '10px' }}
                                            options={majorAssembliesList || []}
                                            getOptionLabel={option => option.major_assembly_name}
                                            value={templateData?.majorAssemblyAsset}
                                            onChange={(e, data) => handleChange('majorAssemblyAsset', data)}
                                            renderInput={params => <TextField  variant='outlined'  placeholder="Please select Major Assembly" size='small' {...params} margin="none" label='Major Assembly' fullWidth InputLabelProps={{ shrink: true }} />}
                                        />
                                    </Grid> : null
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModal} color="primary" variant="text">CANCEL</Button>
                    <Button onClick={onSubmit} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
               { 
                addTemplateModal?.flag ?
                <TemplateAddEdit
                 templateData={templateData}
                 submitWithTemplate={(data)=>onSubmit('template_creation', data)}
                    mainassetInfoData={assetInfo}
                    addTemplateModal={addTemplateModal}
                    assetData={addTemplateModal}
                    toggleModalFn={() => setAddTemplateModal(false)}
                    // templateSelection={templateSelection}
                />
                : null}

        </>
    )
}

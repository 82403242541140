import React, { useState, useEffect, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { Paper, Grid, Button, Tabs, Tab } from '@material-ui/core'
import { PageLoader } from '../../../shared_elements';
import { IssueTitle, OtherInformation, ContentExpansion, IssueComments } from '../components'
import { checkApiStatus, removeEmptyKey } from '../../../utils';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
const DRIssueDetail = ({match, location}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [tabIndex, setTabIndex] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [prsDetail, setPrsDetail] = useState({});
    useEffect(() => {
        getIssueDetail(match.params.slug);
    },[]);
    const getIssueDetail = (slug, loaderType) => {
        setSkeletonLoader(true);
        globalGetService(`records/asset/${match.params.assetType}/${match.params.assetSlug}/issues/${slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setPrsDetail(response.data.data);
            }
            setSkeletonLoader(false);
        })
    }
    return(
        <section className='project-forms-section'>
            <div className='prs-details-section' style={{padding:'3px'}}>
                { skeletonLoader ? <PageLoader />:
                    <div className='prs-detail-content' style={{height: `${window.innerHeight-132}px`}}>
                        {prsDetail && Object.keys(prsDetail).length ?
                            <Fragment>
                                <div className='issue-title'>
                                    <IssueTitle prsDetail={prsDetail} getResponseBack={() => getIssueDetail(match.params.slug,'pageLoader')} />
                                </div>
                                <div className='issue-description'>
                                    <ContentExpansion valueKey='description' title={'Description'} content={prsDetail?.description||''} />
                                    <ContentExpansion valueKey='requirement_remarks' title={'Discrepancy Requirement'} prsDetail={prsDetail} content={prsDetail?.requirement_remarks||''} />
                                    <ContentExpansion valueKey='background_remarks' title={'Discrepancy Background'} prsDetail={prsDetail} content={prsDetail?.background_remarks||''} />
                                </div>
                                <div className='issue-other-information'>
                                    <OtherInformation prsDetail={prsDetail} />
                                </div>
                                <Paper style={{marginBottom:'15px'}}>
                                    <Tabs value={tabIndex} onChange={(event, newValue)=> setTabIndex(newValue)}>
                                        <Tab label="Comment(s)" />
                                    </Tabs>
                                </Paper>
                                <IssueComments />
                            </Fragment>:null
                        }
                    </div>
                }
            </div>
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default DRIssueDetail;
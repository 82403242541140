import moment from 'moment';
import { globalGetService, globalPostService, globalPutService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, getLocalStorageInfo, downloadFileType } from '../../utils';
import { trackActivity } from '../../utils/mixpanel';
const queryString = require('query-string');
export function getAutoOrgBatchsApi(props, { }, loaderType) {
  this.setState({ [loaderType]: true });
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/batches/`)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        this.setState({ autoOrganiserBatch: response.data.data });
      }
    })
}
export function getAutoOrgBatchDetailApi(props, loaderType) {
  globalGetService(`records/v2/batch/${props.match.params.uuid}/detail/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ batchDetail: response.data.data });
      }
    })
}
export function getAutoOrgDataApi(props, queryParams, loaderType) {
  this.setState({ [loaderType]: true });
  globalGetService(`records/v2/batch/${props.match.params.uuid}/organised-files/`, queryParams)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        this.setState({ autoOrganiserData: response.data.data })
      }
    })
}
export function getAutoUnOrgDataApi(props, queryParams, loader) {
  this.setState({pageLoader:true})
  globalGetService(`records/v2/batch/${props.match.params.uuid}/unorganised-files/`, queryParams)
  .then(response => {
      this.setState({pageLoader:false})
      if (checkApiStatus(response)) {
        this.setState({ autoUnOrgData: response.data.data })

      }
    })
}
export function onFileRanmeApi(props, renameFile) {
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/batch/${props.match.params.uuid}/file-rename/${renameFile.data.uuid}/`, { new_name: renameFile.data.name })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ renameFile: { modal: false, data: null } });
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        let query = {};
        if (queryString.parse(this.props.location.search).folder_uuid) {
          query = {
            ...query,
            folder_uuid: queryString.parse(this.props.location.search).folder_uuid
          }
        }
        this.getAutoOrgDataApi(this.props, query, 'pageLoader');
        this.getAutoUnOrgDataApi(this.props);
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}

export function publishOrgDataApi(props) {
  this.setState({ pageLoader: true });
  globalGetService(`records/v2/batch/${props.match.params.uuid}/publish/`)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ publishDataModal: false });
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.getAutoOrgDataApi(this.props, {}, 'pageLoader');
        trackActivity('Item Published', { page_title: 'Auto Organiser', page_type: 'view - (Organised Files)', uuid: props.match.params.uuid, asset_slug: props.match.params.assetSlug, asset_type: props.match.params.assetType })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function onFileDeleteApi(props, bulkOperation) {
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/batch/${props.match.params.uuid}/batch-file-recycle/action/`, { file_uuid: bulkOperation.ids })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ bulkOperation: { modal: false, ids: [] } });
        let query = {};
        if (queryString.parse(this.props.location.search).folder_uuid) {
          query = {
            ...query,
            folder_uuid: queryString.parse(this.props.location.search).folder_uuid
          }
        }
        this.getAutoOrgDataApi(this.props, query, 'pageLoader');
        this.getAutoUnOrgDataApi(props, {})
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function moveUnOrgTreeApi(props, queryParams = {}) {
  globalGetService(`/records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/move-folder-dropdown/`, queryParams)
    .then(response => {
      if (checkApiStatus(response)) {

      }
    })
}

// Move Operation related API
export function getDataMoveListApi(props, uuid, folderUuid, queryParams, name, type) {
  let url = `records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/move-folder-dropdown/`;
  if (type === 'org') {
    url += `file/${uuid}/${folderUuid}/`;
  }
  globalGetService(url, queryParams)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          moveData: {
            ...prevState.moveData,
            modal: true,
            sourceType: 'file',
            sourceId: uuid,
            moveFolderuuid: '',
            navigation: response.data.data.navigation,
            list: response.data.data.list,
            type: type,
            name: name
          }
        }))
      }
    })
}
export function onMoveDmDataApi(props, data) {
  let payload = [{ type: data.sourceType, to_folder: data.moveFolderuuid, uuid: [data.sourceId] }];
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/move/`, payload)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({
          moveData: {
            name: '',
            moveFolderuuid: '',
            modal: false,
            sourceId: '',
            sourceType: '',
            navigation: [],
            list: [],
            type: ''
          },
        });
        let query = {};
        if (queryString.parse(this.props.location.search).folder_uuid) {
          query = {
            ...query,
            folder_uuid: queryString.parse(this.props.location.search).folder_uuid
          }
        }
        this.getAutoOrgDataApi(this.props, query, 'pageLoader');
        this.getAutoUnOrgDataApi(this.props, {}, 'pageLoader');
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}

import React,  { useState } from 'react';
import { Provider } from 'react-redux';
import { withOrientationChange, isMobile } from 'react-device-detect'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Offline } from "react-detect-offline";
import { SnackbarProvider } from 'notistack';
import { Zoom, Fab, useScrollTrigger, makeStyles } from '@material-ui/core';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PrivateRoute from './hoc/PrivateRoute';
import configureStore from './store/configureStore';
import { fleetRoutes } from './records_dataroom/fleet/routes'
import { reportsRoutes } from './records_dataroom/reports/routes'
import { trashRoutes } from './records_dataroom/trash/routes'
import { authRoutes } from './application/auth/authRoutes';
import { dataRoomRoutes } from './application/dataroom/dataRoomRoutes';
import { actionDockRoutes } from './application/action_dock/actionDockRoutes';
// import { recycleBinRoutes } from './application/recycle_bin/recycleBinRoutes';
// import { reportsRoutes } from './application/reports/reportsRoutes';
import { homeRoutes } from './application/home/homeRoutes';
import { autoOrganiserRouters } from './application/auto_organiser/autoOrganiserRouters';
import { settingsRoutes } from './application/settings/settingsRoutes';
import MaintenanceModal from './shared_elements/components/MaintenanceModal';
import { getGlobalCookie } from './utils';
// import { useNavigatorStatus } from "react-navigator-status";
// import Alert from "./Alert";
const store = configureStore();
const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));
function ScrollTop(props) {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}
const App = ({isLandscape, isPortrait}) => {
  const [maintenanceModal, setMaintenanceModal ] = useState(false)
  // const isOnline = useNavigatorStatus();
  return (
    <Provider store={store}>
      <div id="back-to-top-anchor" />
      <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
      {/* <Alert isOnline={isOnline} /> */}
        {/* <Offline>
          <span style={{position:'fixed', top:'0', left:'0', right:'0', margin:'0 auto', width: '300px', background:'yellow', zIndex:999999, textAlign:'center'}}><b>Computer not connected.</b> Make sure your computer has an active Internet connection.</span>
          <p style={{position:'fixed', top:0, right:0, bottom:0, left:0, zIndex:99999, background:'transparent', cursor:'not-allowed'}}></p>
        </Offline> */}
        <Router>
          <Switch>
            { authRoutes.map(({path, component, key}, index) =>
              <Route exact={true} path={path} component={component} key={key}/>
            )}
            {[...fleetRoutes, ...homeRoutes, ...dataRoomRoutes, ...autoOrganiserRouters, ...actionDockRoutes, ...trashRoutes, ...reportsRoutes, ...settingsRoutes].map(({path, component, key}, index)  =>
              <PrivateRoute exact={true} path={path} component={component} key={key}/>
            )}
          </Switch>
        </Router>
      </SnackbarProvider>
      {!isMobile ?
        <ScrollTop>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>:null
      }
      <MaintenanceModal maintenanceModal={maintenanceModal} onCloseMaintenanceModal={() => setMaintenanceModal(false) }  />
    </Provider>
  );
}
export default withOrientationChange(App)

export const autoOrganiserHd = [
  { label:'Batch No', sortOption:false},
  { label:'No. of Files', sortOption:false},
  { label:'Size', sortOption:false},
  { label:'Uploaded By', sortOption:false},
  { label:'Status', sortOption:false},
  { label:'Action', sortOption:false}
]
export const unOrganisedHd = [
  { label:'Name', sortOption:false},
  { label:'Size', sortOption:false},
  { label:'Uploaded By', sortOption:false},
  { label:'Last Modified', sortOption:false},
  { label:'Action', sortOption:false}
]

import React from 'react';
import { Grid, Paper } from '@material-ui/core';
const OCRCountCard = ({title, count}) => {
  return(
    <Grid item xs={12} md={3}>
      <Paper className="ocr-count-card">
        <p>{title}</p>
        <h4>{count}</h4>
      </Paper>
    </Grid>
  )
}
export default OCRCountCard;

import { globalGetService, globalPostService, globalStaticGetService } from '../../utils/globalApiServices';
import * as actions from './actions';
import { checkApiStatus } from '../../utils';
export const getFilesCountsAc = (props, queryParams) => {
  return (dispatch) => {
    dispatch({
      type:actions.TOGGLE_DB_LOADER,
      payload:{type:'countCardLoader', flag:true}
    });
    dispatch({
      type:actions.DB_LOADER,
      payload:true
    });
    globalStaticGetService(`records/v2/dashboard_bar_plots/`, queryParams)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.FLEET_FILES_COUNT,
          payload: response.data.data
        });
        dispatch({
          type:actions.DB_LOADER,
          payload:false
        });
      }
      dispatch({
        type:actions.TOGGLE_DB_LOADER,
        payload:{type:'countCardLoader', flag:false}
      });
    })
  }
}
export const getFilesActivityCountsAc = (props, queryParams) => {
  return (dispatch) => {
    dispatch({
      type:actions.TOGGLE_DB_LOADER,
      payload:{type:'activityLoader', flag:true}
    });
    globalStaticGetService(`records/v2/dashboard_line_plots/`, queryParams)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.FLEET_FILES_ACTIVITY_COUNT,
          payload: response.data.data
        })
      }
      dispatch({
        type:actions.TOGGLE_DB_LOADER,
        payload:{type:'activityLoader', flag:false}
      });
    })
  }
}

import { combineReducers } from 'redux'
import shareReducer from '../shared_elements/reducers';
import homeReducer from '../application/home/reducers';
import dataRoomReducer from '../application/dataroom/reducers';
const rootReducer = combineReducers({
  shareReducer,
  homeReducer,
  dataRoomReducer
})
export default rootReducer

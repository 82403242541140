import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { FileStatusCountChart, OcrFileCountChart, FileOpsProgressChart, HomeFilter } from '../components';
import { ChartDateFilter, PageLoader } from '../../../shared_elements'
import { backendDateFormat } from '../../../constants';
import { getFilesCountsAc, getFilesActivityCountsAc } from '../actionCreators';
import { getAllAssetListAc } from '../../../shared_elements/actionCreators';
import { trackActivity } from '../../../utils/mixpanel';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customDateFlag: false,
      pageLoader: false,
      asset: { unique_name: 'All Asset', id: 0 },
      start_date: moment().subtract(7, 'days').format(backendDateFormat),
      end_date: moment().format(backendDateFormat)
    }
  }
  componentDidMount() {
    const { start_date, end_date } = this.state;
    this.props.getFilesCountsFn({})
    this.props.getFilesActivityCountsFn({ start_date: start_date, end_date: end_date })
    this.props.getAllAssetListFn()
    trackActivity('Page Visited', {
      page_title: 'Dashboard'
    })
  }
  onAssetChange = (asset) => {
    const { start_date, end_date } = this.state;
    this.setState({ asset: asset ? asset : asset === null ? { unique_name: '', id: 0 } : { unique_name: 'All Asset', id: 0 } });
    let query = {};
    if (asset && asset.id !== 0) {
      query = {
        ...query,
        asset_id: asset.id,
        asset_type: asset.asset_type
      }
    }
    this.props.getFilesCountsFn(query);
    this.props.getFilesActivityCountsFn({ start_date: start_date, end_date: end_date, ...query })
  }
  onChangeDays = (days) => {
    this.props.getFilesActivityCountsFn({ start_date: moment().subtract(days, 'days').format(backendDateFormat), end_date: moment().format(backendDateFormat) });
    this.setState({
      start_date: moment().subtract(days, 'days').format(backendDateFormat),
      end_date: moment().format(backendDateFormat),
      customDateFlag: false
    })
  }
  render() {
    const { fleetFiles, countCardLoader, fleetActivityCount, activityLoader, dbLoader, allAssets } = this.props;
    const { customDateFlag, start_date, end_date, pageLoader, asset } = this.state;
    return (
      <section className="records-home-section">
        <HomeFilter allAssets={allAssets} asset={asset} onAssetChange={this.onAssetChange} fleetFiles={fleetFiles} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            {countCardLoader ?
              <div>
                <Skeleton variant="rect" animation='wave' height={424} />
              </div> :
              fleetFiles && fleetFiles.files_data ?
                <FileStatusCountChart
                  filesCount={fleetFiles && fleetFiles.files_data ? fleetFiles.files_data : []}
                /> : null
            }
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {countCardLoader ?
              <div>
                <Skeleton variant="rect" animation='wave' height={424} />
              </div> :
              fleetFiles && fleetFiles.ocr_data ?
                <OcrFileCountChart
                  filesCount={fleetFiles && fleetFiles.ocr_data ? fleetFiles.ocr_data : []}
                /> : null
            }
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ChartDateFilter
              start_date={start_date}
              end_date={end_date}
              onChangeDays={this.onChangeDays}
              customDateFlag={customDateFlag}
              onChangeCustomDate={(startDate, endDate) => { this.props.getFilesActivityCountsFn({ start_date: startDate, end_date: endDate }); this.setState({ start_date: startDate, end_date: endDate, customDateFlag: true }) }}
            />
            {activityLoader ?
              <div style={{ clear: 'both' }}>
                <Skeleton variant="rect" animation='wave' height={424} />
              </div> :
              fleetActivityCount && Object.keys(fleetActivityCount).length ?
                <FileOpsProgressChart
                  fleetActivityCount={fleetActivityCount}
                /> : null
            }
          </Grid>
        </Grid>
        {pageLoader || dbLoader? <PageLoader /> : null}
      </section>
    )
  }
}
const mapStateToProps = state => ({
  fleetFiles: state.homeReducer.fleetFiles,
  countCardLoader: state.homeReducer.countCardLoader,
  fleetActivityCount: state.homeReducer.fleetActivityCount,
  dbLoader: state.homeReducer.dbLoader,
  activityLoader: state.homeReducer.activityLoader,
  allAssets: state.shareReducer.allAssets,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getFilesCountsFn: (query) => dispatch(getFilesCountsAc(ownProps, query)),
    getFilesActivityCountsFn: (query) => dispatch(getFilesActivityCountsAc(ownProps, query)),
    getAllAssetListFn: () => dispatch(getAllAssetListAc()),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Dashboard))

import React from 'react';
import { TableCell, TableHead, TableRow, Table, TableSortLabel } from '@material-ui/core';
import { getLocalStorageInfo } from '../../../utils'; 
import { assetValueKey } from '../'
import config from '../../../config';
const AssetHeader = ({fleetType, filter, sortInfo, createSortHandler}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>
                    <div style={{width:'180px'}}>
                        <TableSortLabel
                            active={sortInfo.sort === 'asset_name' ? true:false}
                            direction={sortInfo.sort === 'asset_name' ? sortInfo.sort_by :'asc'}
                            onClick={()=>createSortHandler('asset_name')}
                        >
                            Asset(s)
                        </TableSortLabel>
                    </div>
                    
                </TableCell>
                <TableCell>
                    <div style={{width:'180px'}}>
                        <TableSortLabel
                            active={sortInfo.sort === 'records_count' ? true:false}
                            direction={sortInfo.sort === 'records_count' ? sortInfo.sort_by :'asc'}
                            onClick={()=>createSortHandler('records_count')}
                        >
                            No. of Files(s)
                        </TableSortLabel>
                    </div>
                </TableCell>
                <TableCell>
                    <div style={{width:'180px'}}>
                        <TableSortLabel
                            active={sortInfo.sort === 'records_size' ? true:false}
                            direction={sortInfo.sort === 'records_size' ? sortInfo.sort_by :'asc'}
                            onClick={()=>createSortHandler('records_size')}
                        >
                            Records Size
                        </TableSortLabel>
                    </div>
                </TableCell>
                <TableCell>
                    <div style={{width:'180px'}}>
                        File(s) In Queue
                    </div>
                </TableCell>
                <TableCell>
                    <div style={{width:'180px'}}>
                        OCR File(s) In Queue
                    </div>
                </TableCell>
                <TableCell>
                    <div style={{width:'180px'}}>
                        File(s) to be Renamed
                    </div>
                </TableCell>
                <TableCell align='right'>Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default AssetHeader;
import { Button, Chip, CircularProgress, Drawer, Paper, Grid, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import { useSnackbar } from 'notistack';
import { withRouter, Link } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Pagination, EmptyCollection, PageLoader } from '../../../shared_elements'
import { globalGetService, globalPutService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
const UnlinkedIssues = ({match, assetSlug, assetType, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [issuesInfo, setIssuesInfo] = useState({list:[], pagination:null});
    const getIssues = (query={}) => {
        setLoading(true);
        globalGetService(`records/asset/${assetType?assetType:match.params.assetType}/${assetSlug?assetSlug:match.params.assetSlug}/issues/`, {...query, unlinked:'True'})
        .then(response => {
            if(checkApiStatus(response)) {
                setIssuesInfo(response.data.data);
                setOpen(true)
            }
            setLoading(false)
        })
    }
    const onLinkIssue = (id) => {
        globalPutService(`records/asset/${assetType}/${assetSlug}/issues/${id}/`, {record_uuid:match.params.uuid, record_type:1})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar('Issue linked successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setIssuesInfo({...issuesInfo, list:issuesInfo.list.filter((item) => item.id !== id)})
                getIssues();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    const onCloseDrawer = () => {
        setOpen(false);
        getResponseBack();
    }
    return(
        <>
            <Button disabled={isLoading} onClick={() => getIssues()} color='primary' size='small' variant='outlined'>
                    {isLoading ? <CircularProgress size={24} />:`Link Issue`}
            </Button>
            { isOpen ?
                <Drawer anchor={'right'} open={isOpen} onClose={onCloseDrawer}>
                    <div className='issue-drawer'>
                        <div className='issue-drawer-hd'>
                            <div className='flex-centered'>
                                <h4>Unlinked Issue(s)</h4>
                                <span onClick={() => {onCloseDrawer(); setIssuesInfo({list:[], pagination:null})}}>
                                    <CancelIcon color='primary' fontSize='small' />
                                </span>
                            </div>
                        </div>
                        <div className='issue-drawer-body'>
                            <Paper>
                                <div style={{maxHeight:`${window.innerHeight-100}px`, overflow:'auto'}}>
                                    {issuesInfo.list.map((item) => 
                                        <div className='issue-item'>
                                            <Grid container spacing={1} key={item.id}>
                                                <Grid item md={8}>
                                                    <h6>{item.unique_code}</h6>
                                                    <h4 className='singleline-clamp'>{item.title}</h4>
                                                    <div className='comment-content singleline-clamp' dangerouslySetInnerHTML={{__html: item.description}}></div>
                                                    <ul className='list-inline'>
                                                        <li className='list-inline-item'>
                                                            <Chip label={<span>{item?.status?.label || ''}</span>} size="small"
                                                            style={{  background:item?.status.value === 0 && '#4b81de' || item?.status.value === 1 && '#269b2c' || item?.status.value === 2 && '#e86642'|| item?.status.value === 3 && '#dbcc28', color: '#ffffff',width: '110px', display: 'flex',  alignItems: 'center' }}
                                                            />
                                                        </li>
                                                    </ul>
                                                </Grid>
                                                <Grid item md={4}>
                                                    <ul className='list-inline issue-right-cta flex-centered'>
                                                        <li className='list-inline-item'>
                                                            <Link target='_blank' to={`/${assetType?assetType:match.params.assetType}/${assetSlug?assetSlug:match.params.assetSlug}/data-room/issues/view/${item.id}`}>
                                                                <VisibilityIcon color='primary' fontSize='small' />
                                                            </Link>
                                                        </li>
                                                        <li className='list-inline-item' onClick={() => onLinkIssue(item.id)}>
                                                            <Button size='small' color='primary' variant='outlined'>Link</Button>
                                                        </li>
                                                    </ul>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                </div>
                                { issuesInfo?.list?.length ?
                                    <Pagination 
                                        pagination={issuesInfo.pagination}
                                        onChangePage={(event, newPage) => getIssues({page:newPage+1, per_page: issuesInfo.pagination.per_page}, 'pageLoader')}
                                        onChangeRowsPerPage={(event) => getIssues({page:1, per_page: event.target.value}, 'pageLoader')}
                                    />:null
                                }
                                { !issuesInfo?.list?.length ? <EmptyCollection title={'No records found'}/>:null }
                            </Paper>
                        </div>
                    </div>
                </Drawer>:null
            }
            { isLoading ? <PageLoader />:null }
        </>
    )
}
export default withRouter(UnlinkedIssues);
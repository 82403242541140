import Trash from './pages/Trash';
import PageLayout from '../../hoc/PageLayout';
import UserRoleAccess from '../../hoc/UserRoleAccess';
export const trashRoutes = [
  {
    path: '/trash',
    component: PageLayout(UserRoleAccess(Trash, ['trash', 'trash', 'R']), {apps: 'Trash', layoutPhase: 1}),
    key: 'RecycleBins',
    apps:'records'
  },
]

import Templates from './containers/Templates';
import CheckListTemplate from './containers/CheckListTemplate';
import Tags from './containers/Tags';
import PageLayout from '../../hoc/PageLayout';
import UserRoleAccess from '../../hoc/UserRoleAccess';
export const settingsRoutes = [
  {
    path: '/settings/templates',
    component: PageLayout(Templates, {apps: 'Settings', layoutPhase: 2}),
    key: 'Templates',
    apps:'records'
  },
  {
    path: '/settings/tags',
    component: PageLayout(UserRoleAccess(Tags, ['records', 'tags', 'R']), {apps: 'Tags', layoutPhase: 2}),
    key: 'Tags',
    apps:'records'
  },
  {
    path: '/templates',
    component: PageLayout(UserRoleAccess(CheckListTemplate, ['templates', 'templates', 'R']), {apps: 'Template', layoutPhase: 1}),
    key: 'Templates',
    apps:'records'
  },
]

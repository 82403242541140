import * as actions from './actions';
export default function( state = {
  searchLoader:false,
  fileStacks:[],
  afterUploadStacks:[],
  fileStacksFlag:false,
  fileUploadingFlag:false
}, action){
  switch (action.type) {
    case actions.UPLOAD_FILE_STACK:
      return {
        ...state,
        fileStacks: [...state.fileStacks,...action.payload]
      }
    case actions.TOGGLE_UPLOAD_STATS_BAR:
      return {
        ...state,
        fileStacksFlag: action.payload
      }
    case actions.TOGGLE_FILE_UPLOAD_PRO:
      return {
        ...state,
        fileUploadingFlag: action.payload
      }
    case actions.UPLOAD_AFTER_FILE_STACK:
      return {
        ...state,
        afterUploadStacks: [...state.afterUploadStacks, action.payload]
      }
    case actions.CLEAN_FILES_STACK:
      return {
        ...state,
        afterUploadStacks: [],
        fileStacksFlag:false,
        fileStacks:[],
        fileUploadingFlag:false
      }
    default:
      return state;
  }
}

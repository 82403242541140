import React, { Fragment } from 'react';
import { withRouter} from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { checkPermission } from '../../../utils';
import { permissionKeys } from '..';
const RenameFileModal = ({match, toggleModalFn, onChangeFileName, fileRenameInfo, saveFileName}) => {
  return(
    <Dialog
      open={fileRenameInfo.modal}
      onClose={() => toggleModalFn(false)}
      aria-labelledby="scroll-dialog-title"
      fullWidth= 'true'
    >
      <DialogTitle id="scroll-dialog-title">
        Rename
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2} style={{display:'block'}}>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              fullWidth
              margin="normal"
              value={fileRenameInfo.data.name}
              onChange={(e) => onChangeFileName(e.target.value)}
              InputLabelProps={{shrink: true}}
            />
          </Grid>
          {fileRenameInfo.data.naming_convention.map((name, index) =>
            <p style={{margin:"0px 0px 4px 10px"}}>{name}</p>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleModalFn(false)}>Cancel</Button>
        <Button onClick={() => saveFileName({publish:false})} variant="contained"  color="primary">Save</Button>
        { checkPermission('reviewdock',permissionKeys[match.params.type],'PUB_REJ') ?
          <Button onClick={() => saveFileName({publish:true})} variant="contained"  color="primary">Save & Publish</Button>:null
        }
        
      </DialogActions>
    </Dialog>
  )
}
export default withSnackbar(withRouter(RenameFileModal));

import React from 'react';
import {Paper, Tooltip} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Info from '@material-ui/icons/Info';
import { isMobile } from 'react-device-detect';
require('highcharts/modules/exporting')(Highcharts);
const OcrFileCountChart = ({filesCount}) => {
  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
      style: {
        fontSize: '11px',
      },
    },
    xAxis: {
      type: 'category'
    },
    yAxis:{
      allowDecimals: false,
      title: {
        text: 'File(s) Count',
        style: {
          fontSize: '11px'
        }
      },
      labels: {
        formatter: function () {
          return this.value;
        }
      },
      gridLineDashStyle: 'longdash'
    },
    tooltip: {
      formatter: function(){
        return '<p><b>No. of Files: </b>'+this.points[0].y+'</p>'
      },
      shared: true
    },
    credits: {
      enabled: false
    },
    series:  [
      {
        name: 'OCR Progress',
        data: [
          {
              name: "Completed",
              y: filesCount.completed,
              color:'#BC8546'
          },
          {
              name: "In Queue",
              y: filesCount.ocr_in_queue,
              color:'#77BCB8'
          },
          {
              name: "Processing",
              y: filesCount.processing,
              color:'#CF6FA9'
          },
          {
              name: "Rejected",
              y: filesCount.issue,
              color:'#FF6565'
          }
        ],
      }
    ],
  };
  return(
    <Paper style={{height:'400px'}}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <Tooltip title="Includes all the File Types" arrow>
        <span style={isMobile ? {position:'relative', left:'64%', bottom:'34px'} : {position:'relative', left:'59%', bottom:'34px'}}> 
          <Info style={isMobile ? { height:'15px'} : { height:'18px'}}/> 
        </span>
      </Tooltip>
    </Paper>
  )
}
export default OcrFileCountChart;

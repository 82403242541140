import React, { Fragment, Component } from 'react';
import { withRouter} from 'react-router-dom'
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, TableRow, TableCell, Checkbox, Tooltip } from '@material-ui/core';
import { TableListComp, EmptyCollection } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import { getFolderFilesApi, onRenameDMFilesApi } from '../apiServices';
import { checkPermission } from '../../../utils';
class FolderLevelFilesModal extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      renameModal:false,
      fileInfo:null,
      bulkOperation:{ids:[]},
      title:'',
      folderLevelFiles:{
        list:[],
        pagination:null
      },
    }
    this.getFolderFilesApi = getFolderFilesApi.bind(this);
    this.onRenameDMFilesApi = onRenameDMFilesApi.bind(this);
  }
  toggleBulkOps = (flag, uuid) => {
    if(uuid === 'all'){
      if(flag){
        this.setState(prevState => ({
          ...prevState,
          bulkOperation:{
            ...prevState.bulkOperation,
            ids:this.state.folderLevelFiles.list.map(item => item.uuid)
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          bulkOperation:{
            ...prevState.bulkOperation,
            ids:[]
          }
        }))
      }
    }else{
      if(flag){
        this.setState(prevState => ({
          ...prevState,
          bulkOperation: {
            ...prevState.bulkOperation,
            ids:[...prevState.bulkOperation.ids, uuid]
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          bulkOperation:{
            ...prevState.bulkOperation,
            ids:prevState.bulkOperation.ids.filter(id => id !== uuid)
          }
        }))
      }
    }
  }
  render(){
    const { modal, renameModal, fileInfo, folderLevelFiles, bulkOperation, title } = this.state;
    const { analyticStats, type, changeDMFilesStatus } = this.props;
    let query = {};
    if(title){
      if(title === 'Unpublished Files'){
        query = {
          ...query,
          unpublished:true
        }
      }else{
        query = {
          ...query,
          rename:true
        }
      }
    }
    return(
      <Fragment>
        { type.trim() === 'unpublished' ?
          <Button onClick={() => this.getFolderFilesApi(this.props, {unpublished:true})} color="primary" size="small" variant="contained">Files in Queue - {analyticStats.general_info.unpublished_files}</Button>:
          <Button onClick={() => this.getFolderFilesApi(this.props, {rename:true})} color="primary" size="small" variant="contained">Rename {analyticStats.general_info.to_be_renamed}</Button>
        }
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
            fullScreen={true}
          >
            <DialogTitle id="scroll-dialog-title">
              {title}
            </DialogTitle>
            <DialogContent dividers={true} className="issue-files-modal">
              <TableListComp
                bulkOperation={checkPermission('records','data_room', 'PUB_REJ') ? true:false}
                bulkFlag={folderLevelFiles.list.length === bulkOperation.ids.length && folderLevelFiles.list.length > 0}
                toggleBulkOps={(flag) => this.toggleBulkOps(flag, 'all')}
                heads={[{label:'Name', sortOption:false}]}
                data={ folderLevelFiles.list.map((item,index) =>
                  <TableRow hover tabIndex={-1}>
                    {checkPermission('records','data_room', 'PUB_REJ') ?
                      <TableCell width="30">
                        <Checkbox
                          name={item.id}
                          color="primary"
                          className="checkbox-icon"
                          size="small"
                          checked={bulkOperation.ids.includes(item.uuid)}
                          onChange={(e) => this.toggleBulkOps(e.target.checked, item.uuid)}
                        />
                      </TableCell>:null
                    }
                    <TableCell>
                      <div className="flex-centered">
                        <div>
                          <h4 style={{marginBottom:'0px !important'}}>{ item.name }
                            { query.rename && checkPermission('records','data_room', 'RENAME') ?
                              <span style={{cursor:'pointer'}} onClick={() => this.setState({renameModal:true, fileInfo:item})}>
                                <Tooltip title="Rename file" arrow>
                                  <EditIcon color="primary" fontSize="small" />
                                </Tooltip>
                              </span>:null
                            }
                          </h4>
                          <p>{item.location}</p>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                pagination={folderLevelFiles.pagination}
                onChangePage={(event, newPage) => {this.setState({bulkOperation:{ids:[]}});this.getFolderFilesApi(this.props, { ...query, page:newPage+1, per_page: folderLevelFiles.pagination.per_page})}}
                onChangeRowsPerPage={(event) => this.getFolderFilesApi(this.props, {...query, page:1, per_page:event.target.value})}
                noRecord={ folderLevelFiles.list.length ? null:
                  <EmptyCollection
                    title="No Records Found"
                  />
                }
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined"  color="primary" onClick={() => {this.setState({modal:false}); window.location.reload()}}>Cancel</Button>
              { checkPermission('records','data_room', 'PUB_REJ') ?
                <Fragment>
                  { bulkOperation.ids.length ?
                    <Button onClick={() => { this.setState({modal:false, title:'', bulkOperation:{ids:[]}}); changeDMFilesStatus({status:3,type:'file', uuid:bulkOperation.ids, folder_uuid:[]})}} variant="contained"  color="primary">Publish ({bulkOperation.ids.length})</Button>:null
                  }
                  <Button onClick={() => { this.setState({modal:false, title:'', bulkOperation:{ids:[]}}) ;changeDMFilesStatus({status:3,type:'file', uuid:[], to_consider:query.unpublished ? 'unpublished':'renamed', folder_uuid:[this.props.match.params.uuid]})}} variant="contained"  color="primary">Publish All</Button>
                </Fragment>:null
              }
            </DialogActions>
          </Dialog>:null
        }
        { renameModal ?
          <Dialog
            open={renameModal}
            onClose={() => this.setState({renameModal:false})}
            aria-labelledby="scroll-dialog-title"
            fullWidth= 'true'
          >
            <DialogTitle id="scroll-dialog-title">
              Rename File
            </DialogTitle>
            <DialogContent dividers={true} className="issue-files-modal">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="name"
                    fullWidth
                    margin="normal"
                    value={fileInfo && fileInfo.name ? fileInfo.name:''}
                    onChange={(e) =>
                      this.setState(prevState => ({
                        ...prevState,
                        fileInfo:{
                          ...prevState.fileInfo,
                          name:e.target.value
                        }
                      }))
                    }
                    InputLabelProps={{shrink: true}}
                  />
                  <p>{fileInfo.naming_convention.map(name => <span>{name}</span>)}</p>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button size="small" variant="outlined"  color="primary" onClick={() => {this.setState({renameModal:false, fileInfo:null})}}>Cancel</Button>
              { checkPermission('records','data_room', 'RENAME') ?
                <Button onClick={() => this.onRenameDMFilesApi(this.props, {new_name:fileInfo.name,uuid:fileInfo.uuid, type:'file'})} size="small" variant="contained"  color="primary">Rename</Button>:null
              }
              { checkPermission('records','data_room', 'PUB_REJ') ?
                <Button onClick={() => this.onRenameDMFilesApi(this.props, {new_name:fileInfo.name,uuid:fileInfo.uuid, type:'file', publish:true})} size="small" variant="contained"  color="primary">Rename & Publish</Button>:null
              }
              
            </DialogActions>
          </Dialog>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(FolderLevelFilesModal));

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Button, Paper, Grid, Tabs, Tab, TableRow, TableCell, Avatar, Breadcrumbs, Link, Typography } from '@material-ui/core';
import { STableLoader } from '../../../shared_elements/loaders';
import { TableListComp, PageLoader, EmptyCollection, FilterUIComp, DeletePopUp } from '../../../shared_elements';
import { SCardLoader } from '../../../shared_elements/loaders';
import { UnOrgansiedList, OrgDataCard, BatchDetail, AutoOrgContextMenu, RenameFile } from '../components';
import { MoveDataModal } from '../../dataroom/components';
import { imageMedia, videoTypes } from '../../../constants'
import { getPlatformConstantsAc } from '../../../shared_elements/actionCreators';
import * as actions from '../../../shared_elements/actions'
import { getAutoOrgBatchDetailApi, getAutoOrgDataApi, getAutoUnOrgDataApi, onFileRanmeApi, onFileDeleteApi, getDataMoveListApi, onMoveDmDataApi, publishOrgDataApi, moveUnOrgTreeApi } from '../apiServices';
import { unOrganisedHd } from '../';
import { trackActivity } from '../../../utils/mixpanel';
const queryString = require('query-string');
class AutoOrganiserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publishDataModal: false,
      renameFile: { modal: false, data: null },
      skeletonLoader: false,
      pageLoader: false,
      tabIndex: 0,
      batchDetail: {},
      autoOrganiserData: {
        list: [],
        navigation: []
      },
      autoUnOrgData: {
        list: [],
        pagination: null
      },
      bulkOperation: {
        modal: false,
        ids: []
      },
      moveData: {
        moveType:'single',
        name: '',
        moveFolderuuid: '',
        modal: false,
        sourceId: '',
        sourceType: '',
        navigation: [],
        list: []
      },
    }
    this.getAutoOrgBatchDetailApi = getAutoOrgBatchDetailApi.bind(this);
    this.getAutoOrgDataApi = getAutoOrgDataApi.bind(this);
    this.getAutoUnOrgDataApi = getAutoUnOrgDataApi.bind(this);
    this.onFileRanmeApi = onFileRanmeApi.bind(this);
    this.onFileDeleteApi = onFileDeleteApi.bind(this);
    this.getDataMoveListApi = getDataMoveListApi.bind(this);
    this.onMoveDmDataApi = onMoveDmDataApi.bind(this);
    this.publishOrgDataApi = publishOrgDataApi.bind(this);
    this.moveUnOrgTreeApi = moveUnOrgTreeApi.bind(this);
  }
  componentDidMount() {
    let query = {};
    if (queryString.parse(this.props.location.search).folder_uuid) {
      query = {
        ...query,
        folder_uuid: queryString.parse(this.props.location.search).folder_uuid
      }
    }
    this.getAutoOrgBatchDetailApi(this.props);
    this.getAutoOrgDataApi(this.props, query, 'skeletonLoader');
    this.getAutoUnOrgDataApi(this.props, {}, 'skeletonLoader');
    if (!this.props.pltConstants.filter(item => item.type === 'file_extension_drive').length) {
      this.props.getPlatformConstants();
    }
    trackActivity('Page Visited', { page_title: 'Auto Organiser', page_type: 'Detail' })
  }
  onChangeFileName = (value) => {
    this.setState(prevState => ({
      ...prevState,
      renameFile: {
        ...prevState.renameFile,
        data: {
          ...prevState.renameFile.data,
          name: value
        }
      }
    }))
  }
  onChangeTabIndex = (event, newValue) => {
    this.setState({ tabIndex: newValue })
  }
  getMoveFirstFolders = () => {
    this.setState(prevState => ({
      ...prevState,
      moveData: {
        ...prevState.moveData,
        list: prevState.moveData.navigation.slice(0, 1),
        navigation: []
      }
    }))
  }
  onChangeNav = (folderUuid) => {
    const { match, history } = this.props;
    history.push({
      pathname: `/${match.params.assetType}/${match.params.assetSlug}/auto-organiser/view/${match.params.uuid}`,
      search: '?folder_uuid=' + folderUuid
    });
    this.getAutoOrgDataApi(this.props, { folder_uuid: folderUuid }, 'pageLoader');
  }
  render() {
    const { publishDataModal, skeletonLoader, pageLoader, tabIndex, moveData, renameFile, bulkOperation, autoOrganiserData, autoUnOrgData, batchDetail } = this.state;
    const { pltConstants } = this.props;
    return (
      <section className="auto-organiser-section">
        <div className="heading flex-centered">
          <h3>Auto Organiser</h3>
        </div>
        <BatchDetail batchDetail={batchDetail} />
        <div className="action-dock-tab">
          <Tabs
            value={tabIndex}
            onChange={this.onChangeTabIndex}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
          >
            <Tab label={`Organised`} onClick={() => trackActivity('Tab Clicked', { page_title: 'Auto Organiser', page_type: 'View', tab_name: 'Organised' })} />
            <Tab label={`Unorganised`} onClick={() => trackActivity('Tab Clicked', { page_title: 'Auto Organiser', page_type: 'View', tab_name: 'Unorganised' })} />
          </Tabs>
        </div>
        {tabIndex === 0 ?
          <div>
            <div className="publish-org-data">
              <Button color="primary" variant="contained" onClick={() => { this.setState({ publishDataModal: true }); trackActivity('Item Clicked', { page_title: 'Auto Organiser', page_type: 'View', event_type: 'Publish Button', asset_type: this.props.match.params.assetType, asset_slug: this.props.match.params.assetSlug, uuid: this.props.match.params.uuid }) }}>Publish</Button>
              <p>By publishing, the organised files will go to Data Room & Action Dock</p>
            </div>
            <Breadcrumbs maxItems={3} aria-label="breadcrumb">
              {autoOrganiserData.navigation.map((nav, index) =>
                autoOrganiserData.navigation.length - 1 === index ? <Typography>{nav.name}</Typography> :
                  <Link onClick={() => this.onChangeNav(nav.uuid)}>{nav.name}</Link>
              )}
            </Breadcrumbs>
            <div className="shared-content-list" style={{ marginTop: '15px' }}>
              <ul className="list-inline">
                {skeletonLoader ? <SCardLoader count={20} /> : null}
                {autoOrganiserData.list.map((item) =>
                  <li className="list-inline-item">
                    <OrgDataCard
                      item={item}
                      fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                      onChangeNav={() => this.onChangeNav(item.uuid)}
                      contextMenu={
                        <AutoOrgContextMenu
                          onRenameFile={() => this.setState({ renameFile: { modal: true, data: item } })}
                          onDeleteFile={() => this.setState({ bulkOperation: { modal: true, ids: [item.uuid] } })}
                          getDataMoveList={() => this.getDataMoveListApi(this.props, item.uuid, autoOrganiserData.navigation[autoOrganiserData.navigation.length - 1].uuid, {}, item.name, 'org')}
                        />
                      }
                    />
                  </li>
                )}
              </ul>
              {!autoOrganiserData.list.length ?
                <EmptyCollection
                  title="No Records Found"
                /> : null
              }

            </div>
          </div> : null
        }
        {tabIndex === 1 ?
          <div>
            <TableListComp
              heads={unOrganisedHd}
              data={autoUnOrgData.list.map((item, index) =>
                <UnOrgansiedList
                  key={index}
                  item={item}
                  fileIcons={pltConstants.filter(icon => icon.type === 'file_extension_drive')}
                  contextMenu={
                    <AutoOrgContextMenu
                      onRenameFile={() => this.setState({ renameFile: { modal: true, data: item } })}
                      onDeleteFile={() => this.setState({ bulkOperation: { modal: true, ids: [item.uuid] } })}
                      getDataMoveList={() => this.getDataMoveListApi(this.props, item.uuid, '', {}, item.name, 'un-org')}
                    />
                  }
                />
              )}
              pagination={autoUnOrgData?.pagination}
              onChangePage={(event, newPage) => { this.setState({ bulkOperation: { ids: [], modal: false, title: '' } }); this.getAutoUnOrgDataApi(this.props, {  page: newPage + 1, per_page: autoUnOrgData.pagination.per_page, }, 'pageLoader') }}
              onChangeRowsPerPage={(event) => this.getAutoUnOrgDataApi(this.props, {  page: 1, per_page: event.target.value, }, 'pageLoader')}
              noRecord={autoUnOrgData.list.length ? null :
                <EmptyCollection
                  title="No Records Found"
                />
              }
            />
          </div> : null
        }
        {renameFile.modal ?
          <RenameFile
            renameFile={renameFile}
            toggleModalFn={() => this.setState({ renameFile: { modal: false, data: null } })}
            saveFileName={() => this.onFileRanmeApi(this.props, renameFile)}
            onChangeFileName={this.onChangeFileName}
          /> : null
        }
        {moveData.modal ?
          <MoveDataModal
            moveData={moveData}
            getuuidParent={()=>console.log('hi')}
            toggleModalFn={() => this.setState({ moveData: { modal: false, list: [], navigation: [], sourceType: '', sourceId: '' } })}
            getMoveFolders={(parentuuid) => this.getDataMoveListApi(this.props, moveData.sourceId, parentuuid, { source_uuid: parentuuid }, moveData.name, moveData.type)}
            getMoveFirstFolders={this.getMoveFirstFolders}
            onMoveDmData={() => this.onMoveDmDataApi(this.props, moveData)}
            setMoveFolder={(uuid, flag) => this.setState(prevState => ({
              ...prevState,
              moveData: {
                ...prevState.moveData,
                moveFolderuuid: prevState.moveData.moveFolderuuid === uuid ? '' : uuid
              }
            }))}
          /> : null
        }
        <DeletePopUp
          modal={bulkOperation.modal}
          toggleModalFn={() =>
            this.setState(prevState => ({
              ...prevState,
              bulkOperation: {
                ...prevState.bulkOperation,
                modal: false,
                ids: []
              }
            }))
          }
          title="Delete"
          content={<h4>{`Are you sure you want to Delete`}</h4>}
          triggerEventFn={() => this.onFileDeleteApi(this.props, bulkOperation)}
        />
        {publishDataModal ?
          <DeletePopUp
            modal={publishDataModal}
            toggleModalFn={() => this.setState({ publishDataModal: false })}
            title="Publish"
            content={<h4>{`After publishing all the organised files will be deleted.`}</h4>}
            triggerEventFn={() => this.publishOrgDataApi(this.props)}
            confirmText="Yes"
            cancelText="No"
          /> : null
        }
        {pageLoader ? <PageLoader /> : null}
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ['file_extension_drive'] })),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(AutoOrganiserView))

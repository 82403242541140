import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { TableRow, TableCell, Tooltip, Checkbox, Avatar, Chip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import { getFileSize, getFileIcon, localTimeFn } from '../../../utils';
import { displayDateFormatShort, imgPath } from '../../../constants';
import folderIcon from '../../../assets/images/folder_icon.svg';
import sharedIcon from '../../../assets/images/shared_icon.svg';
import { trackPublicActivity } from '../../../utils/mixpanel';
const SharedListCard = ({ item, conntextMenu, onSelection, selectedItems, fileIcons, onChangeNav, onShareContent, sharedData }) => {
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell className="file-info">
        <div className="flex-centered">
          <ul className="list-inline" style={{ minWidth: '80px' }}>
            <li className="list-inline-item" style={{ position: 'relative', top: '5px' }}>
              <img style={{ width: item.type === 'folder' ? '30px' : '20px' }} className="folder-img" src={item.type === 'folder' ? folderIcon : getFileIcon(fileIcons, item.extension)} alt={item.extension} />
            </li>
          </ul>
          <div>
            <h5 className="flex-centered" onClick={(e) => { item.type === 'folder' ? onChangeNav() : window.open(`/records/preview/${item.uuid}`, '_blank'); item.type !== 'folder' ? trackPublicActivity('File Previewed', { page_title: 'Shared', event_type: 'Preview(List Layout)', extension: item.extension ? item.extension : '', size: item.size ? item.size : '', asset_type: sharedData.asset.asset_type, asset_name: sharedData.asset.msn, lessor_name: sharedData.asset.lessor, file_path: item.location, file_name: item.name, uuid: item.uuid }) : e.preventDefault() }} style={{ cursor: 'pointer' }}>
              {item.name}
              {/* {item.share_enabled ?
                <span className="shared-info">
                  <Tooltip title="Copy Shared Link" arrow>
                    <img style={{ marginLeft: '6px' }} width="20" onClick={onShareContent} src={sharedIcon} alt="" />
                  </Tooltip>
                </span> : null
              } */}
            </h5>
          </div>
        </div>

      </TableCell>
      <TableCell> {item.type === 'folder' ? 'Files: ' + item.file_count ? item.file_count : '0' : getFileSize(item.size)}</TableCell>
    </TableRow>
  )
}
export default withRouter(SharedListCard);

import Login from './containers/Login';
import LessorSelection from './containers/LessorSelection';
import SharedRecordsList from './containers/SharedRecordsList';
import FilePreview from './containers/FilePreview';
import UserLogged from '../../hoc/UserLogged';
import Otp from './containers/Otp';
export const authRoutes = [
  {
    path: '/',
    component: UserLogged(Login),
    key: 'login',
    apps:'Auth'
  },
  {
    path: '/login',
    component:UserLogged(Login) ,
    key: 'login1',
    apps:'Auth'
  },
  {
    path: '/select-lessor',
    component: LessorSelection,
    key: 'select-lessor',
    apps:'Auth'
  },
  {
    path: '/records/shared-data/:key',
    component: SharedRecordsList,
    key: 'SharedRecordsList',
    apps:'Shared'
  },
  {
    path: '/records/preview/:uuid',
    component: FilePreview,
    key: 'FilePreview',
    apps:'Shared'
  },
  {
    path: '/otp-verification/:token',
    component: UserLogged(Otp),
    key: 'otp',
    apps:'Auth'
  },
]
